var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __asyncValues = (this && this.__asyncValues) || function (o) {
    if (!Symbol.asyncIterator) throw new TypeError("Symbol.asyncIterator is not defined.");
    var m = o[Symbol.asyncIterator], i;
    return m ? m.call(o) : (o = typeof __values === "function" ? __values(o) : o[Symbol.iterator](), i = {}, verb("next"), verb("throw"), verb("return"), i[Symbol.asyncIterator] = function () { return this; }, i);
    function verb(n) { i[n] = o[n] && function (v) { return new Promise(function (resolve, reject) { v = o[n](v), settle(resolve, reject, v.done, v.value); }); }; }
    function settle(resolve, reject, d, v) { Promise.resolve(v).then(function(v) { resolve({ value: v, done: d }); }, reject); }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { BlobServiceClient, } from "@azure/storage-blob";
import { from, Observable } from "rxjs";
import { distinctUntilChanged, scan, startWith } from "rxjs/operators";
var BlobStorageService = /** @class */ (function () {
    function BlobStorageService() {
    }
    BlobStorageService.prototype.getContainers = function (request) {
        var blobServiceClient = this.buildClient(request);
        return this.asyncToObservable(blobServiceClient.listContainers());
    };
    BlobStorageService.prototype.listBlobsInContainer = function (request) {
        var containerClient = this.getContainerClient(request);
        return this.asyncToObservable(containerClient.listBlobsFlat());
    };
    BlobStorageService.prototype.downloadBlobItem = function (request) {
        var blockBlobClient = this.getBlockBlobClient(request);
        return from(blockBlobClient.download());
    };
    BlobStorageService.prototype.deleteBlobItem = function (request) {
        var blockBlobClient = this.getBlockBlobClient(request);
        return from(blockBlobClient.delete());
    };
    BlobStorageService.prototype.uploadToBlobStorage = function (file, request) {
        var blockBlobClient = this.getBlockBlobClient(request);
        return this.uploadFileToBlob(blockBlobClient, file);
    };
    BlobStorageService.prototype.uploadVideoToBlobStorage = function (file, request, containerName, objectId) {
        var blobServiceClient = this.buildClient(request);
        var parentContainerClient = blobServiceClient.getContainerClient(containerName);
        var blobClient = parentContainerClient.getBlockBlobClient("".concat(objectId, "/").concat(request.filename));
        // Call uploadFileToBlob method
        this.uploadFileToBlob(blobClient, file).subscribe({
            next: function (response) {
                // Check if the response contains the URL, indicating success
                if (response.url) {
                    return response.url;
                }
                else {
                    console.error("Upload failed!");
                }
            },
            error: function (error) {
                console.error("Error uploading file:");
                // Handle error
            },
            complete: function () {
                console.log("Upload complete!");
                // Handle completion
            },
        });
    };
    BlobStorageService.prototype.getImageFileNameFromUrl = function (imageUrl) {
        var parts = imageUrl.split("/");
        return decodeURIComponent(parts[parts.length - 1]); // Decode URL components
    };
    BlobStorageService.prototype.getImageContentTypeFromUrl = function (imageUrl) {
        var _a;
        var extension = (_a = imageUrl.split(".").pop()) === null || _a === void 0 ? void 0 : _a.toLowerCase();
        switch (extension) {
            case "jpg":
            case "jpeg":
                return "image/jpeg";
            case "png":
                return "image/png";
            case "gif":
                return "image/gif";
            default:
                return undefined; // Add more supported formats if needed
        }
    };
    BlobStorageService.prototype.createContainer = function (containerName, request) {
        return __awaiter(this, void 0, void 0, function () {
            var blobServiceClient, containerClient;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        blobServiceClient = this.buildClient(request);
                        containerClient = blobServiceClient.getContainerClient(containerName);
                        return [4 /*yield*/, containerClient.create()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    BlobStorageService.prototype.createNestedContainer = function (parentContainerName, orderNumber, request) {
        return __awaiter(this, void 0, void 0, function () {
            var blobServiceClient, parentContainerClient, orderPrefix, chatBlobClient, documentsBlobClient;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        blobServiceClient = this.buildClient(request);
                        parentContainerClient = blobServiceClient.getContainerClient(parentContainerName);
                        return [4 /*yield*/, parentContainerClient.exists()];
                    case 1:
                        // Ensure the parent container exists
                        if (!(_a.sent())) {
                            throw new Error("Parent container ".concat(parentContainerName, " does not exist"));
                        }
                        orderPrefix = "".concat(orderNumber, "/");
                        chatBlobClient = parentContainerClient.getBlockBlobClient("".concat(orderPrefix, "chat/dummy.txt"));
                        documentsBlobClient = parentContainerClient.getBlockBlobClient("".concat(orderPrefix, "documents/dummy.txt"));
                        return [4 /*yield*/, Promise.all([
                                chatBlobClient.upload("", 0), // Creates a dummy file to simulate the "chat" folder
                                documentsBlobClient.upload("", 0), // Creates a dummy file to simulate the "documents" folder
                            ])];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    BlobStorageService.prototype.getBlockBlobClient = function (request) {
        var containerClient = this.getContainerClient(request);
        return containerClient.getBlockBlobClient(request.filename);
    };
    BlobStorageService.prototype.getContainerClient = function (request) {
        var blobServiceClient = this.buildClient(request);
        return blobServiceClient.getContainerClient(request.containerName);
    };
    BlobStorageService.prototype.buildClient = function (options) {
        return BlobServiceClient.fromConnectionString(this.buildConnectionString(options));
    };
    BlobStorageService.prototype.uploadFileToBlob = function (blockBlobClient, file) {
        return new Observable(function (observer) {
            var fileSize = file.size;
            var uploadedBytes = 0;
            var fileName = file.name;
            blockBlobClient
                .uploadData(file, {
                onProgress: function (ev) {
                    uploadedBytes = ev.loadedBytes;
                    var progress = (uploadedBytes / fileSize) * 100;
                    observer.next({ progress: progress.toFixed(2), fileName: fileName }); // Emit progress percentage and file name
                },
                blobHTTPHeaders: {
                    blobContentType: file.type,
                },
            })
                .then(function (response) {
                // Get the uploaded URL from the blockBlobClient after the upload is complete
                var url = blockBlobClient.url;
                observer.next({ progress: "100", fileName: fileName, url: url }); // Upload completed, emit 100% progress, file name, and URL
                observer.complete(); // Upload complete, emit complete event
            }, function (error) {
                observer.error(error); // Upload error, emit error event
            });
        });
    };
    BlobStorageService.prototype.uploadFile = function (blockBlobClient, file) {
        var _this = this;
        return new Observable(function (observer) {
            blockBlobClient
                .uploadBrowserData(file, {
                onProgress: _this.onProgress(observer),
                blobHTTPHeaders: {
                    blobContentType: file.type,
                },
            })
                .then(_this.onUploadComplete(observer, file), _this.onUploadError(observer));
        }).pipe(distinctUntilChanged());
    };
    BlobStorageService.prototype.onUploadError = function (observer) {
        return function (error) { return observer.error(error); };
    };
    BlobStorageService.prototype.onUploadComplete = function (observer, file) {
        return function () {
            observer.next(file.size);
            observer.complete();
        };
    };
    BlobStorageService.prototype.onProgress = function (observer) {
        return function (progress) {
            return observer.next(progress.loadedBytes);
        };
    };
    BlobStorageService.prototype.asyncToObservable = function (iterable) {
        var _this = this;
        return new Observable(function (observer) {
            return void (function () { return __awaiter(_this, void 0, void 0, function () {
                var _a, _b, _c, item, e_1_1, e_2;
                var _d, e_1, _e, _f;
                return __generator(this, function (_g) {
                    switch (_g.label) {
                        case 0:
                            _g.trys.push([0, 13, , 14]);
                            _g.label = 1;
                        case 1:
                            _g.trys.push([1, 6, 7, 12]);
                            _a = true, _b = __asyncValues(iterable);
                            _g.label = 2;
                        case 2: return [4 /*yield*/, _b.next()];
                        case 3:
                            if (!(_c = _g.sent(), _d = _c.done, !_d)) return [3 /*break*/, 5];
                            _f = _c.value;
                            _a = false;
                            item = _f;
                            if (observer.closed)
                                return [2 /*return*/];
                            observer.next(item);
                            _g.label = 4;
                        case 4:
                            _a = true;
                            return [3 /*break*/, 2];
                        case 5: return [3 /*break*/, 12];
                        case 6:
                            e_1_1 = _g.sent();
                            e_1 = { error: e_1_1 };
                            return [3 /*break*/, 12];
                        case 7:
                            _g.trys.push([7, , 10, 11]);
                            if (!(!_a && !_d && (_e = _b.return))) return [3 /*break*/, 9];
                            return [4 /*yield*/, _e.call(_b)];
                        case 8:
                            _g.sent();
                            _g.label = 9;
                        case 9: return [3 /*break*/, 11];
                        case 10:
                            if (e_1) throw e_1.error;
                            return [7 /*endfinally*/];
                        case 11: return [7 /*endfinally*/];
                        case 12:
                            observer.complete();
                            return [3 /*break*/, 14];
                        case 13:
                            e_2 = _g.sent();
                            observer.error(e_2);
                            return [3 /*break*/, 14];
                        case 14: return [2 /*return*/];
                    }
                });
            }); })();
        }).pipe(scan(function (items, item) { return __spreadArray(__spreadArray([], items, true), [item], false); }, []), startWith([]));
    };
    BlobStorageService.prototype.buildConnectionString = function (options) {
        return "".concat(process.env.BLOBENDPOINT);
    };
    return BlobStorageService;
}());
export { BlobStorageService };
