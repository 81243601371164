import React, { useState } from "react";
import Pagination from "./Pagination";
import RecordsPerPage from "./RecordsPerPage";
import DataTableContent from "./DataTableContent";
import ExportTable from "./ExportTable";
import ButtonComponent from "../Button/ButtonComponent";
import { IoFilter } from "react-icons/io5";
import LinkComponent from "../Link/LinkComponent";
var DataTable = function (_a) {
    var headers = _a.headers, data = _a.data, actions = _a.actions, _b = _a.itemsPerPageOptions, itemsPerPageOptions = _b === void 0 ? [10, 20, 50, 100] : _b, containerClassName = _a.containerClassName, headerClassName = _a.headerClassName, bodyClassName = _a.bodyClassName, rowClassName = _a.rowClassName, paginationPrevClassName = _a.paginationPrevClassName, paginationNextClassName = _a.paginationNextClassName, paginationActiveClassName = _a.paginationActiveClassName, paginationInactiveClassname = _a.paginationInactiveClassname, selectClassName = _a.selectClassName, children = _a.children, refreshData = _a.refreshData, totalRecords = _a.totalRecords, filter = _a.filter, pagination = _a.pagination, RecordsPage = _a.RecordsPage;
    var _c = useState(1), currentPage = _c[0], setCurrentPage = _c[1];
    var _d = useState(itemsPerPageOptions[0]), itemsPerPage = _d[0], setItemsPerPage = _d[1];
    var _e = useState(false), filterOpen = _e[0], setFilterOpen = _e[1];
    var handlePageChange = function (page) {
        setCurrentPage(page);
        refreshData(page, itemsPerPage);
    };
    var handleItemsPerPageChange = function (e) {
        var newItemsPerPage = parseInt(e.target.value, 10);
        setItemsPerPage(newItemsPerPage);
        setCurrentPage(1); // Reset to page 1 to avoid being out of range
        refreshData(1, newItemsPerPage); // Use 1 because the page is reset to 1
    };
    var totalPages = Math.ceil(totalRecords / itemsPerPage);
    var processedData = data.map(function (item) {
        var processedItem = {};
        Object.keys(item).forEach(function (key) {
            processedItem[key] = item[key] !== null ? item[key] : "-";
        });
        return processedItem;
    });
    return (React.createElement("div", { className: "lg:flex" },
        React.createElement("div", { className: "".concat(filterOpen ? "lg:w-3/4" : "w-full", " bg-white border border-gray-200 p-6 rounded-lg mb-4 shadow-lg hover:shadow-xl transition duration-300") },
            React.createElement("div", { className: "mb-4 flex justify-between items-center flex-wrap" }, RecordsPage !== false && (React.createElement(React.Fragment, null,
                React.createElement(RecordsPerPage, { itemsPerPage: itemsPerPage, itemsPerPageOptions: itemsPerPageOptions, onItemsPerPageChange: handleItemsPerPageChange, selectClassName: selectClassName }),
                React.createElement("div", { className: "lg:flex items-center mt-4 hidden" },
                    React.createElement(ExportTable, { data: data, headers: headers, id: "table" }),
                    filter !== false && (React.createElement(ButtonComponent, { btnClassName: "bg-primary-500 hover:bg-primary-700 text-white p-2 rounded mx-2 flex items-center", onClick: function () { return setFilterOpen(!filterOpen); } },
                        React.createElement(IoFilter, { className: "mr-1" }),
                        filterOpen ? "Hide Filter" : "Filter")))))),
            React.createElement(DataTableContent, { headers: headers, currentItems: processedData, actions: actions, containerClassName: containerClassName, bodyClassName: bodyClassName, headerClassName: headerClassName, rowClassName: rowClassName, id: "table" }),
            pagination !== false && (React.createElement(Pagination, { currentPage: currentPage, totalPages: totalPages, onPageChange: handlePageChange, paginationActiveClassName: paginationActiveClassName, paginationInactiveClassname: paginationInactiveClassname, paginationNextClassName: paginationNextClassName, paginationPrevClassName: paginationPrevClassName }))),
        filterOpen && (React.createElement("div", { className: "w-1/2 lg:w-1/4 lg:mb-6 lg:ml-4 absolute bottom-16 right-0 h-auto lg:static mr-2" }, children)),
        React.createElement("div", { className: "fixed lg:hidden grid grid-cols-2 items-center bottom-0 right-0 left-0 z-50 p-4 w-full bg-blue-50" },
            React.createElement(ExportTable, { data: data, headers: headers, id: "table", responsive: true }),
            filter !== false && (React.createElement(LinkComponent, { to: "", className: "mx-2 flex items-center justify-center border-l border-black", onClick: function () { return setFilterOpen(!filterOpen); } },
                React.createElement(IoFilter, { className: "mr-1" }),
                filterOpen ? "Hide Filter" : "Filter")))));
};
export default DataTable;
