import React from "react";
import clsx from "clsx";
var RecordsPerPage = function (_a) {
    var itemsPerPage = _a.itemsPerPage, itemsPerPageOptions = _a.itemsPerPageOptions, onItemsPerPageChange = _a.onItemsPerPageChange, selectClassName = _a.selectClassName;
    return (React.createElement("div", null,
        React.createElement("label", { className: "mr-2" }, "Show Records Per Page:"),
        React.createElement("select", { onChange: onItemsPerPageChange, value: itemsPerPage, className: clsx(selectClassName === undefined || null
                ? "px-3 h-8 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg"
                : selectClassName) }, itemsPerPageOptions.map(function (option) { return (React.createElement("option", { key: option, value: option }, option)); }))));
};
export default RecordsPerPage;
