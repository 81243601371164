import React, { useEffect, useRef, useState } from 'react';

const AnimatedScrollDiv = ({ children, animationClass = 'animate-fadeIn', duration = 1000 }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [isBlurred, setIsBlurred] = useState(false);
  const ref = useRef(null);

  const handleFocus = () => {
    setIsFocused(true);
    setIsBlurred(false);
  };

  const handleBlur = () => {
    setIsFocused(false);
    setIsBlurred(true);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(ref.current!);
        } else {
          setIsVisible(false);
        }
      },
      { threshold: 0.1 } // Adjust threshold as needed
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  // Determine class names based on focus, blur, and visibility
  const getClassNames = () => {
    let classNames = `transition-opacity duration-[${duration}ms] ease-in-out opacity-100 `;
    if (isFocused) {
      classNames += `${animationClass}`;
    } else if (isBlurred) {
      classNames += 'animate-fadeOut'; // Ensure this class is defined in your CSS
    } else if (isVisible) {
      classNames += `${animationClass}`; // Apply animation when visible
    }
    return classNames;
  };

  return (
    <div
      ref={ref}
      tabIndex={0} // Makes the div focusable
      onFocus={handleFocus}
      onBlur={handleBlur}
      className={getClassNames()}
    >
      {children}
    </div>
  );
};

export default AnimatedScrollDiv;
