import React from "react";
import userImage from "../../Assets/aadhar-register-icon.svg";
import InquiryForm from "./InquiryForm";
import { SupportAnsCard, SupportQueCard } from "../../Models/types";
import { accountList, bankCheckList, bankFaq, bankStep, chatConversation, chatQue, kycStepList, securityCheckList, securitySteps, tradingSupportList, withdrawCheckList, withdrawFaqList, withdrawProcessList, withdrawQuestionList } from "./CardComponentContentData";

export const supportCardQue: SupportQueCard[] = [
  {
    id: "chat-support",
    image: userImage,
    title: "Chat Support",
    description:
      "Immediate assistance for all your queries. Click here to connect with our live support team for real-time help.",
  },
  {
    id: "kyc",
    image: userImage,
    title: "KYC & Registration Support",
    description:
      "Learn how to easily complete your KYC and registration with Xonick Pay.",
  },
  {
    id: "withdraw-trade",
    image: userImage,
    title: "Withdrawal and Trading Support",
    description:
      "Get all the information you need for smooth withdrawals and effective trading.",
  },
  {
    id: "bank-account",
    image: userImage,
    title: "Bank Account Verification Support",
    description:
      " Make sure your bank account is verified to allow for smooth transactions.",
  },
  {
    id: "security",
    image: userImage,
    title: "Security",
    description:
      "Your security is our priority. Learn more about the measures we take to protect your information and funds.",
  },
  {
    id: "account",
    image: userImage,
    title: "Account Management",
    description: "Manage your account details, preferences, and settings here.",
  },
  {
    id: "inquiry-form",
    image: userImage,
    title: "Inquiry Form",
    description:
      "Have more questions? Submit your inquiry, and our support team will get back to you as soon as possible.",
  },
];

export const supportCardQueAns: SupportAnsCard[] = [
  {
    id: "chat-support",
    title: "Chat Support",
    description: (
      <div className="container mx-auto px-4 py-2">
        <h2 className="text-2xl font-bold mb-8">
          Chat Support Responses for Xonick Pay
        </h2>

        <ol className="list-decimal space-y-4">
          {chatQue && chatQue.map((que, index) => (
            <li key={index}>
              <h4 className="font-bold mb-2">{que.question}</h4>
              <p>{que.answer}</p>
              <hr className="my-4" />
            </li>
          ))}
        </ol>
        <h2 className="text-2xl font-bold mb-8 mt-4">
          Chat Support Conversation Structure
        </h2>
        <div>
          {chatConversation && chatConversation.map((que, index) => (
            <div key={index}>
              <h3 className="font-semibold mb-2">{que.title}</h3>
              <p>{que.description}</p>
              <hr className="my-4" />
            </div>
          ))}
        </div>
      </div>
    ),
  },
  {
    id: "kyc",
    title: "KYC & Registration Support",
    description: (
      <div className="container mx-auto px-4 py-2">
        <h2 className="text-2xl font-bold mb-8">Create a Xonick Pay Account</h2>

        <h3 className="text-xl font-bold mb-4">
          How to Create an Account on Xonick Pay?
        </h3>

        <p>
          Creating an account on Xonick Pay is a straightforward process
          designed to ensure security and ease of use. Follow these detailed
          steps to start trading INR for USDT securely:
        </p>
        <hr className="my-4" />
        <div>
          {kycStepList && kycStepList.map((step, index) => (
            <div key={index}>
              <h4 className="text-lg font-bold mb-4">{step.title}</h4>
              <ol className="list-decimal space-y-2 list-inside">
                {step.step.step1 && (
                  <li className="list-inside">
                    <strong>{step.step.step1.title}</strong>&nbsp;
                    {step.step.step1.data}
                  </li>
                )}
                {step.step.step2 && (
                  <li className="list-inside">
                    <strong>{step.step.step2.title}</strong>&nbsp;
                    {step.step.step2.data}
                    {step.step.SubStep && (
                      <ul className="list-disc ml-4 space-y-2 list-inside">
                        {step.step.SubStep.subStep1 && (
                          <li>{step.step.SubStep.subStep1}</li>
                        )}
                        {step.step.SubStep.subSubStep1 && (
                          <li><strong>{step.step.SubStep.subSubStep1.title}</strong>&nbsp;{step.step.SubStep.subSubStep1.data}</li>
                        )}
                        {step.step.SubStep.subSubStep2 && (
                          <li><strong>{step.step.SubStep.subSubStep2.title}</strong>&nbsp;{step.step.SubStep.subSubStep2.data}</li>
                        )}
                        {step.step.SubStep.subStep2 && (
                          <li>{step.step.SubStep.subStep2}</li>
                        )}
                        {step.step.SubStep.subStep3 && (
                          <li>{step.step.SubStep.subStep3}</li>
                        )}
                      </ul>
                    )}
                  </li>
                )}
                {step.step.step3 && (
                  <li>
                    <strong>{step.step.step3.title}</strong>&nbsp;
                    {step.step.step3.data}
                  </li>
                )}
              </ol>
              <hr className="my-4" />
            </div>
          ))}
        </div>
        <h2 className="text-lg font-bold mb-4">Start Trading</h2>
        <p>
          Once your account is set up and verified, you can start trading by
          depositing TRX into your Xonick Pay wallet and using it to trade for
          USDT, following the secure and user-friendly P2P trading process
          provided by Xonick Pay.
        </p>
        <br />
        <p>
          This comprehensive guide ensures that new users can easily navigate
          the account creation process on Xonick Pay, from initial registration
          to ready-to-trade status, in a secure and compliant manner.
        </p>
      </div>
    ),
  },
  {
    id: "withdraw-trade",
    title: "Withdrawal and Trading Support",
    description: (
      <div className="container mx-auto px-4 py-2">
        <h2 className="text-2xl font-bold mb-4">
          Withdrawal and Trading Support
        </h2>
        <p>
          Get all the information you need for smooth withdrawals and effective
          trading on Xonick Pay.
        </p>
        <br />
        <p>
          Performing withdrawals and trading on Xonick Pay is designed to be
          straightforward. Here's
        </p>
        <hr className="my-4" />
        <h3 className="text-xl font-bold mb-4">Withdrawal Process</h3>
        {withdrawProcessList && withdrawProcessList.map((withdraw, index) => (
          <div key={index}>
            <h3 className="text-xl font-bold mb-4">{withdraw.title}</h3>
            <ul className="list-disc space-y-2 list-inside">
              {withdraw.subTitle.map((sub, subIndex) => (
                <li key={subIndex}>{sub}</li>
              ))}
            </ul>
            <hr className="my-4" />
          </div>
        ))}
        {withdrawCheckList && withdrawCheckList.map((list, index) => (
          <div key={index}>
            <h3 className="text-xl font-bold mb-4" >
              {list.title}
            </h3>
            <ul className="list-disc space-y-2 list-inside">
              {list.steps.map((sub, subIndex) => (
                <li key={subIndex}>{sub}</li>
              ))}
            </ul>
          </div>
        ))}

        <hr className="my-4" />
        <h2 className="text-2xl font-bold mb-4">Trading Support</h2>
        {tradingSupportList && tradingSupportList.map((trad, index) => (
          <div key={index}>
            <h3 className="text-xl font-bold mb-4">{trad.title}</h3>
            <ul className="list-disc space-y-2 list-inside">
              {trad.subTitle.map((sub, subIndex) => (
                <li key={subIndex}>{sub}</li>
              ))}
            </ul>
            <hr className="my-4" />
          </div>
        ))}

        <h2 className="text-2xl font-bold mb-4">FAQs</h2>

        <dl className="space-y-4">
          {withdrawFaqList &&
            withdrawFaqList.map((faq, index) => (
              <div key={index}>
                <dt className="font-bold">{faq.question}</dt>
                <dd>{faq.answer}</dd>
              </div>
            ))}
        </dl>
        <hr className="my-4" />
        {withdrawQuestionList && withdrawQuestionList.map((item, index) => (
          <div key={index}>
            <h2 className="text-2xl font-bold mb-4">{item.title}</h2>
            {item.steps ? (
              <>
                {item.heading && <p>{item.heading}</p>}
                <ol className="list-decimal space-y-2 list-inside">
                  {item.steps.map((step, stepIndex) => (
                    <li key={stepIndex}>{step}</li>
                  ))}
                </ol>
              </>
            ) : (
              <p>{item.content}</p>
            )}
            <hr className="my-4" />
          </div>
        ))}
      </div>
    ),
  },
  {
    id: "bank-account",
    title: "Bank Account Verification Support",
    description: (
      <div>
        <h2 className="text-xl font-bold mb-4">
          Verifying your bank account is crucial for a smooth and secure trading
          experience on Xonick Pay. This process involves a small test
          transaction known as a penny drop to confirm the legitimacy of your
          account details. Follow these steps to verify your bank account:
        </h2>
        <hr className="my-4" />
        <ol className="list-decimal space-y-2 list-inside">
          {bankStep && bankStep.map((steps, index) => (
            <li key={index}>
              <strong>{steps.title}</strong>
              {steps.step}
            </li>
          ))}
        </ol>
        <hr className="my-4" />
        {bankCheckList && bankCheckList.map((list, index) => (
          <div key={index}>
            <h3 className="text-xl font-bold mb-4" key={index}>
              {list.title}
            </h3>
            <ul className="list-disc space-y-2 list-inside">
              {list.steps.map((sub, subIndex) => (
                <li key={subIndex}>{sub}</li>
              ))}
            </ul>
          </div>
        ))}
        <hr className="my-4" />
        <h3 className="text-xl font-bold mb-4">FAQs:</h3>

        <dl className="space-y-4">
          {bankFaq && bankFaq.map((faq, index) => (
            <div  key={index}>
              <dt className="font-bold">
                {faq.question}
              </dt>
              <dd>{faq.answer}</dd>
            </div>
          ))}
        </dl>
      </div>
    ),
  },
  {
    id: "security",
    title: "Security",
    description: (
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-2xl font-bold mb-4">
          At Xonick Pay, we implement the best security measures to ensure the
          highest level of protection for your data and assets:
        </h1>
        <hr className="my-4" />
        <ul className="list-disc space-y-2 list-inside">
          {securitySteps && securitySteps.map((steps, index) => (
            <li key={index}>
              <strong>{steps.title}</strong>
              {steps.step}
            </li>
          ))}
        </ul>
        <hr className="my-4" />
        {securityCheckList && securityCheckList.map((list, index) => (
          <div key={index}>
            <h2 className="text-2xl font-bold mb-4" >
              {list.title}
            </h2>
            <ul className="list-disc space-y-2 list-inside">
              {list.steps.map((sub, subIndex) => (
                <li key={"sub"+subIndex}>{sub}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    ),
  },
  {
    id: "account",
    title: "Account Management",
    description: (
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-2xl font-bold mb-4">Account Management FAQ</h1>
        <hr className="my-4" />
        <ol className="list-decimal space-y-4">
          {accountList &&
            accountList.map((list, index) => (
              <div key={index}>
                <li className="font-bold">
                  {list.title}
                  <ul className="list-disc space-y-2 list-inside font-normal mt-2">
                    <li>{list.step}</li>
                  </ul>
                </li>
                <hr className="my-4" />
              </div>
            ))}
        </ol>
      </div>
    ),
  },
  {
    id: "inquiry-form",
    title: "Inquiry Form",
    description: <InquiryForm />,
  },
];
