export var API_CONSTANTS = {
    ROUTES: {
        GET_PERMISSION_BY_OBJECTID: "rolepermissions/getpermissionbyobject",
        ADMIN_ROUTES: "admin/rolepermissions/getpermission",
    },
    USER: {
        REGISTER: "user/user",
        LOGIN: "",
        FORGOTPASSWORD: "",
        ALL_BANK: function (objectId, category) {
            return "user/".concat(objectId, "/paymentmethod/").concat(category);
        },
        GET_CUSTOM_TOKEN: "user/generatecustomtoken",
    },
    GETALLUSER: {
        GETALL: "user/user",
    },
    KYCVERIFICATION: {
        AADHAAR: function (id) { return "verification/".concat(id, "/aadhaar"); },
        PAN: function (id) { return "verification/".concat(id, "/pan"); },
        VERIFYAADHAAR: "verification/getaadhaardetails",
        GETVIDEOKYCCONTENT: function (id) { return "verification/".concat(id, "/getvideokyccontent"); },
        VIDEO_KYC: function (id) { return "verification/".concat(id, "/uploadvideokyc"); },
        REMAINING_TIME: function (id) { return "verification/".concat(id, "/remainingtime"); },
        CONSENT_FORM: {
            SIGNED_UNDERTAKING_DOC: function (id) {
                return "verification/".concat(id, "/signedundertakingdoc");
            },
        },
    },
    BANK_VERIFICATION: {
        BANK: function (id) { return "verification/".concat(id, "/bank"); },
        FETCH_BANK: "verification/fetchbank",
    },
    USER_VERIFICATION: {
        USER_INFORMATION: function (id) { return "verification/".concat(id, "/userinformation"); },
        USER_INFO_VERIFY: function (id) { return "verification/".concat(id, "/updateuserinfo"); },
        SECONDARY_NUMBER_GENERATE_OTP: function (id) {
            return "verification/".concat(id, "/generateotp");
        },
        USER_SECONDARY_NUMBER_VERIFY: function (id) {
            return "verification/".concat(id, "/otpverification");
        },
        GET_USER_INFO: function (objectId) { return "user/".concat(objectId, "/getuserinfo"); },
    },
    MASTER: {
        ORDER_TYPE: {
            GET_ALL: "master/ordertype/getall",
            GET_BY_ID: function (id) { return "master/ordertype/".concat(id, "/getordertypebyid"); },
        },
        PRICE_TYPE: {
            GET_ALL: "master/pricetype/getallpricetype",
            GET_BY_ID: function (id) { return "master/pricetype/".concat(id, "/getbyid"); },
        },
        PAYMENT_METHOD: {
            GET_ALL: "master/paymentmethod/getall",
            GET_BY_ID: function (id) {
                return "master/paymentmethod/".concat(id, "/getpaymentmethodbyid");
            },
        },
        CURRENCY: {
            GET_ALL: "master/currency/getallcurrency",
            GET_BY_ID: function (id) { return "master/currency/".concat(id, "/getcurrency"); },
            GET_CURRENCY: "master/currency/getcurrency",
        },
    },
    ADVERTISEMENT: {
        CREATE: "advertisement/create",
        ADD_PAYMENT_METHOD: function (id) { return "advertisement/".concat(id, "/addpaymentmethod"); },
        ADD_REMARKS: function (id) { return "advertisement/".concat(id, "/addremarks"); },
        GET_ALL: function (id) { return "advertisement/".concat(id, "/getalladvertisement"); },
        GET_BY_ID: function (id) { return "advertisement/".concat(id, "/getadvertisementbyid"); },
        UPDATE_AD_STATUS: function (id) { return "advertisement/".concat(id, "/changestatus"); },
        UPDATE_STATUS: "advertisement/updatestatus",
        FILTERS: function (moduleName) {
            return "advertisement/getadsfilters?moduleName=".concat(moduleName);
        },
        ADVERTISEMENT_TIME_LIMIT: "advertisement/getadvertisementtimelimit",
        PAYMENT_TIME_LIMIT: "advertisement/getpaymenttimelimit",
        UPDATE_AD_STATUS_BY_ID: "advertisement/updatestatusbyadsid",
    },
    ORDERS: {
        GET_ALL_ORDER: "order/getall",
        ORDER_CREATED: "order/order",
        ORDER_BY_OBJECTID: function (objectId) {
            return "order/".concat(objectId, "/getordersbyobjectid");
        },
        GET_ORDER_BY_ID: function (id) { return "order/".concat(id, "/getorderbyid"); },
        EMAIL_GENERATE_OTP: "order/sendemailotp",
        EMAIL_OTP_VERIFICATION: "order/verifyemailotp",
        GET_TRADE_LIST: "order/ordersearch",
        FACE_VERIFICATION: function (id) { return "order/".concat(id, "/facematch"); },
        GENERATE_UNDERTAKING_DOC: function (objectId, orderId) {
            return "verification/".concat(objectId, "/").concat(orderId, "/getUndertakingDoc");
        },
        SIGN_UNDERTAKING_DOC: "verification/undertakingdoc",
        PULL_UNDERTAKING_DOC: function (xonicPayContractId) {
            return "verification/pulludertakingdoc/".concat(xonicPayContractId);
        },
        ONGOING_TRADES: "/order/getOngoingTrades",
        FILTERS: "order/getorderfilters",
        TRANSACTION_FILTERS: "order/gettransactionfilters",
        SEND_OTP: "order/sendemailotp",
        VERIFY_OTP: "order/verifyemailotp",
        HISTORY: {
            GET_TRADES: function (objectId) { return "order/".concat(objectId, "/gettrade"); },
            GET_TRADE_DETAILS: function (orderId) {
                return "order/".concat(orderId, "/gettradedetails");
            },
            GET_TRANSACTIONS: function (objectId) {
                return "order/".concat(objectId, "/gettransaction");
            },
            GET_TRANSACTION_DETAILS: function (transactionId) {
                return "order/".concat(transactionId, "/gettransactiondetails");
            },
        },
        CANCEL_ORDER: "order/canceltradecategory",
    },
    CONVERTER: {
        POST_PRICE: "converter",
    },
    TERMS: {
        TERMS_AND_CONDITIONS: function (id) { return "/user/".concat(id, "/updatetermsandcondition"); },
        GET_TERMS_AND_CONDITIONS: function (id) {
            return "/user/".concat(id, "/getTermsAndCondition");
        },
        APPEAL_CATEGORY: "/appealCategory",
    },
    REWARD_REDEEM: {
        REDEEM_POINT_POST: "/redemptions",
        REDEEM_ALL_POINTS_GET: "/redemptions",
        REDEEM_POINT_BY_ID_GET: "/redemptions/search",
        REWARDS_POINT_POST: "/rewardsoverview",
        REWARDS_ALL_POINTS_GET: "/rewardsoverview",
        REWARDS_POINT_BY_ID_GET: function (id) { return "/rewardsoverview/".concat(id); },
        FILTERS: function (type) { return "redemptions/getfilters?type=".concat(type); },
    },
    USDT_COIN_VALUE: {
        BINANCE: "https://api.binance.com/api/v3/ticker?symbols=[\"USDCUSDT\",\"ETHUSDT\",\"BNBUSDT\",\"BTCUSDT\",\"TRXUSDT\"]",
    },
};
export var API_CONSTANTS_ADMIN = {
    LOGIN: {
        GET_ALL_USER: "admin/user/searchuser",
        REGISTER: "admin/register",
    },
    USER: {
        USER_SEARCH_FILTER: "admin/user/search",
        GET_USER_DETAILS: function (id) { return "admin/user/".concat(id, "/getuserdetails"); },
        UPDATE_USER: function (id) { return "admin/user/".concat(id, "/updateuserInfo"); },
        USER_FILTERS: "admin/user/getuserfilters",
        APPROVE_USER: function (id) { return "admin/user/".concat(id, "/approvelogin"); },
        REMOVE_USER: function (id) { return "admin/user/".concat(id, "/removeuser"); },
    },
    ORDER: {
        GET_ORDER: "admin/order/getordersbyobjectid",
        GET_ORDER_BY_ID: function (id) { return "admin/order/".concat(id, "/getorderbyid"); },
        ORDER_FILTERS: "admin/order/getorderfilters",
    },
    DASHBOARD: {
        GET_DASHBOARD: "admin/dashboard/dashboard",
    },
    ADVERTISEMENT: {
        GET_ADS_BY_OBJECT_ID: "admin/advertisement/getads",
        GET_ADS_BY_ID: function (id) {
            return "admin/advertisement/".concat(id, "/getadvertisementbyid");
        },
        UPDATE_AD: function (id) { return "admin/advertisement/".concat(id, "/updateadvertisement"); },
        ADS_FILTERS: "admin/advertisement/getadsfilters",
        ADVERTISEMENT_TIME_LIMIT: "admin/advertisement/advertisementtimelimit",
    },
    WALLET: {
        GET_WALLET: function (objectId) {
            return "admin/wallet/".concat(objectId, "/getwalltbyobjectid");
        },
        GET_WALLET_ID: function (walletId) {
            return "admin/wallet/".concat(walletId, "/gettransactionbywalletid");
        },
        GET_ALL_TRANSACTIONS: "admin/transaction/getalltransactions",
        GET_WALLET_TRANSACTIONS: function (objectId) {
            return "admin/wallet/".concat(objectId, "/gettransactionbyobjectid");
        },
        GET_WALLET_TRANSACTIONS_HASH: function (transactionHash) {
            return "admin/wallet/".concat(transactionHash, "/gettransactionbyhash");
        },
        TRANSACTIONS_FILTERS: "admin/transaction/gettransactionfilters",
        WALLET_FILTERS: "admin/wallet/getwalletfilters",
        GET_WALLET_TOKEN: function (objectId) {
            return "admin/wallet/".concat(objectId, "/getwallettokensbyobjectid");
        },
        REWARDS_POINT_BY_ID_GET: function (id) { return "admin/rewardsoverview/".concat(id, "/rewardsoverviewbyid"); },
    },
    IBTREE: {
        GET_IB_TREE: function (id) { return "admin/User/".concat(id, "/getIbTree"); },
    },
    USERSETTINGS: {
        GET_ALL_SETTINGS: function (objectId) {
            return "admin/usersettings/getall?objectId=".concat(objectId);
        },
        POST_SETTINGS: function (objectId) {
            return "admin/usersettings/updateusersettings?objectId=".concat(objectId);
        },
    },
    ROLES: {
        POST_ROLE: "admin/roles",
        GET_ROLE: "admin/roles/getall",
        UPDATE_DELETE_ROLE: function (id) { return "admin/roles/".concat(id); },
    },
    PERMISSIONS: {
        GET_POST_PERMISSION: "admin/permissions",
        UPDATE_DELETE_PERMISSION: function (id) { return "admin/permissions/".concat(id); },
    },
    ROLE_PERMISSIONS: {
        GET_POST_ROLE_PERMISSIONS: "admin/rolepermissions",
        GET_PERMISSIONS_BY_ROLE: function (id) { return "admin/rolePermissions/".concat(id); },
    },
    ROLE_ASSIGNE: {
        GET_POST_ROLE_ASSIGNE: "admin/userroles",
        USER_ROLE_FILTER: "admin/userRoles/userRoleFilter",
        DELETE_ASSIGNE_ROLE_BY_ID: function (id) { return "admin/userroles/".concat(id); },
    },
    ATTACHMENTS: {
        CHAT_MESSAGE: "admin/chatmessage",
        ATTACHMENTS: "admin/attachments",
        VERIFICATION_ATTACHMENTS: function (refId) {
            return "admin/attachments/getattachmentbyrefid/".concat(refId);
        },
    },
    TERMS: {
        TERMS_AND_CONDITIONS_UPDATE: "admin/settings/update",
        EMAIL_SENDER: "admin/user/sendbulkemail",
    },
    APPEAL: {
        APPEAL_DATA: "admin/appeal/searchappeal",
        APPEAL_DATA_FILTER: "admin/appeal/getappealfilters",
        APPEAL_DATA_UPDATE: function (id) { return "admin/appeal/updateappeal/".concat(id); },
    },
};
export var API_CONSTANTS_WALLET = {
    WALLET: {
        GET_TRANSACTION: function (objectId) {
            return "wallet/".concat(objectId, "/gettransactionbyobjectid");
        },
        GET_TOKEN_INFO: function (objectId) {
            return "wallet/".concat(objectId, "/getwallettokensoverviewbyobjectid");
        },
        GET_STATISTICS: function (objectId) {
            return "wallet/".concat(objectId, "/getwalletstatisticsbyobjectid");
        },
        GET_ANALYTICS: function (objectId) {
            return "wallet/".concat(objectId, "/getwalletanalyticsbyobjectid");
        },
        GET_TRANSACTIONHASH: function (transactionHash) {
            return "wallet/".concat(transactionHash, "/gettransactionbyhash");
        },
    },
};
