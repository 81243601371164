import React from "react";
import FooterComponent from "../Components/Footer/FooterComponent";
import HeaderComponent from "../Components/Header/HeaderComponent";

const MainLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <div className="h-screen overflow-y-auto overflow-x-hidden flex flex-col bg-sky-50">
      <HeaderComponent />
      <div className="w-full">{children}</div>
      <FooterComponent />
    </div>
  );
};

export default MainLayout;
