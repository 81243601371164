import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { Bounce, toast as reactToastifyToast, ToastContainer } from 'react-toastify';
var ToastManager = /** @class */ (function () {
    function ToastManager() {
    }
    ToastManager.addToast = function (message, type) {
        var _this = this;
        if (type === void 0) { type = "default"; }
        if (!message)
            return;
        if (this.toasts.some(function (toast) { return toast.message === message; })) {
            return;
        }
        var toastId = this.toastIdCounter++;
        var toastReactId = reactToastifyToast(message, { type: type, style: { fontFamily: 'Saira' } });
        var newToast = { id: toastId, message: message, type: type, toastReactId: toastReactId };
        this.toasts.push(newToast);
        setTimeout(function () {
            _this.removeToast(toastId);
        }, 5000); // 5000 milliseconds
    };
    ToastManager.removeToast = function (toastId) {
        var toastIndex = this.toasts.findIndex(function (toast) { return toast.id === toastId; });
        if (toastIndex !== -1) {
            var toast = this.toasts[toastIndex];
            reactToastifyToast.dismiss(toast.toastReactId);
            this.toasts.splice(toastIndex, 1);
        }
    };
    ToastManager.toasts = [];
    ToastManager.toastIdCounter = 1;
    // Custom color codes for success and error
    ToastManager.toastColors = {
        success: '#5cb85c',
        error: '#d9534f',
        default: '#333333',
        info: '',
        warning: ''
    };
    return ToastManager;
}());
export var addToast = ToastManager.addToast.bind(ToastManager);
export var removeToast = ToastManager.removeToast.bind(ToastManager);
var TosterNotification = function () {
    return React.createElement(ToastContainer, { position: "top-center", autoClose: 3000, hideProgressBar: false, newestOnTop: false, closeOnClick: true, rtl: false, pauseOnFocusLoss: true, draggable: true, pauseOnHover: true, theme: "dark", transition: Bounce });
};
export default TosterNotification;
