import React, { createContext, useContext, useState } from 'react';

interface RoutingContextType {
  lastVisitedUrl: string;
  setLastVisitedUrl: (url: string) => void;
}

const RoutingContext = createContext<RoutingContextType | undefined>(undefined);

export const useRoutingContext = () => {
  const context = useContext(RoutingContext);
  if (!context) {
    throw new Error('useRoutingContext must be used within a RoutingProvider');
  }
  return context;
};

interface RoutingProviderProps {
  children: React.ReactNode;
}

export const RoutingProvider: React.FC<RoutingProviderProps> = ({ children }) => {
  const [lastVisitedUrl, setLastVisitedUrl] = useState<string>('');

  return (
    <RoutingContext.Provider value={{ lastVisitedUrl, setLastVisitedUrl }}>
      {children}
    </RoutingContext.Provider>
  );
};