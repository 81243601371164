import { registerUser, signOutUser } from "XonickPay-Auth";
import React, { useEffect, useState } from "react";
import { FcGoogle } from "react-icons/fc";
import { useNavigate } from "react-router-dom";
import {
  Button,
  CheckBox,
  InputLabelField,
  LinkComponent,
  PopupComponent,
  UseFormikForm,
  addToast,
  yup,
} from "XonickPay-Components-Lib";
import { SignUp, TermsAndConditions } from "XonickPay-Services";
import AuthContainer from "../../Layouts/AuthContainer/AuthContainer";
import { OPEN_ROUTES_CONSTANTS } from "../../Constants/routesConstant";
import { CredentialsProps } from "../../Models/types";
import { signInWithGoogle } from "../../Utils/VerificationFlow";
import { initAxios } from "../../config/http-common";
import { getSessionStorageData } from "../../Utils/StorageDataSetGet";
import logoImage from "../../Assets/xonick_pay_logo.svg";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { useRoutingContext } from "../../Context/PreviousUrlContext";

const Register: React.FC = () => {
  const [userExisted, setUserExisted] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isCheckBoxChecked, setIsCheckBoxChecked] = useState(false);
  const [termsHtml, setTermsHtml] = useState<string>();
  const [loading, setLoading] = useState(false);
  const { lastVisitedUrl, setLastVisitedUrl } = useRoutingContext();
  
  if(lastVisitedUrl){
    caches.open('my-cache').then(cache => {
      cache.put('/data', new Response(lastVisitedUrl));
  });
  }
let previousUrl: any;
caches.open('my-cache').then(cache => {
  cache.match('/data').then(response => {
      if (response) {
          response.text().then(data => {
            previousUrl = data
            updateCacheAndScheduleDeletion(data)
          });
      }
  }).catch(error => {
    return;
  });
});

let cachedData = null;
let lastUpdateTime: any = null;

function updateCache(data: any) {
  cachedData = data;
  lastUpdateTime = Date.now();
}

async function deleteIfExpired() {
  try {
    if (lastUpdateTime && (Date.now() - lastUpdateTime > 15 * 60 * 1000)) {
      const cache = await caches.open('my-cache');
      const deleted = await cache.delete('/data');
      if (deleted) {
        cachedData = null;
        lastUpdateTime = null;
        setLastVisitedUrl(null)
      }

    }
  } catch (error) {
    console.error('Error handling cache:', error);
  }
}

setInterval(deleteIfExpired, 1000 * 60);

function updateCacheAndScheduleDeletion(data: any) {
  updateCache(data);
  setTimeout(() => {
    deleteIfExpired();
  }, 15 * 60 * 1000);
}
  
  const navigate = useNavigate();
  initAxios();

  useEffect(() => {
    if (userExisted) {
      addToast("User is already existed", "error");
      navigate(OPEN_ROUTES_CONSTANTS.LOGIN);
      setUserExisted(false);
    }
  }, [userExisted]);

  const urlParams = new URLSearchParams(window.location.search);
  const ibReferralCode = urlParams.get("ibreferral");

  const handleSubmit = async (values: CredentialsProps) => {
    try {
      setLoading(true)
      const { user, token, fcmtoken } = await registerUser(
        values.email,
        values.password
      );
      if (user) {
        if (user.emailVerified === false) {
          addToast(
            "Email verification link sent to registered email id.",
            "info"
          );
        }
        const parentIbReferralCode = ibReferralCode ? ibReferralCode : 0
        await SignUp({ fcmToken: fcmtoken, parentIbReferralCode : parentIbReferralCode }).then();
        setTimeout(() => {
          navigate(OPEN_ROUTES_CONSTANTS.LOGIN);
          signOutUser();
          setLoading(false);
        }, 2000);
      } else {
        signOutUser();
        setLoading(false);
        throw new Error("User is null");
      }
    } catch (error) {
      setLoading(false)
      if (error instanceof Error) {
        const errorMessage = error.message;
        const lastBracketIndex = errorMessage.lastIndexOf("(");
        const lastBracket = errorMessage.substring(lastBracketIndex);

        // User is already existed than error given below message
        if (lastBracket === "(auth/email-already-in-use).") {
          setUserExisted(true);
        } else {
          console.error("An error occurred:", errorMessage);
        }
      }
      
    }
  };

  const initialValues: CredentialsProps = {
    email: "",
    password: "",
  };

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email("Invalid email")
      .required("Email is required")
      .matches(/@[^.]*\./),
    password: yup
      .string()
      .required("Password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Password must be min 8 characters, it contains 1 special character, 1 uppercase, 1 Numeric and 1 lowercase"
      ),
  });

  const [showPassword, setShowPassword] = useState(false);

  const handleCheckBoxChecked = async () => {
    const objectId = getSessionStorageData("UID");
    const data = {
      objectId: objectId,
      isTermsAndCondition: true,
    };
    if (isCheckBoxChecked) {
      await TermsAndConditions(data, objectId);
      setIsPopupOpen(false);
      signInWithGoogle(location, navigate, setIsPopupOpen, setTermsHtml, previousUrl);
    }
  };

  const navigateOnLoginPage = () => {
    navigate(`${OPEN_ROUTES_CONSTANTS.LOGIN}`, {
      state: {referralCode: ibReferralCode}
    });
  }

  return (
    <>
        <AuthContainer>
          <img src={logoImage} alt="logo-image" className="w-48 mx-auto" />
          <h2 className="md:text-4xl text-3xl text-primary-600 text-center font-bold mb-6 my-4">
            Register
          </h2>
          {loading && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-500 bg-opacity-50 z-50">
          <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-gray-900"></div>
        </div>
      )}
          <UseFormikForm
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            formClassName="flex flex-col justify-evenly h-full"
          >
            {(formik, handleSubmit) => (
              <form
                className="flex flex-col justify-evenly h-full"
                onSubmit={handleSubmit}
              >
                <div className="mb-4">
                  <InputLabelField
                    label={"Email"}
                    name="email"
                    type="email"
                    placeholder="Email"
                    onChange={(event) =>
                      formik.setFieldValue("email", event.target.value)
                    }
                    onBlur={() => formik.setFieldTouched("email", true)}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div className="text-red-500">{formik.errors.email}</div>
                  ) : (
                    <div />
                  )}
                </div>
                <div className="mb-10 relative">
                  <InputLabelField
                    label={"Password"}
                    name="password"
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    autoComplete="password"
                    onChange={(event) =>
                      formik.setFieldValue("password", event.target.value)
                    }
                    onBlur={() => formik.setFieldTouched("password", true)}
                  />
                  {formik.touched.password && formik.errors.password ? (
                    <div className="text-red-500">{formik.errors.password}</div>
                  ) : (
                    <div />
                  )}
                  <span
                    className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer h-12"
                    onClick={() => setShowPassword(!showPassword)} // Toggle showPassword state
                  >
                    {showPassword ? <BsEye /> : <BsEyeSlash />}
                  </span>
                </div>
                <Button
                  type="submit"
                  btnClassName="w-full py-2 px-4 rounded-md bg-gradient-to-r from-orange-500 to-amber-500 text-white text-lg from-20%"
                  disabled={formik.isSubmitting}
                >
                  Register
                </Button>
                <div className="text-center flex flex-col justify-evenly h-44">
                  <div className="flex items-center">
                    <div className="bg-gray-200 h-0.5 w-full" />
                    <span className="mx-2">or</span>
                    <div className="bg-gray-200 h-0.5 w-full" />
                  </div>
                  <p className="border rounded w-full py-2 px-3 text-gray-500">
                    <LinkComponent
                      to="#"
                      onClick={async () => {
                        signInWithGoogle(
                          location,
                          navigate,
                          setIsPopupOpen,
                          setTermsHtml,
                          setLoading,
                          previousUrl
                        );
                      }}
                      className="flex items-center justify-center"
                    >
                      Register with Google
                      <span className="mx-2">
                        <FcGoogle />
                      </span>
                    </LinkComponent>
                  </p>
                  <p>
                    Already have an account?
                    <LinkComponent
                      to=""
                      onClick = {navigateOnLoginPage}
                      className="font-medium text-primary-600 hover:underline ml-1"
                    >
                      Login
                    </LinkComponent>
                  </p>
                </div>
              </form>
            )}
          </UseFormikForm>
          <PopupComponent
          isOpen={isPopupOpen}
          popupClassName="bg-gradient-to-b from-other-50 from-5% max-w-screen-sm"
          acceptClassName={`bg-gradient-to-r from-orange-500 to-amber-500 text-white ${
            isCheckBoxChecked ? "" : "opacity-50 cursor-not-allowed"
          }`}
          acceptText={"Confirm"}
          onAccept={handleCheckBoxChecked}
          onClose={() => {
            setIsPopupOpen(false),
              signOutUser();
          }}
          childrenClassName="h-[75vh]"
        >
          <div dangerouslySetInnerHTML={{ __html: termsHtml || "" }} />
          <div className="mt-4">
            <CheckBox
              label={"Accept all Terms and Conditions"}
              onChange={() => setIsCheckBoxChecked(!isCheckBoxChecked)}
            />
          </div>
        </PopupComponent>
        </AuthContainer>
    </>
  );
};

export default Register;
