import React from "react";
import BuySellCardbg from "../../Assets/new-bg-custom.svg";

const BuySellPlacingCard = () => {
  return (
    <div
      className="relative bg-cover bg-fixed bg-center bg-no-repeat overflow-hidden"
      style={{ backgroundImage: `url(${BuySellCardbg})` }}
    >
      <div className="lg:pt-40">
        <div className="relative">
          <p className="absolute hidden lg:block text-white z-0 top-60 font-bold text-2xl w-[3000px] border-t-4 border-orange-400 border-dashed"></p>
        </div>
        <h1 className="text-2xl ssm:text-3xl md:text-4xl font-bold mb-10 text-center text-white p-4">
          Placing a Buy Order
        </h1>
        <div className="grid lg:grid-cols-5 grid-cols-2 gap-4 md:p-4 md:mx-10 mx-3 3xl:ml-64 3xl:mr-[17rem] md:ml-24 lg:ml-14 mb-4">
          <div className="text-center md:text-left md:mr-6 mt-4 z-10 md:mt-0 w-55 h-55 md:w-57 md:h-57 2xl:w-60 2xl:h-60 rounded-2xl bg-white">
            <h2 className="text-xl text-orange-400 md:text-2xl font-bold mt-4 text-center">
              Step 1
            </h2>
            <p className="md:mt-10 mt-2 text-lg md:text-lg text-center p-2">
              Go to the P2P section and click on “BUY”
            </p>
          </div>
          <div className="text-center md:text-left md:mr-6 mt-4 z-10 md:mt-0 w-55 h-55 md:w-57 md:h-57 2xl:w-60 2xl:h-60 rounded-2xl bg-white">
            <h2 className="text-xl text-orange-400 md:text-2xl font-bold mt-4 text-center">
              Step 2
            </h2>
            <p className="md:mt-10 text-lg md:text-lg text-center p-2">
              Select USDT as the currency you want to buy and INR as the
              currency you want to pay with.
            </p>
          </div>
          <div className="text-center md:text-left md:mr-6 mt-4 z-10 lg:mt-0 md:mt-2 lg:mb-0 md:mb-2 lg:ml-0 w-55 h-55 md:w-57 md:h-57 2xl:w-60 2xl:h-60 rounded-2xl bg-white col-span-2 lg:col-span-1">
            <h2 className="text-xl text-orange-400 md:text-2xl font-bold mt-4 text-center">
              Step 3
            </h2>
            <p className="md:mt-10 text-lg md:text-lg text-center p-2">
              Enter the amount of USDT you wish to purchase.
            </p>
          </div>
          <div className="text-center md:text-left md:mr-6 mt-4 z-10 md:mt-0 w-55 h-55 md:w-57 md:h-57 2xl:w-60 2xl:h-60 rounded-2xl bg-white">
            <h2 className="text-xl text-orange-400 md:text-2xl font-bold mt-4 text-center">
              Step 4
            </h2>
            <p className="md:mt-10 text-lg md:text-lg text-center p-2">
              Review the terms and conditions, then submit your buy order.
            </p>
          </div>
          <div className="text-center md:text-left md:mr-6 mt-4 z-10 md:mt-0 w-55 h-55 md:w-57 md:h-57 2xl:w-60 2xl:h-60 rounded-2xl bg-white">
            <h2 className="text-xl text-orange-400 md:text-2xl font-bold mt-4 text-center">
              Step 5
            </h2>
            <p className="md:mt-10 text-lg md:text-lg text-center p-2">
              Complete the Aadhaar E-Sign and face verification to finalize your
              order and sign your undertaking form
            </p>
          </div>
        </div>
      </div>
      <div className="pb-20 mb-2">
        <div className="relative">
          <p className="absolute hidden lg:block text-white z-0 tracking-widest top-60 font-bold text-2xl w-[3000px] border-t-4 border-orange-400 border-dashed"></p>
        </div>
        <h1 className="text-2xl ssm:text-3xl md:text-4xl font-bold mb-10 text-center text-white p-4">
          Placing a Sell Order
        </h1>
        <div className="grid lg:grid-cols-5 grid-cols-2 gap-4 md:p-4 md:mx-10 mx-3 3xl:ml-64 3xl:mr-[17rem] md:ml-24 lg:ml-14">
          <div className="text-center md:text-left md:mr-6 mt-4 z-10 md:mt-0 w-55 h-55 md:w-57 md:h-57 2xl:w-60 2xl:h-60 rounded-2xl bg-white">
            <h2 className="text-xl text-orange-400 md:text-2xl font-bold mt-4 text-center">
              Step 1
            </h2>
            <p className="mt-10 text-lg md:text-lg text-center p-2">
              Go to the P2P section and click on “Sell”
            </p>
          </div>
          <div className="text-center md:text-left md:mr-6 mt-4 z-10 md:mt-0 w-55 h-55 md:w-57 md:h-57 2xl:w-60 2xl:h-60 rounded-2xl bg-white">
            <h2 className="text-xl text-orange-400 md:text-2xl font-bold mt-4 text-center">
              Step 2
            </h2>
            <p className="mt-10 text-lg md:text-lg text-center p-2">
              Select USDT as the currency you want to sell and INR as the
              currency you want to receive.
            </p>
          </div>
          <div className="text-center md:text-left md:mr-6 mt-4 z-10 lg:mt-0 md:mt-2 lg:mb-0 md:mb-2 lg:ml-0 w-55 h-55 md:w-57 md:h-57 2xl:w-60 2xl:h-60 rounded-2xl bg-white col-span-2 lg:col-span-1">
            <h2 className="text-xl text-orange-400 md:text-2xl font-bold mt-4 text-center">
              Step 3
            </h2>
            <p className="mt-10 text-lg md:text-lg text-center p-2">
              Enter the amount of USDT you wish to sell.
            </p>
          </div>
          <div className="text-center md:text-left md:mr-6 mt-4 z-10 md:mt-0 w-55 h-55 md:w-57 md:h-57 2xl:w-60 2xl:h-60 rounded-2xl bg-white">
            <h2 className="text-xl text-orange-400 md:text-2xl font-bold mt-4 text-center">
              Step 4
            </h2>
            <p className="mt-10 text-lg md:text-lg text-center p-2">
              Review the terms and conditions, then submit your sell order.
            </p>
          </div>
          <div className="text-center md:text-left md:mr-6 mt-4 z-10 md:mt-0 w-55 h-55 md:w-57 md:h-57 2xl:w-60 2xl:h-60 rounded-2xl bg-white">
            <h2 className="text-xl text-orange-400 md:text-2xl font-bold mt-4 text-center">
              Step 5
            </h2>
            <p className="mt-10 text-lg md:text-lg text-center p-2">
              Complete the Aadhaar E-Sign and face verification to finalize your
              order.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuySellPlacingCard;
