import TronWeb from "tronweb";

interface TronWebConfig {
  fullHost: string;
  privateKey?: string;
}
1;

// Example of what should be in './Config'
// export const tronWebConfig: TronWebConfig = {
//   fullHost: 'https://api.shasta.trongrid.io'
// };

import { tronWebConfig } from "./Config";

class TronWebInstance {
  private static instances: Map<string, TronWeb> = new Map();

  private constructor() {
    // Prevent construction outside of the getInstance method
    throw new Error(
      "Use TronWebInstance.getInstance() to get the TronWeb instance."
    );
  }

  static getInstance(privateKey?: string): TronWeb {
    const key = privateKey || "";

    if (!TronWebInstance.instances.has(key)) {
      if (!tronWebConfig.fullHost) {
        throw new Error("TronWeb configuration must include a fullHost URL.");
      }

      const config: TronWebConfig = {
        ...tronWebConfig,
        privateKey: privateKey, // explicitly pass privateKey, whether it's undefined or not
      };
      const tronWeb = new TronWeb(
        config.fullHost,
        config.fullHost, // assuming the same host for the solidityNode and eventServer
        config.fullHost,
        config.privateKey
      );
      TronWebInstance.instances.set(key, tronWeb);
    }

    return TronWebInstance.instances.get(key)!; // The '!' asserts that the value will not be null
  }
}

export default TronWebInstance;
