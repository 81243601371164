import React, { useState, useEffect, useRef } from "react";
import InputFieldComponent from "../Input/InputFieldComponent";
import { IoChatbox, IoSend } from "react-icons/io5";
import { ImAttachment } from "react-icons/im";
import clsx from "clsx";
import { BsFillInfoCircleFill } from "react-icons/bs";
var ChatBoxComponent = function (_a) {
    var profileImage = _a.profileImage, name = _a.name, initialMessages = _a.initialMessages, onSendMessage = _a.onSendMessage, renderMessage = _a.renderMessage, _b = _a.inputPlaceholder, inputPlaceholder = _b === void 0 ? "Write Here..." : _b, btnClassName = _a.btnClassName, chatBodyClassName = _a.chatBodyClassName, headerClassName = _a.headerClassName, infoBoxClassName = _a.infoBoxClassName, inputClassName = _a.inputClassName;
    var _c = useState(""), newMessage = _c[0], setNewMessage = _c[1];
    var _d = useState(false), chatboxVisible = _d[0], setChatboxVisible = _d[1];
    var _e = useState(null), attachment = _e[0], setAttachment = _e[1];
    var _f = useState(null), previewUrl = _f[0], setPreviewUrl = _f[1];
    var handleFileChange = function (event) {
        var _a;
        var file = (_a = event.target.files) === null || _a === void 0 ? void 0 : _a[0];
        if (file) {
            setAttachment(file || null);
            var reader_1 = new FileReader();
            reader_1.onload = function () {
                setPreviewUrl(reader_1.result);
            };
            reader_1.readAsDataURL(file);
        }
    };
    var handleInputChange = function (event) {
        setNewMessage(event.target.value);
    };
    var handleSendMessage = function () {
        if (newMessage.trim() || attachment) {
            onSendMessage(newMessage, attachment);
            setNewMessage("");
            setAttachment(null);
        }
    };
    var handleKeyDown = function (event) {
        if (event.key === "Enter" && !event.shiftKey) {
            event.preventDefault();
            handleSendMessage();
        }
    };
    var popupChatbox = function () {
        setChatboxVisible(!chatboxVisible);
    };
    var chatBodyRef = useRef(null);
    useEffect(function () {
        if (chatBodyRef.current) {
            chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
        }
    }, [initialMessages]);
    return (React.createElement(React.Fragment, null,
        React.createElement("button", { className: clsx("transition duration-300 flex items-center fixed bottom-4 right-4 lg:hidden z-10 rounded-full p-3", btnClassName || "bg-blue-500 text-white"), onClick: popupChatbox },
            React.createElement(IoChatbox, { className: "text-4xl text-primary-600 mr-1" }),
            "Chat"),
        React.createElement("div", { className: clsx("overflow-hidden lg:block fixed lg:static bottom-20 right-4 w-full sm:w-1/2 lg:w-auto border border-gray-200 rounded-lg shadow-md bg-white", { block: chatboxVisible, hidden: !chatboxVisible }) },
            React.createElement("div", { className: clsx("sticky top-0 flex items-center justify-between", headerClassName) },
                React.createElement("div", { className: "flex items-center" },
                    React.createElement("img", { src: profileImage, alt: "avatar", className: "w-12 h-12 rounded-full mx-2 shadow-md object-cover" }),
                    React.createElement("div", null,
                        React.createElement("h5", { className: "font-medium text-black" }, name)))),
            React.createElement("div", { className: clsx("border-l-4 mb-2 flex items-start space-x-4 rounded-lg shadow-lg", infoBoxClassName) },
                React.createElement(BsFillInfoCircleFill, { className: "text-blue-500 text-6xl" }),
                React.createElement("div", { className: "text-gray-800 text-sm" },
                    React.createElement("p", { className: "font-semibold" }, "Important:"),
                    React.createElement("p", null, "Kindly avoid third-party payments and completing ongoing trades outside the XonickPay platform. Engaging in such activities violates our policies, and we will not be responsible for any financial loss resulting from these unauthorized trades. If we find or hear about any accounts doing this, we will take strict action."))),
            React.createElement("div", { ref: chatBodyRef, className: clsx("no-scrollbar space-y-3.5 overflow-auto px-4 py-6", chatBodyClassName), style: { height: "calc(100vh - 31rem)" } }, initialMessages && initialMessages.length > 0 ? (initialMessages.map(function (message, index) {
                return renderMessage(message, index);
            })) : (React.createElement("p", null, "No messages to display."))),
            React.createElement("div", { className: clsx("sticky bottom-0 bg-white border-t border-gray-200 shadow-lg z-10", inputClassName) },
                React.createElement("form", { className: "flex items-center p-2 space-x-2" },
                    React.createElement("div", { className: "relative flex-1" },
                        React.createElement(InputFieldComponent, { label: "", placeholder: inputPlaceholder, value: newMessage, onChange: handleInputChange, onKeyDown: handleKeyDown, inputClassName: "h-12 w-full rounded-l-md border border-gray-300 bg-gray-100 pl-4 pr-14 text-black placeholder-gray-500 outline-none focus:border-primary", labelClassName: "hidden" }),
                        attachment && (React.createElement("div", { className: "absolute right-16 top-1/2 transform -translate-y-1/2" },
                            React.createElement("img", { src: previewUrl, alt: attachment.name, className: "max-w-[80px] max-h-[80px] object-cover border border-gray-300 rounded-md" }))),
                        React.createElement("div", { className: "absolute right-4 top-1/2 transform -translate-y-1/2 flex items-center space-x-2" },
                            React.createElement("input", { type: "file", id: "fileInput", className: "hidden", onChange: handleFileChange, "aria-label": "Attach file" }),
                            React.createElement("label", { htmlFor: "fileInput", className: "cursor-pointer text-gray-600 hover:text-gray-800 transition-colors duration-300" },
                                React.createElement(ImAttachment, { className: "text-xl" })))),
                    React.createElement("div", { className: "flex items-center justify-center rounded-r-md bg-blue-500 text-white hover:bg-blue-400 p-3 cursor-pointer", onClick: handleSendMessage },
                        React.createElement(IoSend, { className: "text-xl" })))))));
};
export default ChatBoxComponent;
