import React, { useState } from "react";
import UseFormikForm from "../../components/UseFormikForm/UseFormikForm";
import InputField from "../../components/Input/InputFieldComponent";
import ButtonComponent from "../Button/ButtonComponent";
import clsx from "clsx";
import { IoMdSwap } from "react-icons/io";
var Converter = function (_a) {
    var firstInputLabel = _a.firstInputLabel, secondInputLabel = _a.secondInputLabel, firstInputName = _a.firstInputName, secondInputName = _a.secondInputName, btnClassName = _a.btnClassName, buttonText = _a.buttonText, firstInputSign = _a.firstInputSign, secondInputSign = _a.secondInputSign, initialValues = _a.initialValues, validationSchema = _a.validationSchema, firstInputDivClassName = _a.firstInputDivClassName, secondInputDivClassName = _a.secondInputDivClassName, SwapSignClassName = _a.SwapSignClassName, InputSignClassName = _a.InputSignClassName, firstInputClassName = _a.firstInputClassName, secondInputClassName = _a.secondInputClassName, btnDivClassName = _a.btnDivClassName, onSubmit = _a.onSubmit, inputLabelClassName = _a.inputLabelClassName, conversionRate = _a.conversionRate, _b = _a.defaultFirstInputValue, defaultFirstInputValue = _b === void 0 ? "1.00" : _b, defaultSecondInputValue = _a.defaultSecondInputValue, disabled = _a.disabled, onCancel = _a.onCancel, mainContainerDivClassName = _a.mainContainerDivClassName;
    var _c = useState(false), isSwapped = _c[0], setIsSwapped = _c[1];
    var _d = useState(defaultFirstInputValue ||
        (parseFloat(defaultSecondInputValue) / conversionRate || 0).toFixed(2)), firstInputValue = _d[0], setFirstInputValue = _d[1];
    var _e = useState(defaultSecondInputValue ||
        (parseFloat(defaultFirstInputValue) * conversionRate || 0).toFixed(2)), secondInputValue = _e[0], setSecondInputValue = _e[1];
    var handleSwapCurrencies = function (formik) {
        setIsSwapped(!isSwapped);
        if (isSwapped) {
            setFirstInputValue(formik.values[secondInputName]);
            setSecondInputValue((parseFloat(formik.values[secondInputName]) * conversionRate || 0).toFixed(2));
            formik.setFieldValue([firstInputName], formik.values[secondInputName]);
            formik.setFieldValue([secondInputName], (parseFloat(formik.values[secondInputName]) * conversionRate || 0).toFixed(2));
        }
        else {
            setSecondInputValue(formik.values[firstInputName]);
            setFirstInputValue((parseFloat(formik.values[firstInputName]) / conversionRate || 0).toFixed(2));
            formik.setFieldValue([secondInputName], formik.values[firstInputName]);
            formik.setFieldValue([firstInputName], (parseFloat(formik.values[firstInputName]) / conversionRate || 0).toFixed(2));
        }
    };
    var handleFirstInputChange = function (value, formik) {
        if (/^\d*\.?\d*$/.test(value)) {
            // Allow only digits and a single decimal point
            setFirstInputValue(value);
            setSecondInputValue((parseFloat(value) * conversionRate || 0).toFixed(2));
            formik.setFieldValue([secondInputName], (parseFloat(value) * conversionRate || 0).toFixed(2));
        }
    };
    var handleSecondInputChange = function (value, formik) {
        if (/^\d*\.?\d*$/.test(value)) {
            // Allow only digits and a single decimal point
            setSecondInputValue(value);
            setFirstInputValue((parseFloat(value) / conversionRate || 0).toFixed(2));
            formik.setFieldValue([firstInputName], (parseFloat(value) / conversionRate || 0).toFixed(2));
        }
    };
    return (React.createElement(UseFormikForm, { initialValues: initialValues, validationSchema: validationSchema, onSubmit: onSubmit, formClassName: "mb-4" }, function (formik, handleSubmit) { return (React.createElement("form", { onSubmit: handleSubmit, className: clsx(!mainContainerDivClassName
            ? "flex flex-col items-center space-y-4"
            : mainContainerDivClassName || "") },
        React.createElement("div", { className: clsx("w-full relative", firstInputDivClassName || "") },
            React.createElement(InputField, { label: isSwapped ? secondInputLabel : firstInputLabel, labelClassName: clsx("block text-sm font-bold mb-1", inputLabelClassName || "text-black"), inputClassName: firstInputClassName, type: "text", name: isSwapped ? secondInputName : firstInputName, onChange: function (event) {
                    formik.setFieldValue(isSwapped ? secondInputName : firstInputName, event.target.value);
                    isSwapped
                        ? handleSecondInputChange(event.target.value, formik)
                        : handleFirstInputChange(event.target.value, formik);
                }, value: isSwapped ? secondInputValue : firstInputValue, onBlur: function () {
                    return formik.setFieldTouched(isSwapped ? secondInputName : firstInputName, true);
                }, inputMode: "numeric", pattern: "\\d*\\.?\\d*" }),
            React.createElement("div", { className: clsx(!InputSignClassName
                    ? "absolute inset-y-0 end-0 top-10 h-4 flex items-center pe-2 pointer-events-none"
                    : InputSignClassName || "") }, isSwapped ? secondInputSign : firstInputSign),
            formik.touched[isSwapped ? secondInputName : firstInputName] &&
                formik.errors[isSwapped ? secondInputName : firstInputName] && (React.createElement("div", { className: "text-red-600 text-xs mt-1" }, formik.errors[isSwapped ? secondInputName : firstInputName]))),
        React.createElement("div", { className: "relative mt-2 mb-4" },
            React.createElement(IoMdSwap, { className: clsx("text-xl cursor-pointer", SwapSignClassName || ""), onClick: function () { return handleSwapCurrencies(formik); } })),
        React.createElement("div", { className: clsx("w-full relative flex-1", secondInputDivClassName || "") },
            React.createElement(InputField, { label: isSwapped ? firstInputLabel : secondInputLabel, labelClassName: clsx("block text-sm font-bold mb-1", inputLabelClassName || "text-black"), inputClassName: secondInputClassName, type: "text", name: isSwapped ? firstInputName : secondInputName, onChange: function (event) {
                    formik.setFieldValue(isSwapped ? firstInputName : secondInputName, event.target.value);
                    isSwapped
                        ? handleFirstInputChange(event.target.value, formik)
                        : handleSecondInputChange(event.target.value, formik);
                }, value: isSwapped ? firstInputValue : secondInputValue, onBlur: function () {
                    return formik.setFieldTouched(isSwapped ? firstInputName : secondInputName, true);
                }, inputMode: "numeric", pattern: "\\d*\\.?\\d*" }),
            React.createElement("div", { className: clsx(!InputSignClassName
                    ? "absolute inset-y-0 end-0 top-10 h-4 flex items-center pe-2 pointer-events-none"
                    : InputSignClassName || "") }, isSwapped ? firstInputSign : secondInputSign),
            formik.touched[isSwapped ? firstInputName : secondInputName] &&
                formik.errors[isSwapped ? firstInputName : secondInputName] && (React.createElement("div", { className: "text-red-600 text-xs mt-1" }, formik.errors[isSwapped ? firstInputName : secondInputName]))),
        buttonText && (React.createElement("div", { className: clsx("w-full flex space-x-2", btnDivClassName || "") },
            React.createElement(ButtonComponent, { type: "button" // Changed type to button
                , className: clsx("flex-1 py-2 px-4 rounded-lg transition duration-300", "bg-gray-300 hover:bg-gray-500 text-black border-gray-200"), disabled: disabled, onClick: onCancel }, "Cancel"),
            React.createElement(ButtonComponent, { type: "submit", className: clsx("flex-1 py-2 px-4 rounded-lg transition duration-300", btnClassName || "bg-blue-500 text-white border-gray-200"), disabled: disabled }, buttonText))))); }));
};
export default Converter;
