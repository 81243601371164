var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useState } from "react";
import CheckBoxField from "../CheckBox/CheckBoxFieldComponent";
import { FaAngleDown } from "react-icons/fa";
import clsx from "clsx";
var CheckBoxDropdownComponent = function (_a) {
    var options = _a.options, containerClassName = _a.containerClassName, inputClassName = _a.inputClassName, labelClassName = _a.labelClassName, optionClassName = _a.optionClassName, selectInputClassName = _a.selectInputClassName;
    var _b = useState(false), isOpen = _b[0], setIsOpen = _b[1];
    var _c = useState([]), selectedOptions = _c[0], setSelectedOptions = _c[1];
    var toggleDropdown = function () {
        setIsOpen(!isOpen);
    };
    var handleOptionClick = function (option) {
        if (selectedOptions.includes(option)) {
            setSelectedOptions(function (prevOptions) {
                return prevOptions.filter(function (item) { return item !== option; });
            });
        }
        else {
            setSelectedOptions(function (prevOptions) { return __spreadArray(__spreadArray([], prevOptions, true), [option], false); });
        }
    };
    return (React.createElement("div", { className: containerClassName },
        React.createElement("div", { className: "relative" },
            React.createElement("div", { className: clsx(selectInputClassName === undefined || null
                    ? "flex items-center p-2 border border-gray-300 rounded cursor-pointer"
                    : selectInputClassName), onClick: toggleDropdown },
                React.createElement("span", { className: "mr-2" }, "Selected options:"),
                selectedOptions.length > 0 ? (selectedOptions.join(", ")) : (React.createElement("span", { className: "italic text-gray-500" }, "None")),
                React.createElement(FaAngleDown, null)),
            isOpen && (React.createElement("div", { className: clsx(optionClassName === undefined || null
                    ? "absolute w-full mt-2 p-2 bg-white border border-gray-300 rounded shadow-lg"
                    : optionClassName) }, options.map(function (option) { return (React.createElement(CheckBoxField, { key: option, label: option, checked: selectedOptions.includes(option), onChange: function () { return handleOptionClick(option); }, labelClassName: labelClassName, inputClassName: inputClassName })); }))))));
};
export default CheckBoxDropdownComponent;
