import React from "react";
import { FaCalendarAlt } from "react-icons/fa";
import LinkComponent from "../Link/LinkComponent";
var TimelineComponent = function (_a) {
    var items = _a.items;
    return (React.createElement("ol", { className: "relative border-s border-gray-200" }, items === null || items === void 0 ? void 0 : items.map(function (item, index) { return (React.createElement("li", { key: index, className: "mb-10 ms-6" },
        React.createElement("span", { className: "absolute flex items-center justify-center w-10 h-10 bg-blue-100 rounded-full -start-6" },
            React.createElement(FaCalendarAlt, null)),
        React.createElement("h3", { className: "mb-1 text-lg font-semibold text-gray-900" }, item.status),
        React.createElement("time", { className: "block mb-2 text-sm font-normal leading-none text-gray-400" }, item.appealDateTime),
        React.createElement("p", { className: "mb-4 text-base font-normal text-gray-500" }, item.description),
        item.buttonLabel && item.link && item.buttonLink && (React.createElement(LinkComponent, { to: item.link, className: "inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:outline-none focus:ring-gray-100 focus:text-blue-700" },
            item.buttonLink,
            item.buttonLabel)))); })));
};
export default TimelineComponent;
