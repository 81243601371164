import React, { useState } from "react";
import Tab from "./TabComponent";
var TabsComponent = function (_a) {
    var tabs = _a.tabs, children = _a.children, activeClassName = _a.activeClassName, inActiveClassName = _a.inActiveClassName, onClickValue = _a.onClickValue;
    var _b = useState(0), activeTabIndex = _b[0], setActiveTabIndex = _b[1];
    var handleTabClick = function (index) {
        onClickValue();
        setActiveTabIndex(index);
    };
    var renderTabs = function () {
        return tabs.map(function (tab, index) { return (React.createElement(Tab, { key: index, label: tab.label, isActive: index === activeTabIndex, onClick: function () { return handleTabClick(index); }, activeClassName: activeClassName, inActiveClassName: inActiveClassName })); });
    };
    var renderTabContent = function () {
        return children.map(function (child, index) { return (React.createElement("div", { className: activeTabIndex === index ? "block" : "hidden", key: index }, child)); });
    };
    return (React.createElement("div", null,
        React.createElement("div", { className: "flex w-full" }, renderTabs()),
        React.createElement("div", { className: "mt-4" }, renderTabContent())));
};
export default TabsComponent;
