import React from "react";
import kycIcon from "../../Assets/kyc.svg";
import aadharIcon from "../../Assets/Aadhar.svg";
import undertakingIcon from "../../Assets/Trade-undertaking.svg";
import faceIcon from "../../Assets/Face-Verification.svg";
import BlockChainBg from "../../Assets/new-bg-custom.svg";

const ShadowCards = () => {
  return (
    <div
      className=" pb-5 bg-fixed bg-no-repeat bg-cover"
      style={{ backgroundImage: `url(${BlockChainBg})` }}
    >
      <div className=" max-w-7xl mx-auto mb-5 3xl:w-full">
        <h1 className="text-2xl md:text-3xl text-white lg:text-4xl 2xl:text:5xl font-bold text-black text-center p-10">
          Xonick Pay's&nbsp;
          <span className="text-orange-400">Commitment to your security</span>
        </h1>
        <div className="grid grid-cols-1 gap-6 3xl:gap-10 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 mx-5 3xl:w-full">
          <div className="bg-white border-black-100 p-4 3xl:p-10 rounded-lg flex flex-col items-center transition duration-300 ease-in-out transform hover:shadow-2xl shadow-xl">
            <img
              src={kycIcon}
              alt="Icon 1"
              className="w-14 h-14 object-cover mb-4"
            />
            <div className="text-center">
              <h2 className="text-lg font-bold mb-2">KYC Upon Registration</h2>
              <p className="text-gray-700">
                We verify the identity of each user through government-issued
                documents ensuring that only legitimate users can access and use
                the platform.
              </p>
            </div>
          </div>

          <div className=" bg-white border-black-100 p-4 3xl:p-10 rounded-lg flex flex-col items-center transition duration-300 ease-in-out transform hover:shadow-2xl shadow-xl">
            <img
              src={aadharIcon}
              alt="Icon 2"
              className="w-14 h-14 object-cover mb-4"
            />
            <div className="text-center">
              <h2 className="text-lg font-bold mb-2">
                Aadhaar E-Sign on Each Trade
              </h2>
              <p className="text-gray-700">
                Supported by Digilocker, this step adds an extra layer of
                security, making every transaction traceable and verified.
              </p>
            </div>
          </div>

          <div className="bg-white border-black-100 p-4 3xl:p-10 rounded-lg flex flex-col items-center transition duration-300 ease-in-out transform hover:shadow-2xl shadow-xl">
            <img
              src={undertakingIcon}
              alt="Icon 3"
              className="w-14 h-14 object-cover mb-4"
            />
            <div className="text-center">
              <h2 className="text-lg font-bold mb-2">
                NSDL-Backed Trade Undertaking Forms
              </h2>
              <p className="text-gray-700">
                Confirm the origin and legitimacy of funds for every trade,
                fortified by NSDL’s secure verification.
              </p>
            </div>
          </div>
          <div className="bg-white border-black-100 p-4 3xl:p-10 rounded-lg flex flex-col items-center transition duration-300 ease-in-out transform hover:shadow-2xl shadow-xl">
            <img
              src={faceIcon}
              alt="Icon 4"
              className="w-14 h-14 object-cover mb-4"
            />
            <div className="text-center">
              <h2 className="text-lg font-bold mb-2">Face Verification</h2>
              <p className="text-gray-700">
                This biometric verification matches the identity of the person
                conducting the transaction to the registered user’s identity
                reducing the risk of unauthorized access and identity fraud.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShadowCards;
