import BigNumber from "bignumber.js";
import TronWebInstance from "../Utils/TronWebInstance";
import TronWeb from "tronweb";

// Interfaces
interface Token {
  tokenId: string;
  tokenType: "TRX" | "USDT";
}
interface Balance {
  tokenType: string;
  balance: string;
}

interface Transaction {
  transactionID: string;
  value: number;
  timestamp: number;
  sender: string;
  receiver: string;
}

// Common Response Interface
interface ApiResponse<T> {
  success: boolean;
  data?: T;
  message?: string;
}
export type SwapDirection = "USDT_TO_TRX" | "TRX_TO_USDT";

export interface SwapOptions {
  amount: number;
  direction: SwapDirection;
  recipient: string;
}
// Fetch TRX Balance
const fetchTrxBalance = async (
  address: string
): Promise<ApiResponse<Balance>> => {
  const tronWeb = TronWebInstance.getInstance();
  try {
    const balanceInSun = await tronWeb.trx.getBalance(address);
    const balance = {
      tokenType: "TRX",
      balance: tronWeb.fromSun(balanceInSun),
    };
    return { success: true, data: balance };
  } catch (error) {
    console.error(`Failed to fetch TRX balance for ${address}:`, error);
    return { success: false, message: `Failed to fetch TRX balance: ${error}` };
  }
};

// Fetch TRC20 Balance
const fetchTrc20Balance = async (
  address: string,
  tokenId: string
): Promise<ApiResponse<Balance>> => {
  try {
    const tronWeb = TronWebInstance.getInstance();
    tronWeb.setAddress(address);

    const ownerAddress = tronWeb.address.toHex(address);
    const contract = await tronWeb.contract().at(tokenId);
    const balance = await contract.methods.balanceOf(ownerAddress).call();
    const decimals = await contract.methods.decimals().call();
    const formattedBalance = new BigNumber(balance.toString())
      .div(new BigNumber(10).pow(decimals))
      .toString();
    const tokenBalance = {
      tokenType: "USDT",
      balance: formattedBalance,
    };
    return { success: true, data: tokenBalance };
  } catch (error) {
    console.error(
      `Failed to fetch TRC20 token balance for ${tokenId} and address ${address}:`,
      error
    );
    return {
      success: false,
      message: `Failed to fetch TRC20 token balance: ${error}`,
    };
  }
};

// Get Balances
export const getBalances = async (
  address: string
): Promise<ApiResponse<Balance[]>> => {
  const tokenList: Token[] = [
    { tokenId: "", tokenType: "TRX" },
    { tokenId: "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t", tokenType: "USDT" }, // Example for USDT TRC20
  ];

  try {
    const balancePromises = tokenList.map(async (token) => {
      switch (token.tokenType) {
        case "TRX":
          return fetchTrxBalance(address);
        case "USDT":
          return fetchTrc20Balance(address, token.tokenId);
        default:
          return {
            success: false,
            message: `Unknown token type: ${token.tokenType}`,
          };
      }
    });

    const balances = await Promise.all(balancePromises);
    const validBalances = balances.filter(
      (response) => response.success && response.data
    ) as ApiResponse<Balance>[];
    return {
      success: true,
      data: validBalances.map((response) => response.data!),
    };
  } catch (error) {
    console.error(`Failed to fetch balances for ${address}:`, error);
    return { success: false, message: `Failed to fetch balances: ${error}` };
  }
};

// Send TRX
export const sendTrx = async (
  fromPrivateKey: string,
  toAddress: string,
  amountInTrx: string
): Promise<ApiResponse<any>> => {
  const tronWeb = TronWebInstance.getInstance(fromPrivateKey);
  try {
    const amountInSun = tronWeb.toSun(amountInTrx);
    const tradeObj = await tronWeb.transactionBuilder.sendTrx(
      toAddress,
      amountInSun
    );
    const signedTxn = await tronWeb.trx.sign(tradeObj, fromPrivateKey);
    const receipt = await tronWeb.trx.sendRawTransaction(signedTxn);
    if ("result" in receipt && receipt.result === true) {
      return { success: true, data: receipt };
    } else {
      return { success: false, message: receipt.code, data: receipt };
    }
    // console.log("Transaction receipt:", receipt);
    // if (receipt.code === null || undefined || "") {
    //   return { success: true, data: receipt };
    // } else {
    //   return { success: false, message: receipt.code, data: receipt };
    // }
  } catch (error) {
    console.error(
      `Error sending TRX from ${fromPrivateKey} to ${toAddress}:`,
      error
    );
    return { success: false, message: `Error sending TRX: ${error}` };
  }
};

// Send USDT
export const sendUSDT = async (
  fromPrivateKey: string,
  toAddress: string,
  amountInUSDT: string
): Promise<ApiResponse<any>> => {
  const tronWeb = TronWebInstance.getInstance(fromPrivateKey);
  try {
    const usdtContractAddress = "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t";
    const contract = await tronWeb.contract().at(usdtContractAddress);
    const amountInSmallestUnit = new BigNumber(amountInUSDT)
      .multipliedBy(1e6)
      .toString();
    const receipt = await contract
      .transfer(toAddress, amountInSmallestUnit)
      .send(
        {
          feeLimit: 100000000,
          callValue: 0,
          shouldPollResponse: false,
        },
        fromPrivateKey
      );
    if (receipt !== null) {
      return { success: true, data: receipt };
    } else {
      return { success: false, message: receipt.code, data: receipt };
    }
  } catch (error) {
    console.error(
      `Error sending USDT from ${fromPrivateKey} to ${toAddress}:`,
      error
    );
    return { success: false, message: `Error sending USDT: ${error}` };
  }
};

// Tron Fee
export const tronFee = async (fromPrivateKey: string, txID: string) => {
  const tronWeb = TronWebInstance.getInstance(fromPrivateKey);
  let transactionInfo: any;
  let retries = 50;
  const delay = (ms: number) =>
    new Promise((resolve) => setTimeout(resolve, ms));

  while (retries > 0) {
    transactionInfo = await tronWeb.trx.getTransactionInfo(txID);

    if (transactionInfo && transactionInfo.receipt) {
      const resourcesConsumed = {
        bandwidth: transactionInfo.receipt?.net_usage || 0,
        energy: transactionInfo.receipt?.energy_usage_total || 0,
        fee: tronWeb.fromSun(transactionInfo.fee || 0),
      };
      return { success: true, data: { txID, resourcesConsumed } };
    }
    await delay(3000);
    retries--;
  }
};

// Transfer
export async function transfer(
  fromAddress: string,
  privateKey: string,
  toAddress: string,
  amount: number,
  currency: "TRX" | "USDT"
) {
  try {
    const tronWeb = TronWebInstance.getInstance(privateKey);

    if (currency === "TRX") {
      const tradeobj = await tronWeb.transactionBuilder.sendTrx(
        toAddress,
        amount * 1e6, // Amount in SUN (1 TRX = 1e6 SUN)
        fromAddress
      );

      const signedTxn = await tronWeb.trx.sign(tradeobj);
      const receipt = await tronWeb.trx.sendRawTransaction(signedTxn);
      if ("result" in receipt && receipt.result === true) {
        return { success: true, data: receipt };
      } else {
        return { success: false, message: receipt.code, data: receipt };
      }
    } else if (currency === "USDT") {
      const contractAddress = "TXLAQ63Xg1NAzckPwKHvzw7CSEmLMEqcdj"; // USDT contract address on Tron
      const contract = await tronWeb.contract().at(contractAddress);

      const result = await contract
        .transfer(
          toAddress,
          amount * 1e6 // Amount in USDT (1 USDT = 1e6 smallest unit)
        )
        .send({
          feeLimit: 10000000,
        });

      if (result !== null) {
        return { success: true, data: result };
      } else {
        return { success: false, message: result.code, data: result };
      }
    }
  } catch (error) {
    console.error(`Error transferring ${currency}:`, error);
    throw error;
  }
}

const encryptedPrivateKey = process.env.ENCRYPTED_KEY;
const encryptionKey = process.env.ENCRYPTION_KEY;
const iv = process.env.IV_KEY;
const encryptedSenderAddress = process.env.ENCRYPTED_ADDRESS;
const encryptionSenderAddress = process.env.ENCRYPTION_ADDRESS;
const ivSenderAddress = process.env.IV_ADDRESS;

export async function withdrawRewardPoint(toAddress: string, amount: number) {
  try {
    const encryptedPrivateKeys = encryptedPrivateKey || "";
    const encryptionKeys = Buffer.from(encryptionKey || "", "hex");
    const ivs = Buffer.from(iv || "", "hex");

    // Decrypt the private key
    const crypto = require("crypto");
    const decipher = crypto.createDecipheriv(
      "aes-256-cbc",
      encryptionKeys,
      ivs
    );

    let decryptedPrivateKey = decipher.update(
      encryptedPrivateKeys,
      "hex",
      "utf8"
    );
    decryptedPrivateKey += decipher.final("utf8");

    const encryptedSendersAddress = encryptedSenderAddress || "";
    const encryptionSendersAddress = Buffer.from(
      encryptionSenderAddress || "",
      "hex"
    );
    const ivSendersAddress = Buffer.from(ivSenderAddress || "", "hex");

    // Decrypt the Sender Address
    const cryptoSender = require("crypto");
    const decipherSender = cryptoSender.createDecipheriv(
      "aes-256-cbc",
      encryptionSendersAddress,
      ivSendersAddress
    );

    let decryptedSenderKey = decipherSender.update(
      encryptedSendersAddress,
      "hex",
      "utf8"
    );
    decryptedSenderKey += decipherSender.final("utf8");

    const PrivateDecryptedKey = decryptedPrivateKey;
    const SenderDecryptedAddress = decryptedSenderKey;
    const tronWeb = TronWebInstance.getInstance(PrivateDecryptedKey);
    const tradeobj = await tronWeb.transactionBuilder.sendTrx(
      toAddress,
      amount * 1e6, // Amount in SUN (1 TRX = 1e6 SUN)
      SenderDecryptedAddress
    );
    const signedTxn = await tronWeb.trx.sign(tradeobj);
    const receipt = await tronWeb.trx.sendRawTransaction(signedTxn);

    if ("result" in receipt && receipt.result === true) {
      return { success: true, data: receipt };
    } else {
      return { success: false, message: receipt.code, data: receipt };
    }
  } catch (error) {
    console.error(`Error transferring TRX:`, error);
    throw error;
  }
}

export const fetchTransactionReceipt = async (
  transactionId: string,
  toAddress: string
): Promise<ApiResponse<any>> => {
  const tronWeb = TronWebInstance.getInstance();
  try {
    let receipt: any;
    const maxRetries = 100;

    for (let attempts = 0; attempts < maxRetries; attempts++) {
      receipt = await tronWeb.trx.getTransactionInfo(transactionId);

      if (receipt && receipt.receipt) {
        // console.log(`Attempt ${attempts + 1} of ${maxRetries}`);
        break;
      } else {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        // console.log(
        //   `Attempt ${
        //     attempts + 1
        //   } of ${maxRetries} failed to fetch transaction receipt`
        // );
      }
    }

    if (receipt && receipt.receipt) {
      const transaction = await tronWeb.trx.getTransaction(transactionId);
      const { contract } = transaction.raw_data;
      const transfer = contract[0].parameter.value;

      const from = tronWeb.address.fromHex(transfer.owner_address);
      const to = tronWeb.address.fromHex(transfer.to_address);
      const amount = new BigNumber(transfer.amount).dividedBy(1e6).toString(); // Converting from SUN to USDT

      const bandwidthUsed =
        receipt.receipt.net_usage !== "undefined" &&
        receipt.receipt.net_usage !== "null" &&
        receipt.receipt.net_usage
          ? receipt.receipt.net_usage
          : 0;
      const energyUsed =
        receipt.receipt.energy_usage_total !== "undefined" &&
        receipt.receipt.energy_usage_total !== "null" &&
        receipt.receipt.energy_usage_total
          ? receipt.receipt.energy_usage_total
          : 0;

      const transactionHash = receipt.id;
      const blockNumber = receipt.blockNumber;
      const blockTimestamp = receipt.blockTimeStamp;

      const detailedReceipt = {
        transactionHash,
        from,
        toAddress,
        amount,
        bandwidthUsed,
        energyUsed,
        blockNumber,
        blockTimestamp,
        ...receipt,
      };

      return { success: true, data: detailedReceipt };
    } else {
      throw new Error("Transaction not confirmed in a timely manner");
    }
  } catch (error) {
    console.error(
      `Error fetching transaction receipt for ${transactionId}:`,
      error
    );
    return {
      success: false,
      message: `Error fetching transaction receipt: ${error}`,
    };
  }
};

// Freeze TRX
export const freezeTrx = async (
  privateKey: string,
  amount: string,
  resource: string,
  duration: number
): Promise<ApiResponse<any>> => {
  const tronWeb = TronWebInstance.getInstance(privateKey);
  try {
    const amountInSun = tronWeb.toSun(amount);
    const transaction = await tronWeb.transactionBuilder.freezeBalanceV2(
      amountInSun,
      resource,
      privateKey
    );
    const signedTx = await tronWeb.trx.sign(transaction);
    const receipt = await tronWeb.trx.sendRawTransaction(signedTx);
    return { success: true, data: receipt };
  } catch (error) {
    console.error(
      `Failed to freeze balance with ${amount} TRX for resource ${resource}:`,
      error
    );
    return { success: false, message: `Failed to freeze TRX: ${error}` };
  }
};

// Unfreeze TRX
export const unfreezeTrx = async (
  privateKey: string,
  resource: string
): Promise<ApiResponse<any>> => {
  const tronWeb = TronWebInstance.getInstance(privateKey);
  try {
    const address = tronWeb.defaultAddress.base58;
    const transaction = await tronWeb.transactionBuilder.unfreezeBalance(
      resource,
      address
    );
    const signedTxn = await tronWeb.trx.sign(transaction);
    const receipt = await tronWeb.trx.sendRawTransaction(signedTxn);
    return { success: true, data: receipt };
  } catch (error) {
    console.error(`Error unfreezing TRX for resource ${resource}:`, error);
    return { success: false, message: `Error unfreezing TRX: ${error}` };
  }
};

// Get Freeze Balance
export const getFreezeBalance = async (
  address: string
): Promise<ApiResponse<number>> => {
  const tronWeb = TronWebInstance.getInstance();
  try {
    const account = await tronWeb.trx.getAccount(address);
    return {
      success: true,
      data:
        account.frozen && account.frozen[0]
          ? account.frozen[0].frozen_balance
          : 0,
    };
  } catch (error) {
    console.error(`Failed to fetch frozen balance for ${address}:`, error);
    return {
      success: false,
      message: `Failed to fetch frozen balance: ${error}`,
    };
  }
};

// Get Transaction History
// Get Transaction History by Address
export const getTransactionHistoryByAddress = async (
  address: string
): Promise<ApiResponse<Transaction[]>> => {
  const tronWeb = TronWebInstance.getInstance();
  tronWeb.setAddress(address);
  try {
    const transactions = await tronWeb.trx.getTransactionsFromAddress(
      address,
      50
    ); // Fetching up to 50 transactions
    const formattedTransactions = transactions.map((tx: any) => ({
      transactionID: tx.txID,
      value: tx.raw_data.contract[0].parameter.value.amount,
      timestamp: tx.raw_data.timestamp,
      sender: tx.raw_data.contract[0].parameter.value.owner_address,
      receiver: tx.raw_data.contract[0].parameter.value.to_address,
    }));
    return { success: true, data: formattedTransactions };
  } catch (error) {
    console.error(`Failed to fetch transaction history for ${address}:`, error);
    return {
      success: false,
      message: `Failed to fetch transaction history: ${error}`,
    };
  }
};

export const swapCurrency = async ({
  amount,
  direction,
  recipient,
}: SwapOptions): Promise<string> => {
  try {
    const tronWeb = TronWebInstance.getInstance();
    tronWeb.setAddress(recipient);
    const tradeAmount = tronWeb.toSun(amount); // Convert amount to SUN
    const justSwapRouterAddress = "T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb"; // JustSwap router address

    const justSwapRouter = await tronWeb.contract().at(justSwapRouterAddress);

    if (direction === "USDT_TO_TRX") {
      // Approve USDT transfer
      const usdtContractAddress = "TXLAQ63Xg1NAzckPwKHvzw7CSEmLMEqcdj"; // USDT contract address
      const usdtContract = await tronWeb.contract().at(usdtContractAddress);
      await usdtContract.approve(justSwapRouterAddress, tradeAmount).send();

      // Perform the swap on JustSwap
      const tx = await justSwapRouter
        .swapExactTokensForTRX(
          tradeAmount,
          0,
          [usdtContractAddress, tronWeb.defaultAddress.base58],
          tronWeb.defaultAddress.base58,
          Math.floor(Date.now() / 1000) + 60 * 20
        )
        .send();

      return tx;
    } else if (direction === "TRX_TO_USDT") {
      // Perform the swap on JustSwap
      const usdtContractAddress = "TXLAQ63Xg1NAzckPwKHvzw7CSEmLMEqcdj"; // USDT contract address
      const tx = await justSwapRouter
        .swapExactTRXForTokens(
          0,
          [tronWeb.defaultAddress.base58, usdtContractAddress],
          tronWeb.defaultAddress.base58,
          Math.floor(Date.now() / 1000) + 60 * 20
        )
        .send({
          callValue: tradeAmount, // Send TRX with the transaction
        });

      return tx;
    } else {
      throw new Error("Invalid swap direction");
    }
  } catch (error) {
    console.error("Swap failed:", error);
    throw new Error("Swap transaction failed: " + error); // Improved error message
  }
};
export const swapUSDTtoTRX = async (amount: number, address: string) => {
  try {
    const fullNode = "https://api.trongrid.io"; // Or your preferred full node URL
    const solidityNode = "https://api.trongrid.io"; // Or your preferred Solidity node URL
    const eventServer = "https://api.trongrid.io"; // Or your preferred event server URL

    const tronWeb = new TronWeb(fullNode, solidityNode, eventServer);
    tronWeb.setAddress(address);
    // Convert amount to SUN
    const tradeAmount = tronWeb.toSun(amount);

    // JustSwap router address
    const justSwapRouterAddress = "T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb";

    // USDT contract address
    const usdtContractAddress = "TXLAQ63Xg1NAzckPwKHvzw7CSEmLMEqcdj";

    // Get JustSwap router contract instance
    const justSwapRouter = await tronWeb.contract().at(justSwapRouterAddress);
    // Get USDT contract instance;
    const usdtContract = await tronWeb.contract().at(usdtContractAddress);

    // Approve USDT transfer
    await usdtContract.approve(justSwapRouterAddress, tradeAmount).send();
    // console.log(tronWeb.address.base58);
    // Perform the swap on JustSwap
    const tx = await justSwapRouter
      .swapExactTokensForTRX(
        tradeAmount,
        0,
        [usdtContractAddress, tronWeb.address.base58],
        tronWeb.address.base58,
        Math.floor(Date.now() / 1000) + 60 * 20
      )
      .send();
  } catch (error) {
    console.error(error);
    console.error("Swap failed:", error);
  }
};
