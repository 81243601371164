import React from "react";
import logo from "../../Assets/logo-white.svg";
import facebook from "../../Assets/facebook.svg";
import twitter from "../../Assets/twitter.svg";
import youtube from "../../Assets/youtube.svg";
import { IoCall } from "react-icons/io5";
import { IoMdMail } from "react-icons/io";
import { LinkComponent } from "XonickPay-Components-Lib";

const FooterComponent = () => {
  return (
    <footer className="custom-gradient text-white">
      <div className="container lg:mx-24 mx-6 py-12">
        <div className="grid grid-cols-2 md:grid-cols-4 lg:gap-10 md:gap-1 ssm:gap-8 gap-1">
          <div className="footer-widget col-span-2 md:col-span-1">
            <div className="footer-logo mb-4">
              <LinkComponent to="/">
                <img src={logo} className="w-32 h-auto" alt="logo" />
              </LinkComponent>
            </div>
            <div className="footer-social-icon mb-4">
              <div className="flex mt-2">
                <LinkComponent to="#">
                  <img
                    src={facebook}
                    alt="Facebook"
                    className="w-10 h-10 me-2"
                  />
                </LinkComponent>
                <LinkComponent to="#">
                  <img src={twitter} alt="Twitter" className="w-10 h-10 me-2" />
                </LinkComponent>
                <LinkComponent to="#">
                  <img src={youtube} alt="YouTube" className="w-10 h-10 me-2" />
                </LinkComponent>
              </div>
            </div>
          </div>

          <div className="footer-widget">
            <h3 className="text-lg font-semibold mb-4">Useful Links</h3>
            <ul className="text-gray-300">
              <li>
                <LinkComponent to="/" className="hover:text-white">
                  Home
                </LinkComponent>
              </li>
              <li>
                <LinkComponent to="/about" className="hover:text-white">
                  Mission
                </LinkComponent>
              </li>
              <li>
                <LinkComponent to="/work" className="hover:text-white">
                  How it works
                </LinkComponent>
              </li>
              <li>
                <LinkComponent to="/faq" className="hover:text-white">
                  FAQS
                </LinkComponent>
              </li>
              <li>
                <LinkComponent to="/support" className="hover:text-white">
                  Support
                </LinkComponent>
              </li>
            </ul>
          </div>

          <div className="footer-widget">
            <h3 className="text-lg font-semibold mb-4">Additional Section</h3>
            <ul className="text-gray-300">
              <li>
                <LinkComponent to="#" className="hover:text-white">
                  Terms of Service
                </LinkComponent>
              </li>
              <li>
                <LinkComponent to="#" className="hover:text-white">
                  Privacy Policy
                </LinkComponent>
              </li>
              <li>
                <LinkComponent to="#" className="hover:text-white">
                  T&C
                </LinkComponent>
              </li>
              <li>
                <LinkComponent to="#" className="hover:text-white">
                  24/7 Chat Support
                </LinkComponent>
              </li>
            </ul>
          </div>

          <div className="footer-widget">
            <h3 className="text-lg font-semibold mb-4">Contact Info</h3>
            <ul className="text-gray-300">
              <li className="mb-2 flex items-center">
                <IoMdMail className="mr-2" />
                <LinkComponent to="#" className="hover:text-white">
                  support@xonickpay.com
                </LinkComponent>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <hr className="opacity-40 lg:mx-24 mx-6" />
      <div className="text-left py-6 lg:mx-24 mx-6 my-1">
        <h1 className="text-xl font-semibold">Disclaimer</h1>
        <p className="text-gray-300">Trading on Xonick Pay involves USDT and TRX transactions that are subject to market risks, including volatility and regulatory changes. Please be aware that all trading activities are at your own risk and discretion. Xonick Pay does not guarantee any returns or the performance of assets. We advise users to exercise caution and to fully understand all the risks associated with cryptocurrency trading before proceeding. Xonick Pay holds no responsibility for any losses incurred as a result of trading activities or reliance on the functionality of the platform.</p>

      </div>

      {/* Copyright Area */}
      <div className="bg-gray-800">
        <div className="container mx-auto py-4 text-center text-white">
          <p>
            &copy; 2024 All Rights Reserved.&nbsp;
            <LinkComponent to="#" className="text-orange-500">
              Xonick Pay
            </LinkComponent>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default FooterComponent;
