import React, { useState } from "react";
import Button from "../../components/Button/ButtonComponent";
import { TbMinusVertical } from "react-icons/tb";
import clsx from "clsx";
var CardComponent = function (_a) {
    var isOnline = _a.isOnline, profileImage = _a.profileImage, Title = _a.Title, subTitle1 = _a.subTitle1, subTitle2 = _a.subTitle2, subTitle3 = _a.subTitle3, action = _a.action, col4Title = _a.col4Title, col2Title = _a.col2Title, col3Title = _a.col3Title, col3SubTitle = _a.col3SubTitle, btnClassName = _a.btnClassName, isExpandable = _a.isExpandable, children = _a.children, cardHighlighterClass = _a.cardHighlighterClass, onClick = _a.onClick;
    var _b = useState(false), expanded = _b[0], setExpanded = _b[1];
    var _c = useState(false), isSelected = _c[0], setIsSelected = _c[1];
    var handleButtonClick = function () {
        if (isExpandable) {
            setExpanded(!expanded);
        }
        setIsSelected(!isSelected); // Set the card as selected when button is clicked
        onClick();
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: clsx("bg-white border p-6 rounded-lg flex flex-col md:flex-row items-center justify-between space-y-4 md:space-x-4 mb-4 transition duration-300", isSelected ? cardHighlighterClass : "border-gray-200") },
            React.createElement("div", { className: "flex space-x-3 w-full md:w-1/5" },
                React.createElement("div", { className: "relative w-16 h-16 rounded-full" },
                    React.createElement("img", { src: profileImage, alt: "Profile", className: "rounded-full" }),
                    React.createElement("svg", { className: "absolute top-0 right-0 w-5 h-5 ".concat(isOnline ? "text-green-500" : "text-gray-500"), xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 20 20", fill: "currentColor" },
                        React.createElement("circle", { cx: "10", cy: "10", r: "8" }))),
                React.createElement("div", { className: "text-left flex flex-col min-w-0 break-words" },
                    React.createElement("h6", { className: "text-xl font-semibold text-gray-800 truncate" }, Title),
                    React.createElement("p", { className: "text-gray-600 truncate" }, subTitle1),
                    React.createElement("p", { className: "text-gray-600 truncate" }, subTitle2),
                    React.createElement("p", { className: "text-gray-600 truncate" }, subTitle3))),
            React.createElement("div", { className: "w-full md:w-1/5 ml-36 md:text-center break-words" },
                React.createElement("h6", { className: "text-xl font-semibold text-gray-800 truncate" }, col2Title)),
            React.createElement("div", { className: "w-full md:w-1/5 md:text-center ml-36  break-words" },
                React.createElement("p", { className: "text-lg text-gray-700 truncate" }, col3Title),
                React.createElement("p", { className: "text-gray-600 truncate" }, col3SubTitle)),
            React.createElement("div", { className: "w-full md:w-1/5 md:text-center ml-36 break-words" },
                React.createElement("p", { className: "text-lg text-gray-700 truncate flex items-center md:justify-center" },
                    React.createElement(TbMinusVertical, { className: "text-yellow-500" }),
                    col4Title)),
            React.createElement("div", { className: "w-full md:w-1/5 md:text-center ml-36" },
                React.createElement(Button, { onClick: handleButtonClick, className: clsx(btnClassName
                        ? btnClassName
                        : "bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-lg transition duration-300") }, action))),
        expanded && children));
};
export default CardComponent;
