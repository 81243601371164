export const RegistrationHome = {
    title1: "How to",
    title2: "Register?",
    step1: "Aadhaar verification using digilocker.",
    step2: "PAN verification.",
    step3: "Bank verification with penny drop.",
    step4: "Video KYC.",
  };
  
  export const RegistrationAbout = {
    title1: "Registration and KYC ",
    title2: "Verification",
    step1: "Visit the Xonick Pay website or app and click on Register.",
    step2: "Complete the registration form with your details.",
    step3: "Submit your documents for KYC verification.",
    step4:
      "Once verified, your account will be activated, and you can start trading.",
  };
  