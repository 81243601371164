import clsx from "clsx";
import React from "react";
var CheckBoxFieldComponent = function (_a) {
    var label = _a.label, checked = _a.checked, onChange = _a.onChange, labelClassName = _a.labelClassName, inputClassName = _a.inputClassName, name = _a.name, value = _a.value, disabled = _a.disabled;
    var handleChange = function (e) {
        var isChecked = e.target.checked;
        onChange(isChecked);
    };
    return (React.createElement("div", { className: "flex items-center" },
        React.createElement("input", { id: "checked-checkbox", type: "checkbox", checked: checked, onChange: handleChange, className: clsx("w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded", inputClassName === undefined || null ? "" : inputClassName), name: name, value: value }),
        React.createElement("label", { className: clsx(labelClassName === undefined || null
                ? "ml-2 text-gray-900"
                : labelClassName) }, label)));
};
export default CheckBoxFieldComponent;
