var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { clsx } from "clsx";
import React from "react";
var IconInputFieldComponent = function (_a) {
    var icon = _a.icon, iconInputClassName = _a.iconInputClassName, placeholder = _a.placeholder, type = _a.type, rest = __rest(_a, ["icon", "iconInputClassName", "placeholder", "type"]);
    return (React.createElement("div", { className: "relative flex items-center" },
        icon && React.createElement("div", { className: "absolute left-0 pl-2" }, icon),
        React.createElement("input", __assign({}, rest, { type: type, placeholder: placeholder, className: clsx(iconInputClassName === undefined || null
                ? "border-2 border-gray-300 rounded-md p-2 pl-10 w-full"
                : iconInputClassName) }))));
};
export default IconInputFieldComponent;
