import React from "react";
import trustworthy from "../../Assets/trustworthy.svg";
import easyTrade from "../../Assets/easytrade.svg";
import legitimate from "../../Assets/legitimate.svg";

const Flipcard = () => {
  return (
    <>
      <h1 className="text-2xl md:text-3xl lg:text-4xl 2xl:text:5xl font-bold text-black text-center py-10">
        Why
        <span className="text-orange-400 ml-2">
          Xonick Pay?
        </span>
      </h1>
      <div className="flex flex-col justify-around lg:flex-row items-center p-4 3xl:px-20 mb-10">
        <div className="card">
          <div className="cover" style={{ backgroundImage: `url(${trustworthy})` }}>
            <h1 className="sm:text-xl text-sm md:text-2xl ">Trustworthy</h1>
            <div className="card-back">
              <p>
              Xonick Pay is compliant with India's required certifications and global ISO certification to operate as a INR/USDT trading platform. 
              </p>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="cover" style={{ backgroundImage: `url(${easyTrade})` }}>
            <h1 className="sm:text-xl md:text-2xl">Easy to Trade</h1>
            <div className="card-back">
              <p>
                Register in 4 steps and begin trading within 24 hours of your
                registration.
              </p>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="cover" style={{ backgroundImage: `url(${legitimate})` }}>
            <h1 className="sm:text-xl md:text-2xl">Legitimate</h1>
            <div className="card-back">
              <p>
                Clear and detailed trade undertaking forms to ensure legitimacy
                of money being transferred
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Flipcard;
