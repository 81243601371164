export const setSessionStorageData = (key: string, value: any) => {
    typeof value === 'object' ? 
        sessionStorage.setItem(key, JSON.stringify(value))
        : sessionStorage.setItem(key, value);
}

export const getSessionStorageData = (key: string): any => {
    const storedData = sessionStorage.getItem(key);
    // return typeof storedData === 'string' ? JSON.parse(storedData) : storedData;
    return storedData // JSON.parse is must important 
}