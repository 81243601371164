import { GetTermsAndConditions, VerifyAadhaar } from "XonickPay-Services";
import { setSessionStorageData } from "./StorageDataSetGet";
import { CONSTANTS } from "../Constants/routesConstant";

export const verificationStepsComponent = async (
  data: any,
  navigate: any,
  uid?: any,
  setIsPopupOpen?: any,
  setTermsHtml?: any,
  location?: any,
  previousUrl?: any,
) => {
  if (!data.isTermsAndConditionsApproved) {
    const termsData = await GetTermsAndConditions(uid);
    setTermsHtml(termsData.data);
    setIsPopupOpen(true);
    return;
  }
  setSessionStorageData("isAadhaarVerified", data.isAadhaarVerified);

  if (!data.isAadhaarVerified) {
    setSessionStorageData("VerificationInitialStep", "0");
    const checkAadhaar = await VerifyAadhaar(null, data.objectId);
    window.location.href = checkAadhaar.data.data.result.url.toString();
    return;
  }
  let VerificationInitialStep = 0;
  if (!data.isPanVerified) {
    VerificationInitialStep = 0;
  } else if (!data.isBankVerified) {
    VerificationInitialStep = 1;
  } else if (!data.isVideoVerified || !data.isVideoUploaded) {
    VerificationInitialStep = 2;
  } else if (data.isVideoUploaded && !data.isUserInfoVerified) {
    VerificationInitialStep = 3;
  } else if (!data.isLoginEnabled) {
    navigate(CONSTANTS.WAITING_SCREEN);
    return;
  }
  if (
    data.isPanVerified &&
    data.isBankVerified &&
    data.isVideoVerified &&
    data.isUserInfoVerified &&
    data.isLoginEnabled
  ) {
    sessionStorage.setItem("IsLoggedIn", "true");

    if (location.pathname === "/login") {
      if (previousUrl) {
        navigate(previousUrl);
        return;
      } else {
        navigate(CONSTANTS.DASHBOARD);
        return;
      }
    } else {
      navigate(location.pathname + location.search);
      return;
    }
  }
  sessionStorage.setItem("IsLoggedIn", "true");

  setSessionStorageData(
    "VerificationInitialStep",
    VerificationInitialStep.toString()
  );
  navigate(CONSTANTS.VERIFICATION_STEPS);
};
