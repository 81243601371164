import React, { useState, useEffect } from 'react';
var TimerComponent = function (_a) {
    var duration = _a.duration, onCompletion = _a.onCompletion, className = _a.className;
    var _b = useState({ hours: 0, minutes: 0, seconds: 0 }), timeData = _b[0], setTimeData = _b[1];
    var _c = useState(null), timer = _c[0], setTimer = _c[1];
    var startTimer = function (duration) {
        if (timer)
            clearTimeout(timer);
        var seconds = duration;
        var newTimer = window.setInterval(function () {
            seconds--;
            var hours = Math.floor(seconds / 3600);
            var minutes = Math.floor((seconds % 3600) / 60);
            setTimeData({
                hours: hours,
                minutes: minutes,
                seconds: seconds % 60,
            });
            if (seconds <= 0) {
                clearInterval(newTimer);
                onCompletion(); // Call the completion function
            }
        }, 1000);
        setTimer(newTimer);
    };
    useEffect(function () {
        startTimer(duration);
        return function () {
            if (timer) {
                clearInterval(timer);
            }
        };
    }, [duration]); // Re-run when duration changes
    return (React.createElement("span", { className: className }, "".concat(timeData.hours.toString().padStart(2, '0'), ":").concat(timeData.minutes.toString().padStart(2, '0'), ":").concat(timeData.seconds.toString().padStart(2, '0'))));
};
export default TimerComponent;
