const crypto = require('crypto');
import React, { useState } from "react";
import { Buffer } from "buffer";

export const EncryptionMethod = () => {
  const [inputValue, setInputValue] = useState("");
  const [encryptObject, setEncryptObject] = useState<any>({});

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleEncryption = () => {
    const encryptData = encryptedKey(inputValue);
    setEncryptObject(encryptData);
  };

  const encryptedKey = (key) => {

    const encryptionKey = crypto.randomBytes(32);
    const iv = crypto.randomBytes(16);
    const privateKey = key;
    const cipher = crypto.createCipheriv("aes-256-cbc", encryptionKey, iv);

    let encryptedPrivateKey = cipher.update(privateKey, "utf8", "hex");
    encryptedPrivateKey += cipher.final("hex");

    const encryptedPrivateKeys = encryptedPrivateKey;
    const encryptionKeys = encryptionKey.toString("hex");
    const ivs = iv.toString("hex");

    return {
      encryptedPrivateKeys,
      encryptionKeys,
      ivs,
    };
  };

  return (
    <div className="mx-auto p-6 bg-white shadow-md rounded-lg">
      <input 
        type="text" 
        onChange={handleInputChange} 
        className="w-full px-4 py-2 mb-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
        placeholder="Enter text to encrypt"
      />

      <button 
        onClick={handleEncryption} 
        className="w-full bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 mb-4"
      >
        Encrypt
      </button>

      <div className="text-gray-700">
        <p className="mb-2">
          <span className="font-semibold">Encrypted Key:</span> {encryptObject.encryptedPrivateKeys || "N/A"}
        </p>
        <p className="mb-2">
          <span className="font-semibold">Encryption Key:</span> {encryptObject.encryptionKeys || "N/A"}
        </p>
        <p>
          <span className="font-semibold">IV Key:</span> {encryptObject.ivs || "N/A"}
        </p>
      </div>
    </div>
  );
};

export const DecryptionMethod = () => {
  const [encryptedKeyInputValue, setEncryptedKeyInputValue] = useState("");
  const [encryptionKeyInputValue, setEncryptionKeyInputValue] = useState("");
  const [ivKeyInputValue, setIvKeyInputValue] = useState("");
  const [decryptedPrivateKey, setDecryptedPrivateKey] = useState("");
  const decryptKey = () => {
    try {
      const encryptedPrivateKeys = encryptedKeyInputValue || "";
      const encryptionKeys = Buffer.from(encryptionKeyInputValue || "", "hex");
      const ivs = Buffer.from(ivKeyInputValue || "", "hex");
      const crypto = require("crypto");
      const decipher = crypto.createDecipheriv("aes-256-cbc", encryptionKeys, ivs);

      let decryptedPrivateKey = decipher.update(encryptedPrivateKeys, "hex", "utf8");
      decryptedPrivateKey += decipher.final("utf8");

      return setDecryptedPrivateKey(decryptedPrivateKey)
    } catch (error) {
      console.error("Decryption error:", error);
      return "Decryption failed";
    }
  };

  return (
    <div className="max-w-md mx-auto p-6 bg-white shadow-md rounded-lg">
      <h3 className="text-xl font-semibold text-gray-800 mb-4">Decrypted Key</h3>

      <input 
        type="text" 
        placeholder="Encrypted Key" 
        onChange={(e) => setEncryptedKeyInputValue(e.target.value)} 
        className="w-full px-4 py-2 mb-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
      />

      <input 
        type="text" 
        placeholder="Encryption Key" 
        onChange={(e) => setEncryptionKeyInputValue(e.target.value)} 
        className="w-full px-4 py-2 mb-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
      />

      <input 
        type="text" 
        placeholder="Iv Key" 
        onChange={(e) => setIvKeyInputValue(e.target.value)} 
        className="w-full px-4 py-2 mb-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
      />

      <button 
        onClick={decryptKey} 
        className="w-full bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
      >
        Decrypt
      </button>

      <p className="mt-6 text-gray-700 break-words">
        {decryptedPrivateKey || "Your decrypted key will appear here."}
      </p>
    </div>
  );
};