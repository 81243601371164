import React from 'react';
import { useNavigate } from 'react-router-dom';
import { supportCardQue } from './CardComponentContent';

const CardComponents: React.FC = () => {
    const navigate = useNavigate();

    const handleCardClick = (topic: { id: string; title: string; description: string }) => {
        navigate(`/support-${topic.id}`, { state: topic });
    };

    return (
        <div className="bg-sky-50 ">
        <div className="container mx-auto px-4 py-8">
            <h1 className="text-3xl font-bold text-center mb-8">Help by Topics</h1>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                {supportCardQue.map((topic: any,index) => (
                    <div
                        key={index}
                        className="bg-white p-6 rounded-lg shadow-lg text-center cursor-pointer"
                        onClick={() => handleCardClick(topic)}
                    >
                        <div className="mb-4">
                            <img src={topic.image} alt={topic.title} className="w-12 h-12 mx-auto" />
                        </div>
                        <h2 className="text-xl font-bold mb-2">{topic.title}</h2>
                        <p>{topic.description}</p>
                    </div>
                ))}
            </div>
        </div>
        </div>
    );
};

export default CardComponents;
