import {
  signInWithGoogle as SigninwithGmail,
  signOutUser,
} from "XonickPay-Auth";
import { addToast } from "XonickPay-Components-Lib";
import { SignUp } from "XonickPay-Services";
import { initAxios } from "../config/http-common";
import {
  getSessionStorageData,
  setSessionStorageData,
} from "./StorageDataSetGet";
import { verificationStepsComponent } from "./VerificationStepsComponent";

export const signInWithGoogle = async (
  location:any,
  navigate: any,
  setIsPopupOpen?: any,
  setTermsHtml?: any,
  setLoading?:any,
  previousUrl?: any,
) => {
  const existedtoken = getSessionStorageData("Token");
  const existeduser = getSessionStorageData("UID");
  const existedfcmtoken = getSessionStorageData("FcmToken");
  const urlParams = new URLSearchParams(window.location.search);
  const ibReferralCode = urlParams.get("ibreferral");

  let user: any, token: any, fcmtoken: any;
  if (!existedtoken) {
    ({ user, token, fcmtoken } = await SigninwithGmail());
    setSessionStorageData("UID", user!.uid);
    setSessionStorageData("Token", token);
    setSessionStorageData("FcmToken", fcmtoken);
  }
  if ((user && token) || (existedtoken && existeduser)) {
    initAxios();
    const parentIbReferralCode = ibReferralCode ? ibReferralCode : 0
    const result: any = await SignUp({
      fcmToken: existedfcmtoken || fcmtoken || "", parentIbReferralCode : parentIbReferralCode
    }).then();
    if (result.data.statuscode === 200) {
      setLoading(false);
      const { data } = result.data;

      verificationStepsComponent(
        data,
        navigate,
        user?.uid || existeduser,
        setIsPopupOpen,
        setTermsHtml,
        location,
        previousUrl
      );
    } else {
      addToast("Login Failed. Please try again.", "error");
      setTimeout(() => {
        signOutUser();
      }, 2000);
    }
  }
};
