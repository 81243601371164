import clsx from "clsx";
import React from "react";
import CheckBoxFieldComponent from "../CheckBox/CheckBoxFieldComponent";
var DataTableContent = function (_a) {
    var headers = _a.headers, currentItems = _a.currentItems, actions = _a.actions, containerClassName = _a.containerClassName, headerClassName = _a.headerClassName, bodyClassName = _a.bodyClassName, rowClassName = _a.rowClassName, id = _a.id;
    var visibleHeaders = headers.filter(function (header) { return header.visible; });
    return (React.createElement("div", { className: "my-8 flex flex-col" },
        React.createElement("div", { className: "-my-2 overflow-x-auto" },
            React.createElement("div", { className: "py-2 align-middle inline-block min-w-full" },
                React.createElement("div", { className: "shadow overflow-hidden border-b border-gray-200 sm:rounded-lg" },
                    React.createElement("table", { className: clsx(containerClassName || "min-w-full divide-y divide-gray-200"), id: id },
                        React.createElement("thead", { className: "bg-[#f1f5f9]" },
                            React.createElement("tr", null,
                                actions && (React.createElement("th", { className: "group px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider" }, "Action")),
                                visibleHeaders.map(function (header, index) { return (React.createElement("th", { key: index, scope: "col", className: clsx(headerClassName ||
                                        "group px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider") }, header.headerName)); }))),
                        React.createElement("tbody", { className: clsx(bodyClassName || "bg-white divide-y divide-gray-200") }, currentItems.length > 0 ? (currentItems.map(function (item, rowIndex) { return (React.createElement("tr", { key: rowIndex, className: "hover:bg-gray-100" },
                            actions && (React.createElement("td", { className: clsx(rowClassName || "px-6 py-4 whitespace-nowrap") }, actions(item))),
                            visibleHeaders.map(function (header, colIndex) { return (React.createElement("td", { key: colIndex, className: clsx(rowClassName || "px-6 py-4 whitespace-nowrap") }, typeof item[header.propertyName] === "boolean" ? (React.createElement(CheckBoxFieldComponent, { label: "", checked: item[header.propertyName], onChange: function () { } })) : (item[header.propertyName]))); }))); })) : (React.createElement("tr", null,
                            React.createElement("td", { colSpan: visibleHeaders.length + (actions ? 1 : 0), className: "py-4" },
                                React.createElement("h3", { className: "md:text-2xl font-semibold mb-4 text-xl" }, "No Data Found")))))))))));
};
export default DataTableContent;
