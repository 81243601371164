import React, { useEffect, useState, Suspense } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import {
  getSessionStorageData,
  setSessionStorageData,
} from "./Utils/StorageDataSetGet";
import LoaderComponent from "./Components/Loader/LoaderComponent";
import Login from "./Pages/Login/Login";
import {
  CustomToken,
  GetPermissionsByObjectId,
  SignUp,
} from "XonickPay-Services"; // Assuming these are correct imports
import { About, Faq, Home, Support, CardDetailsComponent, Work } from "xonick-pay"; // Assuming this is a correct import
import Error404Page from "./Pages/ErrorPage/Error404Page";
import Register from "./Pages/Register/Register";
import { sendEmailOtp, signInWithCustomTokenGeneric } from "XonickPay-Auth"; // Assuming this is a correct import
import { addToast } from "XonickPay-Components-Lib"; // Assuming this is a correct import
import { CONSTANTS, OPEN_ROUTES_CONSTANTS } from "./Constants/routesConstant";
import { SignalRProvider } from "./Utils/SignalRContext";
import { signOutUser } from "XonickPay-Auth"; // Assuming this is a correct import
import { initAxios } from "./config/http-common";
import { verificationStepsComponent } from "./Utils/VerificationStepsComponent";
import { useRoutingContext } from "./Context/PreviousUrlContext";

interface RouteItem {
  path: string;
  componentPath: string;
  layoutPath?: string;
  permissionId: number;
  parentId: number;
  children?: RouteItem[];
}
const ProtectedRoutes: React.FC = () => {
  const [routesConfig, setRoutesConfig] = useState<RouteItem[]>([]);
  const [loader, setLoader] = useState(true);
  const [loaderAdminRedirect, setLoaderAdminRedirect] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const userLogin = getSessionStorageData("Token");
  const userId = getSessionStorageData("UID");
  const { setLastVisitedUrl } = useRoutingContext();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const routesData = await GetPermissionsByObjectId();
        if (routesData.data && routesData.data.length > 0) {
          setRoutesConfig(routesData.data);
        } else {
          throw new Error("No route data received.");
        }
      } catch (error) {
        console.error("Error fetching role details:", error);
      } finally {
        setLoader(false);
      }
    };

    if (userLogin && userLogin !== "undefined") {
      fetchData();
    } else {
      handleLogoutAndRedirect();
    }
  }, [userLogin]);

  // Function to handle logout and redirection to login page
  const handleLogoutAndRedirect = () => {
    // Store the return URL when accessing protected routes without login
    if (
      !Object.values(OPEN_ROUTES_CONSTANTS).some(
        (route) => route === location.pathname
      )
    ) {
      // Set session storage data for ReturnUrl
      setSessionStorageData("ReturnUrl", location.pathname);

      // Perform signOutUser and navigate to LOGIN route
      signOutUser();
      navigate(OPEN_ROUTES_CONSTANTS.LOGIN); // Navigate to LOGIN route
    }
  };

  useEffect(() => {
    if (
      !Object.values(OPEN_ROUTES_CONSTANTS).some(
        (route) => route === location.pathname
      ) &&
      location.pathname !== CONSTANTS.VERIFICATION_STEPS &&
      location.pathname !== CONSTANTS.WAITING_SCREEN
    ) {
      setLastVisitedUrl(location.pathname);
    }
  }, [location.pathname, setLastVisitedUrl]);

  const urlParams = new URLSearchParams(window.location.search);
  const objectId = urlParams.get("key");
  if (loaderAdminRedirect) {
    return <LoaderComponent />;
  }

  // Handle custom token login flow
  const handleCustomTokenLogin = async (objectId: string) => {
    const payload = { objectId: objectId };
    setLoaderAdminRedirect(true)
    CustomToken(payload).then(async (result) => {
      if (result.data.statuscode === 200) {
        const { user, token, fcmtoken }: any =
          await signInWithCustomTokenGeneric(result.data.data);
        if (user && token) {
          // Cache user data in local storage
          setSessionStorageData("UID", user.uid);
          setSessionStorageData("Token", token);
          setSessionStorageData("AdminLogin", true);
          
          // Perform necessary initialization after login
          if (token !== null) {
            initAxios();
            const parentIbReferralCode = 0
            const signUpResult = await SignUp({ fcmToken: "fcmtoken", parentIbReferralCode : parentIbReferralCode });

            if (signUpResult.data.statuscode === 200) {
              addToast("Login Successfully as " + user.email, "success");
              const { data } = signUpResult.data;

              if (!data.isEmailVerified) {
                const emailVerificationSent = getSessionStorageData(
                  "EmailVerificationSent"
                );
                if (!emailVerificationSent) {
                  await sendEmailOtp();
                  setSessionStorageData("EmailVerificationSent", "true");
                  addToast("Email Not Verified, Check Inbox.", "error");
                } else {
                  addToast(
                    "Verification email has already been sent. Please check your inbox.",
                    "info"
                  );
                }
              }
              setLoaderAdminRedirect(false)
              verificationStepsComponent(data, navigate, user, null, null, location);
            } else {
              addToast("SignUp failed", "error");
              setTimeout(() => {
                signOutUser();
              }, 2000);
              setLoaderAdminRedirect(false)
            }
          }
        }
      }
    });
  };

  if (objectId) {
    handleCustomTokenLogin(objectId)
  }

  // Import the component with React.lazy
  const LazyComponent = (compPath: string) => {
    const Component = React.lazy(() =>
      import(`${compPath}`).catch(() => ({ default: <Error404Page /> }))
    );
    return (
      <Suspense fallback={<LoaderComponent />}>
        <Component />
      </Suspense>
    );
  };

  // Create function to set the Component and Layout in routes
  const generateRoutes = (routes: RouteItem[]): React.ReactElement[] => {
    return (
      routes &&
      routes.flatMap((route) => {
        const RouteComponent = () => (
          <Suspense fallback={<LoaderComponent />}>
            {LazyComponent(route.componentPath)}
          </Suspense>
        );
        const LayoutComponent = route.layoutPath
          ? () => (
              <Suspense fallback={<LoaderComponent />}>
                <SignalRProvider>
                  {LazyComponent(route.layoutPath!)}
                </SignalRProvider>
              </Suspense>
            )
          : undefined;

        const childRoutes = route.children
          ? generateRoutes(route.children)
          : null;
        const routeElement = (
          <Route
            key={route.path}
            path={route.path}
            element={<RouteComponent />}
          />
        );

        // Set child in Layout
        return [
          LayoutComponent ? (
            <Route key={`${route.path}-layout`} element={<LayoutComponent />}>
              {routeElement}
              {childRoutes}
            </Route>
          ) : (
            <React.Fragment key={`${route.path}-no-layout`}>
              {routeElement}
              {childRoutes}
            </React.Fragment>
          ),
        ];
      })
    );
  };

  // Set the API data in generateRoutes function
  const generatedRoutes = generateRoutes(routesConfig);

  // if (loader && ![CONSTANTS.LOGIN, CONSTANTS.REGISTER, "/"].includes(location.pathname)) {
  //   return <LoaderComponent />;
  // }
  if (
    loader &&
    !Object.values(OPEN_ROUTES_CONSTANTS).some(
      (route) => route === location.pathname
    )
  ) {
    // Set session storage data for ReturnUrl
    return <LoaderComponent />;
  }

  return (
    <Routes>
      <Route path={OPEN_ROUTES_CONSTANTS.HOME_PAGE} element={<Home />} />
      <Route path={OPEN_ROUTES_CONSTANTS.REGISTER} element={<Register />} />
      <Route path={OPEN_ROUTES_CONSTANTS.LOGIN} element={<Login />} />
      <Route path={OPEN_ROUTES_CONSTANTS.ABOUT_US_PAGE} element={<About />} />
      <Route path={OPEN_ROUTES_CONSTANTS.WORK} element={<Work />} />
      <Route path={OPEN_ROUTES_CONSTANTS.FAQS} element={<Faq />} />
      <Route path={OPEN_ROUTES_CONSTANTS.SUPPORT} element={<Support />} />

      <Route path={OPEN_ROUTES_CONSTANTS.SUPPORT_ID} element={<CardDetailsComponent />} />
      <Route path={OPEN_ROUTES_CONSTANTS.SUPPORT_ID_WITHDRAW} element={<CardDetailsComponent />} />
      <Route path={OPEN_ROUTES_CONSTANTS.SUPPORT_ID_ACCOUNT} element={<CardDetailsComponent />} />
      <Route path={OPEN_ROUTES_CONSTANTS.SUPPORT_ID_BANK} element={<CardDetailsComponent />} />
      <Route path={OPEN_ROUTES_CONSTANTS.SUPPORT_ID_INQUIRY} element={<CardDetailsComponent />} />
      <Route path={OPEN_ROUTES_CONSTANTS.SUPPORT_ID_SECURITY} element={<CardDetailsComponent />} />
      <Route path={OPEN_ROUTES_CONSTANTS.SUPPORT_ID_CHAT} element={<CardDetailsComponent />} />

      {generatedRoutes}
      <Route path="*" element={<Error404Page />} />
    </Routes>
  );
};

export default ProtectedRoutes;
