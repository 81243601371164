import React from "react";
import clsx from "clsx";
import { IoCopyOutline } from "react-icons/io5";
var CopyText = function (_a) {
    var copyText = _a.copyText, CopyClassName = _a.CopyClassName;
    var copy = function () {
        navigator.clipboard.writeText(copyText);
    };
    return (React.createElement(IoCopyOutline, { className: clsx("ms-2 cursor-pointer", CopyClassName === undefined || null ? "" : CopyClassName), onClick: copy }));
};
export default CopyText;
