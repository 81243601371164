var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import clsx from "clsx";
import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { MdOutlineCloudUpload } from "react-icons/md";
var DropzoneComponent = function (_a) {
    var onDrop = _a.onDrop, dropzoneClassName = _a.dropzoneClassName, dropzoneTextClassName = _a.dropzoneTextClassName, text = _a.text;
    var onDropCallback = useCallback(function (acceptedFiles) {
        onDrop(acceptedFiles);
    }, [onDrop]);
    var _b = useDropzone({
        onDrop: onDropCallback,
    }), getRootProps = _b.getRootProps, getInputProps = _b.getInputProps;
    return (React.createElement("div", { className: "flex flex-col items-center justify-center" },
        React.createElement("div", __assign({}, getRootProps(), { className: "cursor-pointer" }),
            React.createElement("input", __assign({}, getInputProps())),
            React.createElement("div", { className: clsx(dropzoneClassName === undefined || null
                    ? "border border-dashed border-gray-300 p-6 rounded-lg"
                    : dropzoneClassName) },
                React.createElement(MdOutlineCloudUpload, { className: "w-12 h-12 text-gray-400 mx-auto mb-4" }),
                React.createElement("p", { className: clsx(dropzoneTextClassName === undefined || null
                        ? "text-gray-600"
                        : dropzoneTextClassName) }, text === undefined || null
                    ? "Drag & drop files here or click to select"
                    : text)))));
};
export default DropzoneComponent;
