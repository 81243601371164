import ReactDOM from "react-dom/client";
import "./index.css";
import "./tailwind.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import React from "react";
import store from "XonickPay-Components-Lib/src/redux/store";
import { Provider } from "react-redux";
import { initFirebase } from "XonickPay-Auth";
import { firebaseConfig } from "XonickPay-Auth/firebaseconfig";
import { initAxios } from "./config/http-common";
import { RecoilRoot } from "recoil";
window.Buffer = Buffer;

initFirebase();
initFirebase().messaging
initAxios();
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <RecoilRoot>
    <App />
  </RecoilRoot>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
