import clsx from "clsx";
import React, { useEffect, useState } from "react";
var HorizontalProgressSteps = function (_a) {
    var steps = _a.steps, labelName = _a.labelName, activeBtnClassName = _a.activeBtnClassName, inActiveBtnClassName = _a.inActiveBtnClassName, progressBarClassName = _a.progressBarClassName, childBackgroundClassName = _a.childBackgroundClassName, mainClassName = _a.mainClassName, currentStepComponent = _a.currentStepComponent, initialStep = _a.initialStep;
    var _b = useState(initialStep), activeStep = _b[0], setActiveStep = _b[1];
    useEffect(function () {
        setActiveStep(initialStep);
    }, [initialStep]);
    return (React.createElement("div", { className: "flex flex-col ".concat(mainClassName) },
        React.createElement("div", { className: clsx("h-2 rounded-full flex items-center transition-all duration-300 ease-in-out ".concat(progressBarClassName || "bg-gray-300")) },
            React.createElement("div", { className: "flex justify-between w-full mx-8" }, steps.map(function (step, index) { return (React.createElement("div", { key: index, className: clsx("flex items-center justify-center rounded-full transition-all duration-300 ease-in-out", activeStep === index
                    ? activeBtnClassName || "bg-blue-600 text-white"
                    : inActiveBtnClassName || "bg-gray-100 border-gray-300 text-gray-700") },
                React.createElement("div", { className: "flex items-center justify-center h-8 w-8 border-2 rounded-full" }, activeStep > index ? (React.createElement("span", { className: "flex items-center justify-center text-sm font-bold h-8 w-8 bg-green-500 rounded-full text-white" }, "\u2713")) : (React.createElement("span", { className: "text-sm font-semibold" }, index + 1))))); }))),
        React.createElement("div", { className: "flex justify-between items-center mt-4 px-8" }, labelName.map(function (label, index) { return (React.createElement("div", { key: index, className: "text-sm font-medium text-center text-gray-600" }, label)); })),
        React.createElement("div", { className: clsx(childBackgroundClassName || "w-full p-4 mt-6 bg-white rounded-lg shadow-md") }, currentStepComponent)));
};
export default HorizontalProgressSteps;
