import { initializeApp, FirebaseApp } from "firebase/app";
import {
  getAuth,
  RecaptchaVerifier,
  PhoneAuthProvider,
  signInWithCredential,
  Auth,
  sendEmailVerification,
} from "firebase/auth";
import {
  getMessaging,
  getToken as getFirebaseMessagingToken,
  Messaging,
} from "firebase/messaging";
import { firebaseConfig } from "../firebaseconfig";

class FirebaseSingleton {
  private static instance: FirebaseSingleton | null = null;
  public readonly app: FirebaseApp;
  public readonly auth: Auth;
  private messaging?: Messaging;

  private constructor() {
    this.app = initializeApp(firebaseConfig); // Ensure Firebase App is initialized here
    this.auth = getAuth(this.app); // Initialize Auth with the Firebase App
  }

  public static getInstance(): FirebaseSingleton {
    if (!FirebaseSingleton.instance) {
      FirebaseSingleton.instance = new FirebaseSingleton();
    }
    return FirebaseSingleton.instance;
  }

  public getMessaging(): Messaging {
    if (!this.messaging) {
      this.messaging = getMessaging(this.app); // Ensure Messaging is initialized with the Firebase App
    }
    return this.messaging;
  }
}
let isEmailOtpBeingSent = false;

export const initFirebase = () => {
  const instance = FirebaseSingleton.getInstance();
  return { auth: instance.auth, messaging: instance.getMessaging() };
};

export const generateFCMToken = async () => {
  let token = "";
  const { messaging } = initFirebase();
  await Notification.requestPermission().then(async (result) => {
    if (result === "granted") {
      try {
        const registration = await navigator.serviceWorker.register(
          new URL("./firebase-messaging-sw.js", import.meta.url)
        );
        token = await getFirebaseMessagingToken(messaging, {
          serviceWorkerRegistration: registration,
        });
      } catch (error) {
        console.error("Service worker registration failed:", error);
      }
    } else if (result === "denied") {
      console.warn("Notifications permission denied");
    }
  });
  return token;
};

export const sendOtp = async (
  phoneNumber: string,
  recaptchaElement: HTMLElement | string
): Promise<{ verificationId: string | null; recaptchaSuccess: boolean }> => {
  const { auth } = initFirebase();
  try {
    const recaptchaVerifier = new RecaptchaVerifier(auth, recaptchaElement, {
      size: "invisible",
    });
    const phoneAuthProvider = new PhoneAuthProvider(auth);
    const verificationId = await phoneAuthProvider.verifyPhoneNumber(
      phoneNumber,
      recaptchaVerifier
    );
    return { verificationId, recaptchaSuccess: true };
  } catch (error) {
    console.error("Error sending OTP:", error);
    return { verificationId: null, recaptchaSuccess: false };
  }
};

export const verifyOtp = async (verificationId: string, otp: string) => {
  const { auth } = initFirebase();
  try {
    const credential = PhoneAuthProvider.credential(verificationId, otp);
    const response = await signInWithCredential(auth, credential);
    return response;
  } catch (error) {
    console.error("Error verifying OTP:", error);
    throw error; // Or handle it as per your error handling strategy
  }
};

export const resendOtp = async (
  phoneNumber: string,
  recaptchaElement: HTMLElement | string
): Promise<{ verificationId: string | null; success: boolean }> => {
  const { auth } = initFirebase(); // Get the Auth instance

  try {
    const phoneAuthProvider = new PhoneAuthProvider(auth);
    const verificationId = await phoneAuthProvider.verifyPhoneNumber(
      phoneNumber,
      new RecaptchaVerifier(auth, recaptchaElement, { size: "invisible" }) // Pass Auth instance
    );
    return { verificationId, success: true };
  } catch (error) {
    console.error("Error resending OTP:", error);
    return { verificationId: null, success: false };
  }
};

export const sendEmailOtp = async (): Promise<boolean> => {
  if (isEmailOtpBeingSent) {
    return false;
  }

  isEmailOtpBeingSent = true;

  const { auth } = initFirebase();
  if (!auth.currentUser) {
    console.error("No authenticated user found.");
    isEmailOtpBeingSent = false;
    return false; // Return false if there's no user signed in
  }

  try {
    await sendEmailVerification(auth.currentUser);
    isEmailOtpBeingSent = false;
    return true; // Return true if the email verification is successfully sent
  } catch (error) {
    console.error("Error sending email:", error);
    isEmailOtpBeingSent = false;
    return false; // Return false if there's an error sending the email verification
  }
};
