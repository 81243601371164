import React from 'react';
import InrCoin from "../../Assets/inrcoin.svg";
import TetherCoin from "../../Assets/tethercoin.svg";

// Define the keyframes for the flip animation
const styles = `
@keyframes flip {
  0% {
    transform: rotateX(0deg);
  }
  50% {
    transform: rotateX(180deg);
  }
  100% {
    transform: rotateX(360deg);
  }
}

.flip-container {
  perspective: 1000px;
}

.flip-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.flip-front, .flip-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.flip-back {
  transform: rotateX(180deg);
}

.animate-flip {
  animation: flip 1.5s infinite;
}

.bg-overlay {
  background: rgba(0, 0, 0, 0.1); /* Dark overlay for contrast */
  border-radius: 9999px; /* Makes the background circular */
}
`;

const LoaderComponent: React.FC = () => (
  <>
    <style>{styles}</style>
    <div className="flex items-center justify-center h-screen bg-white opacity-50">
      <div className="flip-container w-24 h-24 md:w-32 md:h-32 lg:w-40 lg:h-40 bg-overlay">
        <div className="flip-inner animate-flip">
          <div className="flip-front">
            <img src={TetherCoin} alt="USD Coin" className="w-18 h-18 md:w-24 md:h-24 lg:w-32 lg:h-32" />
          </div>
          <div className="flip-back">
            <img src={InrCoin} alt="INR Coin" className="w-18 h-18 md:w-24 md:h-24 lg:w-32 lg:h-32" />
          </div>
        </div>
      </div>
    </div>
  </>
);

export default LoaderComponent;
