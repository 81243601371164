import React, { useRef, useEffect } from "react";
import Chart from "chart.js/auto";
var LineChartComponent = function (_a) {
    var data = _a.data, title = _a.title;
    var chartContainer = useRef(null);
    var chartInstance = useRef();
    useEffect(function () {
        if (chartContainer.current && chartInstance.current) {
            chartInstance.current.destroy(); // Destroy previous chart instance
        }
        if (chartContainer.current) {
            chartInstance.current = new Chart(chartContainer.current, {
                type: "line",
                data: data,
                options: {
                // Chart options here
                },
            });
        }
        // Cleanup function
        return function () {
            if (chartInstance.current) {
                chartInstance.current.destroy();
            }
        };
    }, [data]);
    return (React.createElement("div", { className: "border border-gray-200 p-4 rounded-lg shadow-md" },
        React.createElement("h2", { className: "text-lg font-semibold mb-4" }, title),
        React.createElement("canvas", { ref: chartContainer })));
};
export default LineChartComponent;
