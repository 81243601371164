import React from "react";
import SupportBg from "../Assets/support_bg.svg";
import CardComponents from "../Components/SupportCardComponents/CardComponents";
import MainLayout from "../Layouts/MainLayout";

const Support = () => {
  return (
    <MainLayout>
      <div
        className="relative flex items-center w-full h-96 bg-center bg-no-repeat bg-cover"
        style={{ backgroundImage: `url(${SupportBg})` }}
      >
        <div className="container mx-auto relative z-10">
          <div className="ssm:md:w-1/2 mb-6 md:mb-0 p-4">
            <h1 className="text-3xl md:text-4xl lg:text-5xl px-5 font-bold text-white mb-4">
              Xonick Pay&nbsp;
              <span className="text-orange-400">Support</span>
            </h1>
          </div>
        </div>
      </div>
      <CardComponents />
    </MainLayout>
  );
};

export default Support;
