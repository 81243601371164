var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useState } from "react";
import clsx from "clsx";
var Toggle = function (_a) {
    var label = _a.label, onClickValue = _a.onClickValue, labelClassName = _a.labelClassName, toggleClassName = _a.toggleClassName, _b = _a.defaultChecked, defaultChecked = _b === void 0 ? false : _b, _c = _a.tooltipText, tooltipText = _c === void 0 ? "" : _c, rest = __rest(_a, ["label", "onClickValue", "labelClassName", "toggleClassName", "defaultChecked", "tooltipText"]);
    var _d = useState(defaultChecked), isChecked = _d[0], setIsChecked = _d[1];
    var _e = useState(false), showTooltip = _e[0], setShowTooltip = _e[1];
    var handleToggle = function () {
        onClickValue();
        setIsChecked(!isChecked);
    };
    return (React.createElement("div", { className: "relative" },
        showTooltip && (React.createElement("div", { className: "absolute -bottom-8 left-1/2 transform -translate-x-1/2 bg-gray-700 text-white px-2 py-1 rounded-md text-sm w-[200%]" }, tooltipText || (isChecked ? "Active" : "Inactive"))),
        React.createElement("label", { className: "inline-flex items-center cursor-pointer", onMouseEnter: function () { return setShowTooltip(true); }, onMouseLeave: function () { return setShowTooltip(false); } },
            React.createElement("span", { className: clsx(labelClassName === undefined || null
                    ? "ms-3 text-sm font-medium text-gray-900 mr-2"
                    : labelClassName) }, label),
            React.createElement("input", { type: "checkbox", value: "", className: "sr-only peer", checked: isChecked, onChange: handleToggle }),
            React.createElement("div", __assign({ className: clsx("relative w-14 h-7 bg-gray-200 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all ", toggleClassName === undefined || null
                    ? "peer-checked:bg-blue-600"
                    : toggleClassName) }, rest)))));
};
export default Toggle;
