import React from "react";
import banner from "../Assets/About-banner.svg";
import sideImage1 from "../Assets/about-mission-2.svg";
import sideImage2 from "../Assets/about-mission-1.svg";
import compliance from "../Assets/compliance.svg";
import FaqComponent from "../Components/Faq/FaqComponent";
import { Faqallpage } from "../Components/Faq/FaqConstant";
import TechCardComponent from "../Components/TechCardComponent/TechCardComponent";
import MainLayout from "../Layouts/MainLayout";

const About = () => {
  return (
    <MainLayout>
      <div className="relative h-96">
        <img
          src={banner}
          className="object-cover w-full h-full absolute top-0 left-0"
          alt="Background Image"
        />
        <div className="container mx-auto px-5 lg:py-20 relative z-10">
          <div className="ssm:md:w-2/3 mb-6 md:mb-0 px-4">
            <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold text-white mb-4">
              The Brains Behind&nbsp;
              <span className="text-orange-400">Xonick Pay</span>
            </h1>
            <p className="text-lg md:text-xl lg:text-2xl text-white w-3/5">
              Xonick Pay, powered by Xipers Solutions team, is a cutting-edge
              platform dedicated to the smooth and secure conversion of USDT to
              INR and vice versa. Based on the Tron blockchain, Xonick Pay
              ensures that every transaction is executed with the highest level
              of security and with 100% authenticity.
            </p>
          </div>
        </div>
      </div>

      <div className="relative w-full ">
        <div className="relative z-10 text-center flex flex-col items-center h-full w-full">
          <img
            src={sideImage1}
            alt="Side 1"
            className="w-1/6 3xl:h-80 h-auto hidden lg:block md:absolute md:left-0 md:top-1/2 md:transform md:-translate-y-1/2"
          />
          <div className="p-4 max-w-3xl mx-auto my-auto">
            <h1 className="text-3xl md:text-4xl font-bold mb-10">
              Our Mission and <span className="text-orange-400">Vision</span>
            </h1>
            <p className="text-lg mb-4">
              Our mission is to introduce the best INR/USDT digital currency
              exchange to the next million and billion users, opening the gates
              to the Indian Rupee market for global crypto enthusiasts.
            </p>
            <p className="text-lg mb-4">
              We envision a world where digital currency transactions are as
              straightforward and reliable as traditional banking, making them
              accessible to everyone, everywhere. Likewise, we aim to position
              India prominently on the Global Web3 map by providing easy access
              to stablecoin.
            </p>
          </div>
          <img
            src={sideImage2}
            alt="Side 2"
            className="w-1/6 3xl:h-80 h-auto hidden lg:block md:absolute md:right-0 md:top-1/2 md:transform md:-translate-y-1/2"
          />
        </div>
      </div>
      <TechCardComponent />
      <div className="container bg-white mx-auto lg:px-10 py-10">
        <div className="lg:flex lg:items-center lg:justify-center">
          <div className="lg:w-1/2 p-5 md:p-5 md:ml-36 lg:ml-0">
            <img
              src={compliance}
              alt="About Us"
              className="w-full lg:w-full md:w-96 h-auto lg:h-[35rem] rounded-lg"
            />
          </div>
          <div className="lg:w-1/2 md:py-5 px-5">
            <h1 className="text-3xl lg:text-4xl font-bold mb-10 text-center">
              Our Commitment{" "}
              <span className="text-orange-400">to Compliance</span>
            </h1>
            <p className="text-base leading-relaxed mb-4">
              At Xonick Pay, we prioritize the highest standards of security and
              regulatory compliance, providing a safe and reliable environment
              for our users. Built on the Tron blockchain, our platform is
              celebrated for its rapid transaction processing and robust
              security features.
            </p>
            <p className="text-base leading-relaxed mb-4">
              We uphold stringent security measures including KYC at
              registration, Aadhaar E-Sign for each transaction, trade
              undertaking forms, and face verification to authenticate every
              transaction. By integrating NSDL for signing and Digilocker for
              Aadhaar verification, we maintain the integrity and reliability of
              user verifications.
            </p>
            <p className="text-base leading-relaxed mb-4">
              We are proud to hold ISO 27001 certification, demonstrating our
              commitment to strict operational information security management.
              These certifications confirm our adherence to international
              security practices and financial regulations, helping to prevent
              money laundering and further strengthen trust with our users.
            </p>
            <p className="text-base leading-relaxed">
              At Xonick Pay, we are dedicated to delivering a secure and
              compliant platform for all your INR to USDT transactions, offering
              you confidence and peace of mind with every trade.
            </p>
          </div>
        </div>
      </div>
      <FaqComponent faqItems={Faqallpage} />
    </MainLayout>
  );
};

export default About;
