import React from 'react';
import BlockChainBg from "../../Assets/block_chain_bg.svg"
import UserImage from "../../Assets/aadhar-register-icon.svg";

const features = [
  {
    title: "Scalability",
    subTitle: "Over 2,000 Transaction Per Second (TPS)",
    description1: "Supports high-demand applications with rapid transaction processing.",
    description2: "This capacity is crucial for ensuring network congestion does not hinder user experiences or transaction times.",
    image: UserImage,
  },
  {
    title: "Low Fees",
    subTitle: "Minimal Transaction Costs",
    description1: "It attracts content creators, traders and users with its affordable fees when compared to other digital platforms.",
    description2: "Trade in P2P mode as many times as you want without worrying about the gas fees.",
    image: UserImage,
    },
  {
    title: "Decentralized Governance",
    subTitle: "Delegated Proof-of-Stake (DPoS)",
    description1: 'Governed by 27 elected "super representatives" for community-driven decisions.',
    description2: "DPoS employs a voting and delegation process to validate transactions, which can be more cost-effective, efficient, and equitable than other mechanisms.",
    image: UserImage,
    },
  {
    title: "Native Token - TRX",
    subTitle: "Powering the Network",
    description1: "Used for payments, staking, and powering decentralized applications.",
    description2: "Trading of TRX coins occurs 24/7, allowing global buyers and sellers to trade at any time.",
    image: UserImage,
    }
];

const BlockChainComponent: React.FC = () => {
  return (
    <div className="flex flex-col items-center text-white py-10 bg-center bg-no-repeat bg-cover">
      <h1 className="text-3xl text-center text-black font-bold mb-8">We Choose <span className="text-orange-500">Tron Blockchain</span></h1>
      <div className="grid grid-cols-1 md:grid-cols-2 md:gap-4 lg:gap-10 max-w-4xl m-2">
        {features.map((feature, index) => (
          <div key={index} className="bg-white text-black p-6 rounded-lg shadow-lg my-2">
            <div className='flex'>
            <img src={feature.image} alt="User Image" className='w-7 mr-2 mb-3'/>   
            <h2 className="text-xl font-bold text-orange-500 mb-2">{feature.title}</h2>
            </div>
            <h3 className='font-semibold'>{feature.subTitle}</h3>
            <p>{feature.description1}</p>
            <p>{feature.description2}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BlockChainComponent;
