import React from "react";
import clsx from "clsx";
var SubCardComponent = function (_a) {
    var profileImage = _a.profileImage, Title = _a.Title, subTitle1 = _a.subTitle1, col2Title = _a.col2Title, col3Title = _a.col3Title, data = _a.data, cardClassName = _a.cardClassName, label = _a.label, items = _a.items, children = _a.children, // Converter component here
    labelClass = _a.labelClass, labelTextClassName = _a.labelTextClassName;
    return (React.createElement("div", { className: clsx("border p-6 rounded-lg mb-4 shadow-lg hover:shadow-xl transition duration-300", cardClassName ? cardClassName : "bg-white border-gray-200") },
        React.createElement("div", { className: "flex flex-col md:flex-row items-center justify-center md:justify-between space-y-6 md:space-y-0 md:space-x-8" },
            React.createElement("div", { className: "flex items-center space-x-4" },
                React.createElement("img", { className: "w-16 h-16 rounded-full shadow-lg", src: profileImage, alt: "Profile" }),
                React.createElement("div", { className: "text-left" },
                    React.createElement("h3", { className: "text-2xl font-semibold text-gray-900" }, Title),
                    React.createElement("p", { className: "text-gray-600 text-sm" }, subTitle1))),
            React.createElement("div", { className: "flex-1 text-center" },
                React.createElement("p", { className: "text-primary-700 font-semibold text-lg" }, col2Title)),
            React.createElement("div", { className: "flex-1 text-center" },
                React.createElement("p", { className: "text-gray-800 font-semibold text-lg" }, col3Title))),
        React.createElement("div", { className: "mt-6 mb-6" },
            React.createElement("div", { className: "grid grid-cols-1 md:grid-cols-4 gap-4" }, data.map(function (_a, index) {
                var label = _a.label, value = _a.value;
                return (React.createElement("div", { className: "bg-white p-4 rounded-lg shadow-sm border border-gray-200", key: index },
                    React.createElement("p", { className: clsx(labelClass, "text-gray-700 font-medium") }, label),
                    React.createElement("p", { className: clsx(labelTextClassName, "text-gray-900") }, value)));
            }))),
        React.createElement("div", { className: "flex flex-col md:flex-row space-y-6 md:space-y-0 md:space-x-8" },
            React.createElement("div", { className: "w-full md:w-1/2" },
                React.createElement("div", { className: "mb-3" }, children)),
            React.createElement("div", { className: "w-full md:w-1/2" },
                React.createElement("p", { className: clsx(labelClass, "text-gray-700 font-medium mb-2") }, label),
                React.createElement("div", { className: clsx("h-56 overflow-y-auto text-sm p-4 bg-white rounded-lg border text-gray-600 border-gray-300 shadow-md"), dangerouslySetInnerHTML: { __html: items || "" } })))));
};
export default SubCardComponent;
