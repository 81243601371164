import React, { useState } from "react";

const FaqComponent = ({ faqItems }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index: React.SetStateAction<null>) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <div className="flex justify-center items-center bg-sky-50">
      <div className="max-w-4xl w-full py-8 lg:px-4">
        <div className="space-y-4">
          {faqItems.map((faq: any, index: any) => (
            <div
              key={index}
              className="bg-white rounded-lg shadow-md overflow-hidden"
            >
              <div
                className="question px-4 py-3 flex justify-between items-center cursor-pointer"
                onClick={() => toggleAccordion(index)}
              >
                <span className="text-md font-semibold p-2">
                  {faq.question}
                </span>
                <svg
                  className={`w-6 h-6 transition-transform ${
                    activeIndex === index ? "transform rotate-180" : ""
                  }`}
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </div>
              {activeIndex === index && (
                <div className="answercont px-4 py-3">
                  <p className="text-gray-700">{faq.answer}</p>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FaqComponent;
