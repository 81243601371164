import React, { useRef, useState, MouseEvent } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { supportCardQue, supportCardQueAns } from '../Components/SupportCardComponents/CardComponentContent';
import SupportBg from "../Assets/support_bg.svg"
import Header from "../Components/Header/HeaderComponent";

const CardDetailsComponents: React.FC = () => {
    const location = useLocation();
    const topic = location.state as {
        id: any; title: string; description: string 
    };
    const navigate = useNavigate();
    const id = topic.id

    const handleCardClick = (topic: { id: string; title: string; description: string }) => {
        navigate(`/support-${topic.id}`, { state: topic });
    };

    const handleScroll = (event) => {
        const container = event.target;
        const scrollAmount = event.deltaY;
        container.scrollTo({
            top: 0,
            left: container.scrollLeft + scrollAmount,
            behavior: "smooth",
        });
    };

    const scrollContainerRef = useRef<HTMLDivElement>(null);
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);

    const handleMouseDown = (event: MouseEvent<HTMLDivElement>) => {
        const container = scrollContainerRef.current;
        if (!container) return;

        setIsDragging(true);
        setStartX(event.pageX - container.offsetLeft);
        setScrollLeft(container.scrollLeft);
    };

    const handleMouseLeaveOrUp = () => {
        setIsDragging(false);
    };

    const handleMouseMove = (event: MouseEvent<HTMLDivElement>) => {
        if (!isDragging) return;
        event.preventDefault();

        const container = scrollContainerRef.current;
        if (!container) return;

        const x = event.pageX - container.offsetLeft;
        const walk = (x - startX) * 2; // The multiplier for faster scrolling
        container.scrollLeft = scrollLeft - walk;
    };

    return (
        <div>
            <Header />
            <div className="relative flex items-center w-full h-96 bg-center bg-no-repeat bg-cover" style={{ backgroundImage: `url(${SupportBg})` }}>
                <div className="container mx-auto relative z-10">
                    <div className="ssm:md:w-1/2 mb-6 md:mb-0 p-4">
                        <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold text-white mb-4">
                            Xonick Pay&nbsp;
                            <span className="text-orange-400">Support</span>
                        </h1>
                    </div>
                </div>
            </div>

            <div className="container mx-auto px-4 py-8">
                <div className="md:flex md:h-screen">
                    <div className="md:w-1/4 p-6 h-full flex md:block overflow-y-scroll hide-scrollbar"
                        onWheel={handleScroll}
                        ref={scrollContainerRef}
                        onMouseDown={handleMouseDown}
                        onMouseLeave={handleMouseLeaveOrUp}
                        onMouseUp={handleMouseLeaveOrUp}
                        onMouseMove={handleMouseMove}
                        style={{ cursor: isDragging ? "grabbing" : "grab" }}>
                        {supportCardQue.map((topic: any) => (
                            <div
                                key={topic.id}
                                className={`${topic.id === id ? "bg-gray-200" : "bg-white"} p-4 md:p-6 mr-4 md:mr-0 rounded-md flex hover:bg-gray-100 border border-[#ff9247] shadow-lg items-center text-left cursor-pointer mt-2 mb-4`}
                                onClick={() => handleCardClick(topic)}
                            >
                                <div className="hidden lg:block lg:w-1/4 lg:mr-4">
                                    <img src={topic.image} alt={topic.title} className="w-10 h-10 lg:w-12 lg:h-12" />
                                </div>
                                <div className="md:w-3/4">
                                    <h2 className="text-sm md:text-lg lg:text-xl font-semibold mb-2">{topic.title}</h2>
                                    {/* <p className="hidden lg:block text-sm">{topic.description}</p> */}
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="md:w-3/4 w-full md:p-6 p-1 md:ml-4 h-full overflow-y-scroll hide-scrollbar">
                        <div className="bg-white md:p-6 rounded-lg md:border border-[#ff9247]">
                            {supportCardQueAns.map((ans,index) => (
                                ans.id === id ?
                                    <div key={index}>
                                        {/* <h2 className="text-xl font-bold mb-2">{ans.title}</h2> */}
                                        <div className='md:mx-6'>
                                            {ans.description}
                                        </div>
                                    </div> :
                                    ""
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CardDetailsComponents;
