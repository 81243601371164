import React from "react";
import FaqComponent from "../Components/Faq/FaqComponent";
import { faqs } from "../Components/Faq/FaqConstant";
import MainLayout from "../Layouts/MainLayout";
import banner from "../Assets/FAQs.svg";

const Faq = () => {
  return (
    <MainLayout>
      <div
        className="relative flex items-center w-full h-96 bg-center bg-no-repeat bg-cover"
        style={{ backgroundImage: `url(${banner})` }}
      >
        <div className="container mx-auto relative z-10">
          <div className="ssm:md:w-1/2 mb-6 md:mb-0 p-4">
            <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold px-5 text-white mb-4">
              FAQs&nbsp;
            </h1>
          </div>
        </div>
      </div>
      <FaqComponent faqItems={faqs} />
    </MainLayout>
  );
};

export default Faq;
