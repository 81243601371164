import React from "react";
import videoRegister from "../../Assets/video-register-icon.svg";
import aadhaarRegister from "../../Assets/aadhar-register-icon.svg";
import bankRegister from "../../Assets/bank-register-icon.svg";
import panRegister from "../../Assets/pan-Register-Icon.svg";
import BlockChainBg from "../../Assets/new-bg-custom.svg";

const steps = [
  { imgSrc: aadhaarRegister, altText: "Aadhaar Register", key: "step1" },
  { imgSrc: panRegister, altText: "Pan Register", key: "step2" },
  { imgSrc: bankRegister, altText: "Bank Register", key: "step3" },
  { imgSrc: videoRegister, altText: "Video Register", key: "step4" },
];

const RegisterFlowComponent: React.FC<any> = ({ data = {} }) => {
  return (
    <div
    className="p-4 md:p-6 lg:p-8 xl:p-10 h-auto bg-fixed bg-no-repeat bg-cover"
          style={{ backgroundImage: `url(${BlockChainBg})` }}
    >
      <h1 className="text-2xl text-white md:text-3xl lg:text-4xl 2xl:text-5xl font-bold text-center mt-4 mb-8">
        {data.title1} <span className="text-orange-400">{data.title2}</span>
      </h1>

      <div className="relative pt-8 pb-16">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 p-4 lg:p-8">
          {steps.map((step, index) => (
            <div
              key={index}
              className="bg-white bg-opacity-20 text-white rounded-lg shadow-lg hover:bg-opacity-30 transition duration-300 ease-in-out flex flex-col justify-center items-center p-6 transform hover:scale-105"
              style={{ transitionDelay: `${index * 200}ms` }}
            >
              <img
                src={step.imgSrc}
                className="w-20 md:w-24 lg:w-28 xl:w-32 mb-4 animate-pulse"
                alt={step.altText}
              />
              <div className="text-center font-semibold text-lg md:text-xl lg:text-2xl">
                {data[step.key]}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default RegisterFlowComponent;
