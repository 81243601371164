import React from "react";
import imageSrc from "../Assets/right-image.svg";
import "../PagesCss/home.css";
import ConverterHome from "../Components/Converter/ConverterHome";
import StaticCard from "../Components/Cards/StaticCard";
import ShadowCards from "../Components/Cards/ShadowCards";
import RegisterFlowComponent from "../Components/RegisterComponent/RegisterFlowComponent";
import Testimonial from "../Components/Testimonial/TestimonialComponent";
import FaqComponent from "../Components/Faq/FaqComponent";
import { Faqallpage } from "../Components/Faq/FaqConstant";
import BannerComponent from "../Components/BannerComponent/BannerComponent";
import BlockChainComponent from "../Components/BlockChainComponent/BlockChainComponent";
import { RegistrationHome } from "../Components/RegisterComponent/RegisterFLowConstant";
import AnimatedScrollDiv from "../Components/AnimatedScroll/AnimatedScrollDiv";
import MainLayout from "../Layouts/MainLayout";
import Flipcard from "../Components/Cards/FlipcardComponent ";

const Home = () => {
  const animatedComponents = [
    { component: <BannerComponent />, animation: "animate-slideLeft" },
    {
      component: (
        <div className="relative bg-white">
          <img
            className="image absolute top-6 lg:top-28 2xl:top-16 md:right-[19rem] lg:right-8 sm:right-52 ssm:right-36 xsm:right-28 right-16 lg:h-72 xl:h-80 2xl:h-96 md:h-52 xsm:h-40 h-32 4xl:h-80"
            src={imageSrc}
            alt="Card image"
          />
          <div className="flex lg:flex-col flex-row items-start justify-center py-12 px-4 lg:py-24 lg:px-8">
            <div className="mt-44 xsm:mt-52 md:mt-60 lg:mt-20 mb-20 lg:ml-8 md:w-full lg:w-[43rem] xl:w-[57rem] 2xl:w-[70rem] 3xl:w-[86rem] 4xl:w-[110rem]">
              <h2 className="font-bold text-2xl md:text-3xl lg:text-4xl 2xl:text-5xl text-gray-900">
                Looking for a Safe and Easy Way to Trade&nbsp;
                <span className="text-orange-500">USDT for INR?</span>
              </h2>
              <p className="lg:text-xl 2xl:text-2xl md:text-lg text-gray-700 font-medium mt-3 leading-relaxed">
                Welcome to Xonick Pay, your trusted portal for trading USDT to
                INR and vice versa. Convert your USDT/INR from this platform
                here. Experience the best security standards with Xonick Pay.
              </p>
              <p className="mt-2 text-gray-700 leading-relaxed">
                Xonick Pay, built and running on the Tron blockchain, operates
                with TRX as our native currency, designed to provide a seamless
                trading experience.
              </p>
              <p className="mt-2 text-gray-700 leading-relaxed">
                Using Tron's exceptionally well-known high-speed transaction
                capabilities and low fees ensures that each trade is processed
                quickly and securely. The smart contracts on the Tron network
                automate and verify transactions, further increasing the speed,
                authenticity, and minimizing the risk of errors or fraud.
              </p>
            </div>
          </div>
        </div>
      ),
      animation: "animate-slideRight",
    },
    { component: <ConverterHome />, animation: "animate-slideLeft" },
    { component: <Flipcard />, animation: "animate-slideRight" },
    { component: <ShadowCards />, animation: "animate-slideLeft" },
    { component: <BlockChainComponent />, animation: "animate-slideRight" },
    { component: <StaticCard />, animation: "animate-slideLeft" },
    { component: <Testimonial />, animation: "animate-slideRight" },
    {
      component: <RegisterFlowComponent data={RegistrationHome} />,
      animation: "animate-slideLeft",
    },
    {
      component: <FaqComponent faqItems={Faqallpage} />,
      animation: "animate-slideRight",
    },
  ];

  return (
    <MainLayout>
      <div>
        {animatedComponents.map((item, index) => (
          <AnimatedScrollDiv
            key={index}
            animationClass={`${item.animation} duration-10000`}
          >
            {item.component}
          </AnimatedScrollDiv>
        ))}
      </div>
    </MainLayout>
  );
};

export default Home;
