import clsx from "clsx";
import React from "react";
var TabComponent = function (_a) {
    var label = _a.label, isActive = _a.isActive, onClick = _a.onClick, activeClassName = _a.activeClassName, inActiveClassName = _a.inActiveClassName;
    return (React.createElement("div", { className: "p-2 cursor-pointer border ".concat(isActive
            ? clsx(activeClassName === undefined
                ? "bg-blue-500 text-white"
                : activeClassName)
            : clsx(inActiveClassName === undefined
                ? "bg-gray-200 text-black"
                : inActiveClassName)), onClick: onClick }, label));
};
export default TabComponent;
