var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import Select from "react-select";
import clsx from "clsx";
var DropdownComponent = function (_a) {
    var name = _a.name, options = _a.options, isMulti = _a.isMulti, selectedOption = _a.selectedOption, onOptionChange = _a.onOptionChange, dropdownClassName = _a.dropdownClassName, labelName = _a.labelName, labelClassName = _a.labelClassName, placeholder = _a.placeholder, rest = __rest(_a, ["name", "options", "isMulti", "selectedOption", "onOptionChange", "dropdownClassName", "labelName", "labelClassName", "placeholder"]);
    var handleSelectChange = function (selectedOption) {
        onOptionChange(selectedOption);
    };
    return (React.createElement("div", null,
        React.createElement("label", { className: clsx(labelClassName === undefined || null
                ? "block text-sm font-bold text-tint-600"
                : labelClassName) }, labelName),
        React.createElement(Select, __assign({ name: name, options: options, isMulti: isMulti, value: selectedOption, onChange: handleSelectChange, isSearchable: false, placeholder: placeholder === undefined || null ? "Select Value..." : placeholder, className: dropdownClassName }, rest))));
};
export default DropdownComponent;
