import React from "react";
import "../../App.css";
import "../../PagesCss/home.css";
import video from "../../Assets/bannerVideo.mp4";
import { LinkComponent } from "XonickPay-Components-Lib";

const BannerComponent = () => {
  return (
    <>
      <div className="relative min-h-svh overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <video
            src={video}
            className="w-full h-full object-center object-cover"
            autoPlay
            muted
            loop
          />
        </div>
        <div className="absolute inset-0 bg-black bg-opacity-50" />
        <div className="absolute inset-0 flex items-center justify-center p-4">
          <div className=" bg-opacity-90 p-5 rounded-lg shadow-lg max-w-2xl mx-4 text-center">
            <h1 className="text-white text-3xl sm:text-3xl md:text-4xl lg:text-5xl font-bold leading-snug mb-4">
              India's Premier{" "}
              <span className="text-orange-500">USDT to INR</span>
            </h1>

            <p className="text-white text-base sm:text-lg md:text-xl lg:text-2xl mb-6">
              Experience seamless trading with our platform tailored for USDT to
              INR transactions. Designed for both experienced traders and
              newcomers, we offer all the essential tools for confident and
              secure trading.
            </p>
            <LinkComponent
              to="/register"
              className="inline-block mt-6 px-6 py-3 bg-gradient-to-r from-orange-500 to-amber-500 text-white text-lg font-semibold rounded-lg shadow-lg hover:bg-orange-600 transition duration-300"
            >
              Get Started
            </LinkComponent>
          </div>
        </div>
      </div>
    </>
  );
};

export default BannerComponent;
