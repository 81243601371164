// components/DoughnutChart.tsx
import React from "react";
import { Doughnut } from "react-chartjs-2";
var DoughnutChart = function (_a) {
    var data = _a.data, title = _a.title;
    return (React.createElement("div", { className: "bg-white p-4 rounded-lg border border-gray-200 shadow-lg" },
        React.createElement("h2", { className: "text-lg font-semibold mb-4" }, title),
        React.createElement(Doughnut, { data: data })));
};
export default DoughnutChart;
