var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import clsx from "clsx";
var FloatingLabelComponent = function (_a) {
    var label = _a.label, labelClassName = _a.labelClassName, inputClassName = _a.inputClassName, placeholder = _a.placeholder, rest = __rest(_a, ["label", "labelClassName", "inputClassName", "placeholder"]);
    return (React.createElement("label", { className: "relative block rounded-md border border-gray-200 shadow-sm focus-within:border-blue-500 focus-within:ring-1 focus-within:ring-blue-500 appearance-none" },
        React.createElement("input", __assign({ className: clsx("peer border-none bg-transparent placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0 p-3 w-full place", inputClassName === undefined || null ? "" : inputClassName), placeholder: placeholder }, rest)),
        React.createElement("span", { className: clsx("pointer-events-none absolute start-2.5 px-2 text-gray-700 top-0 -translate-y-1/2 transition-all peer-placeholder-shown:top-1/2  peer-focus:top-0  peer-focus:text-sm peer-focus:font-semibold bg-white", labelClassName === undefined || null ? "" : labelClassName) }, label)));
};
export default FloatingLabelComponent;
