import { initFirebase } from "XonickPay-Auth";
import { addToast } from "XonickPay-Components-Lib";
import {
  getAxiosInst,
  responseInterceptor,
  setUpAxios,
} from "XonickPay-Services";
import {
  AxiosError,
  AxiosResponse,
} from "XonickPay-Services/dist/Utils/AxiosInstance";
import {
  setSessionStorageData,
  getSessionStorageData,
} from "../Utils/StorageDataSetGet";
import { signOutUser } from "XonickPay-Auth";

let globalCancelTokenSource: any | null = null;
let retryCount: number = 0; // Initialize retry count outside the function
const { auth, messaging } = initFirebase();
const initAxios = () => {
  if (globalCancelTokenSource) {
    return;
  }

  const baseURL = process.env.REACT_APP_BASE_URL;
  if (!baseURL) {
    throw new Error(
      "REACT_APP_BASE_URL is not defined in the environment variables."
    );
  }

  const axiosInstance = setUpAxios({
    baseURL: baseURL,
    token: getSessionStorageData("Token")!,
  });

  globalCancelTokenSource = axiosInstance.defaults.cancelToken;
};

getAxiosInst.interceptors.response.use(
  (response): AxiosResponse => {
    if (response.config.url === "rolepermissions/getpermissionbyobject") {
      globalCancelTokenSource = null;
      return response;
    }

    if (response.data && response.data.statuscode === 200) {
      //  addToast(response.data.message, response.data.status);
    } else if (response.data.statuscode === 500) {
      addToast(response.data.message, response.data.status);
    } else if (response.data.statuscode === 400) {
      addToast(response.data.message, response.data.status);
    } else if (response.data.statuscode === 401) {
      addToast("Please Login again", "error");
    }
    globalCancelTokenSource = null;

    return response;
  },
  (error) => {
    globalCancelTokenSource = null;
    return Promise.reject(error);
  }
);

const errorInterceptor = async (error: AxiosError): Promise<AxiosResponse> => {
  if (error.response?.status === 401) {
    // Unauthorized error, try to refresh token and retry request
    try {
      return await retryRequest(error);
    } catch (refreshError) {
      return Promise.reject(refreshError);
    }
  }

  if (error.response?.status === 403 && error.config!.method === "options") {
    console.error("Preflight request canceled:", error.message);
  }

  console.error("Axios error:", error);
  return Promise.reject(error);
};

const retryRequest = async (error: any): Promise<any> => {
  if (retryCount >= 3) {
    signOutUser();
    return Promise.reject(new Error("Maximum retry attempts reached"));
  }
  retryCount++;
  try {
    const newToken = await refreshToken(); // Refresh the token
    if (
      newToken !== null ||
      undefined ||
      "invalid_token" ||
      "Refresh token not implemented"
    ) {
      error.config!.headers.Authorization = `Bearer ${newToken}`;
      setSessionStorageData("Token", newToken!);
      setSessionStorageData("UID", auth.currentUser?.uid!);
      return getAxiosInst.request(error.config); // Retry the original request with new token
    }
    throw new Error("Invalid token or token refresh not implemented");
  } catch (refreshError) {
    if (retryCount >= 3) {
      signOutUser();
    }
    return Promise.reject(refreshError);
  }
};

getAxiosInst.interceptors.response.use(responseInterceptor, errorInterceptor);
const refreshToken = async () => {
  const userRecord = auth;
  return userRecord.currentUser?.getIdToken().then((result) => {
    return result;
  });
};

export { initAxios };
