export const faqs = [
  {
    question: "Where can I find the USDT/INR price chart?",
    answer: `The USDT/INR price chart is available on the Xonick Pay platform, providing real-time updates on the conversion rates.`,
  },
  {
    question: "What security features does Xonick Pay offer?",
    answer: `Xonick Pay offers multiple security features, including KYC upon registration, Aadhaar E-Sign on each trade, a trade undertaking form, and face verification for all transactions.`,
  },
  {
    question: "How do I register on Xonick Pay?",
    answer: `To register, visit the Xonick Pay website or app, provide your details for KYC verification, and follow the registration steps to set up your Xonick Pay wallet.`,
  },
  {
    question: "What certifications does Xonick Pay hold?",
    answer: `Xonick Pay holds ISO 27001 certification ensuring compliance and integrity for all transactions executed on the platform.`,
  },
  {
    question:
      "How much TRX does the user need to deposit /hold to Start Trading?",
    answer: `The user needs to hold a balance of at least 20 TRX in their wallet in case they want to move money from their Xonick Pay wallet to any external wallet. `,
  },
  {
    question:
      " How much time does it take for my Xonick Pay profile to be verified?",
    answer: `Xonick Pay team takes a maximum of 24 hours to completely verify your profile once you have submitted your aadhaar details, pan details and video verification. `,
  },
  {
    question:
      "How do I report suspicious activity or a potential security breach in my account?",
    answer: `If you suspect any suspicious activity or a potential security breach related to your Xonick Pay account, immediately report it to our support team via the inquiry form on our website or directly through the app. Provide as much detail as possible about what you observed. Our security team will investigate the issue promptly and take necessary actions to secure your account and mitigate any risks.`,
  },

  {
    question: " What types of customer support does Xonick Pay offer?",
    answer: `Xonick Pay offers several customer support options to assist you. You can reach us through live chat and email support. Additionally, our comprehensive FAQ section and help center contain answers to common questions and tutorials on how to use the platform. We strive to ensure that you receive timely and helpful support whenever you need it.`,
  },
  {
    question: "Can I access Xonick Pay from multiple devices?",
    answer: `Yes, you can access your Xonick Pay account from multiple devices. However, for security reasons, we monitor account access patterns and may require additional verification if an unusual access pattern is detected. We recommend setting up two-factor authentication (2FA) to add an extra layer of security when accessing your account from new devices.`,
  },
  {
    question: "Can I cancel a transaction on Xonick Pay? ",
    answer: `Yes, you can cancel a transaction on Xonick Pay under certain conditions. If the transaction has not yet been completed or the funds have not been transferred, you can initiate a cancellation from your transaction history. Please note that some transactions may incur cancellation fees depending on the timing and status of the order.`,
  },
  {
    question: "What should I do if I forget my account password?",
    answer: `If you forget your password, you can reset it by clicking on the 'Forgot Password' link on the login page. You will need to enter the email address associated with your account, and we will send you instructions on how to reset your password securely.`,
  },
  {
    question:
      "Are there any limits on how much USDT I can buy or sell at a time?",
    answer: `Xonick Pay sets transaction limits based on your account level, which is determined by your verification status. Higher verification levels, which include providing additional personal information and documents, will allow for higher transaction limits. Please refer to our Account Limits section on the platform for detailed information.`,
  },
  {
    question: "How can I increase my transaction limit on Xonick Pay?",
    answer: `To increase your transaction limit, you need to upgrade your account level by completing additional verification steps. This may include submitting further documentation and undergoing enhanced identity verification. Details and instructions for upgrading your account level are available in your account settings under 'Account Limits.'`,
  },
  {
    question: "What happens if there is a dispute during a transaction?",
    answer: `If a dispute arises during a transaction, Xonick Pay offers a dispute resolution process to help resolve conflicts between parties. Users can initiate a dispute through the transaction page, and our support team will intervene to mediate and help find a resolution based on the evidence provided by both parties and our trading rules.`,
  },
];

export const Faqallpage = [
    {
        question: "How to Buy USDT? ",
        answer: `To purchase USDT, you must create an Xonick Pay account first. After depositing funds, use their trading interface to buy USDT securely and quickly.`,
      },
      {
        question: "How to Buy Tether (USDT) in India?",
        answer: `Users can buy Tether stablecoin (USDT) using INR on Xonick Pay at the best price. Select the USDT/INR trading pair and complete your trade using their streamlined transaction process.`,
      },
      {
        question: "How Can I buy USDT with INR?",
        answer: `Create an account at Xonick Pay. Login and deposit funds into your xonick pay wallet. You can now buy USDT at your terms.`,
      },
      {
        question: "What is USDT?",
        answer: `USDT, also known as Tether, is a cryptocurrency stablecoin that is pegged to the US dollar. It is designed to offer minimal volatility and is globally used for transactions and savings on Xonick Pay.`,
      },
      {
        question:"How do I trade USDT to INR using Binance and Xonick Pay?",
        answer: `First, transfer your TRX from Binance or any other wallet to your Xonick Pay wallet. Then, use Xonick Pay to convert USDT to INR and withdraw it to your bank account.`,
      },
];
