import { clsx } from "clsx";
import React from "react";
import ButtonComponent from "../Button/ButtonComponent";
var PopupComponent = function (_a) {
    var isOpen = _a.isOpen, children = _a.children, acceptText = _a.acceptText, onDecline = _a.onDecline, onAccept = _a.onAccept, onClose = _a.onClose, declineClassName = _a.declineClassName, declineText = _a.declineText, acceptClassName = _a.acceptClassName, popupClassName = _a.popupClassName, childrenClassName = _a.childrenClassName, _b = _a.showCloseButton, showCloseButton = _b === void 0 ? true : _b, _c = _a.showBackButton, showBackButton = _c === void 0 ? false : _c, // Default value for showBackButton
    onBackClick = _a.onBackClick;
    if (!isOpen) {
        return null;
    }
    return (React.createElement("div", { className: "fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden bg-black bg-opacity-40" },
        React.createElement("div", { className: clsx("relative p-4 w-full max-w-2xl max-h-full rounded-lg shadow bg-white", (popupClassName !== undefined || null) && popupClassName) },
            React.createElement("div", { className: "flex items-center justify-between p-2 rounded-t" },
                showBackButton && (React.createElement("button", { className: "text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-3xl w-8 h-8 inline-flex justify-center items-center", onClick: onBackClick }, "\u2190")),
                showCloseButton && (React.createElement("button", { className: "text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-3xl w-8 h-8 ms-auto inline-flex justify-center items-center", onClick: onClose }, "\u00D7"))),
            React.createElement("div", { className: clsx("px-4 overflow-y-auto", childrenClassName === undefined || null
                    ? "max-h-[400px]"
                    : childrenClassName) }, children),
            React.createElement("div", { className: "flex items-center p-4 md:p-5 rounded-b justify-center" },
                React.createElement(ButtonComponent, { btnClassName: clsx("focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center", acceptClassName === undefined || null
                        ? "bg-blue-700 hover:bg-blue-800 text-white focus:ring-blue-300"
                        : acceptClassName), onClick: onAccept }, acceptText),
                React.createElement(ButtonComponent, { btnClassName: clsx("py-2.5 px-5 ms-3 text-sm font-medium focus:outline-none rounded-lg border border-gray-200 focus:ring-4", declineClassName === undefined || null
                        ? "bg-white text-gray-900 hidden hover:text-blue-700 focus:ring-gray-100"
                        : declineClassName), onClick: onDecline }, declineText)))));
};
export default PopupComponent;
