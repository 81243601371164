import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signOut,
  getAuth,
  signInWithEmailAndPassword,
  updatePassword,
  sendPasswordResetEmail,
  GoogleAuthProvider,
  signInWithPopup,
  checkActionCode,
  applyActionCode,
  signInWithCustomToken,
  User,
} from "firebase/auth";
import { generateFCMToken, initFirebase } from "./fcmTokenGeneration";
const { auth } = initFirebase();

class FirebaseSingleton {
  private static instance: FirebaseSingleton | null = null;

  public static getInstance(): FirebaseSingleton {
    if (!FirebaseSingleton.instance) {
      FirebaseSingleton.instance = new FirebaseSingleton();
    }
    return FirebaseSingleton.instance;
  }

  public async userRefreshToken(): Promise<string | undefined> {
    const user: User | null = auth.currentUser;
    if (user) {
      try {
        const idToken = await user.getIdToken(true);
        const refreshToken = idToken;
        return refreshToken;
      } catch (error) {
        console.error("Error refreshing token:", error);
        return undefined;
      }
    } else {
      console.error("No user signed in.");
      return undefined;
    }
  }
}

//  Generate refresh token
export const refreshFirebaseToken = async () => {
  const instance = FirebaseSingleton.getInstance();
  return await instance.userRefreshToken();
};

// User registration function
export const registerUser = async (email: string, password: string) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );
    const user = userCredential.user;
    const fcmtoken = await generateFCMToken();

    // Send email verification
    await sendEmailVerification(user);

    // Get the token (you may want to return it or handle it differently based on your needs)
    const token = await user.getIdToken();

    return { user, token, fcmtoken };
  } catch (error) {
    console.error("Error registering the user: ", error);
    throw error;
  }
};

export const loginUser = async (email: string, password: string) => {
  const response = await signInWithEmailAndPassword(auth, email, password);
  const user = response.user;
  const fcmtoken = await generateFCMToken();

  const token = await user.getIdToken();
  return { user, token, fcmtoken };
};
export const signOutUser = async (): Promise<string> => {
  try {
    await signOut(auth);
    localStorage.clear();
    sessionStorage.clear();
    window.location.reload();
    return "success";
  } catch (error) {
    console.error("Error signing out:", error);
    return "failure";
  }
};

export const changePassword = async (newPassword: string): Promise<string> => {
  const auth = getAuth();
  const user = auth.currentUser;

  if (user) {
    try {
      await updatePassword(user, newPassword);
      return "Password updated successfully";
    } catch (error) {
      console.error("Error updating password:", error);
      return "Failed to update password";
    }
  } else {
    return "No user signed in";
  }
};

export const resetPassword = async (email: string): Promise<string> => {
  const auth = getAuth();

  try {
    await sendPasswordResetEmail(auth, email);
    return "Password reset email sent successfully";
  } catch (error) {
    console.error("Error sending password reset email:", error);
    return "Failed to send password reset email";
  }
};

export const signInWithGoogle = async () => {
  const auth = getAuth();

  try {
    const provider = new GoogleAuthProvider();
    const result = await signInWithPopup(auth, provider);
    // The signed-in user info.
    const user = result.user;
    // This gives you a Google Access Token which you can use to access the Google API.
    const token = await user.getIdToken();
    const fcmtoken = await generateFCMToken();
    return { user, token, fcmtoken };
  } catch (error) {
    console.error("Error signing in with Google:", error);
    return {
      message: "Failed to sign in with Google.",
    };
  }
};

export const verifyEmailUsingOOBCode = async (
  oobCode: string
): Promise<{ success: boolean; message: string }> => {
  if (!oobCode) {
    return { success: false, message: "Verification code is missing." };
  }

  try {
    // Check the action code to verify the email
    await checkActionCode(auth, oobCode);
    // Action code is valid, apply it to verify the email
    await applyActionCode(auth, oobCode);
    return {
      success: true,
      message: "Email verified successfully. You may need to sign in again.",
    };
  } catch (error: any) {
    // Handle specific error codes and return an appropriate message and success flag
    const errorMessage = handleEmailVerificationError(error.code);
    return { success: false, message: errorMessage };
  }
};

/**
 * Interprets specific error codes and returns appropriate error messages.
 *
 * @param {string} errorCode - The error code from the email verification attempt.
 * @returns {string} - The appropriate error message based on the errorCode.
 */
const handleEmailVerificationError = (errorCode: string): string => {
  switch (errorCode) {
    case "auth/invalid-action-code":
      return "The verification link is invalid or expired. Please request a new one.";
    case "auth/user-disabled":
      return "Your account has been disabled by an administrator.";
    case "auth/user-not-found":
      return "No user found for this verification request.";
    default:
      console.error("Email verification error:", errorCode);
      return "Email verification failed. Please try again.";
  }
};

export const signInWithCustomTokenGeneric = async (customtoken: string) => {
  const userCredential = await signInWithCustomToken(auth, customtoken);
  const token: string = await userCredential.user.getIdToken();
  const fcmtoken = await generateFCMToken();
  var user = auth.currentUser;
  return { user, token, fcmtoken };
};
