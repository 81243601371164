import React, { useState } from "react";
import {
  Button,
  CheckBox,
  DropdownComponent,
  InputLabelField,
  Textarea,
  UseFormikForm,
  yup,
} from "XonickPay-Components-Lib";

export interface CredentialsProps {
  name: string;
  email: string;
  phoneNumber: number;
  category: any;
  subject: string;
  agree: boolean;
  attachment: any;
  description: string;
}

const InquiryForm = () => {
  const [agreeCheckBox, setAgreeCheckBox] = useState(false);
  const initialValues: CredentialsProps = {
    name: "",
    email: "",
    phoneNumber: 0,
    category: "",
    subject: "",
    agree: agreeCheckBox,
    attachment: "",
    description: "",
  };

  const validationSchema = yup.object().shape({
    name: yup.string().required("Please enter your Name"),
    email: yup
      .string()
      .email("Invalid email")
      .required("Email is required")
      .matches(/@[^.]*\./),
    category: yup.string().required("Please select category options"),
    subject: yup.string().required("Please enter inquiry subject"),
  });

  const Category = [
    {
      label: "Account Assistance",
      value: "Account Assistance",
    },
    {
      label: "Transaction Issues",
      value: "Transaction Issues",
    },
    {
      label: "Withdrawal Help",
      value: "Withdrawal Help",
    },
    {
      label: "Deposit Queries",
      value: "Deposit Queries",
    },
    {
      label: "Trading Support",
      value: "Trading Support",
    },
    {
      label: "Security Concerns",
      value: "Security Concerns",
    },
    {
      label: "Verification Help",
      value: "Verification Help",
    },
    {
      label: "Fee Information",
      value: "Fee Information",
    },
    {
      label: "Report a Bug",
      value: "Report a Bug",
    },
    {
      label: "Technical Support",
      value: "Technical Support",
    },
    {
      label: "Password Reset",
      value: "Password Reset",
    },
  ];

  return (
    <UseFormikForm
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={() => {}}
      formClassName=""
    >
      {(formik: any, handleSubmit: any) => {
        return (
          <form
            className="grid grid-cols-1 md:grid-cols-2 gap-4 h-full border border-[#ff9247] rounded p-3 md:p-0 md:border-0"
            onSubmit={handleSubmit}
          >
            <div className="mb-4">
              <InputLabelField
                label="Name"
                name="name"
                type="name"
                placeholder="Name"
                onChange={(event) =>
                  formik.setFieldValue("name", event.target.value)
                }
                onBlur={() => formik.setFieldTouched("name", true)}
              />
              {formik.touched.name && formik.errors.name ? (
                <div className="text-red-500">{formik.errors.name}</div>
              ) : (
                <div />
              )}
            </div>
            <div className="mb-4">
              <InputLabelField
                label="Email"
                name="email"
                type="email"
                placeholder="Email"
                autoComplete="email"
                onChange={(event) =>
                  formik.setFieldValue("email", event.target.value)
                }
                onBlur={() => formik.setFieldTouched("email", true)}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="text-red-500">{formik.errors.email}</div>
              ) : (
                <div />
              )}
            </div>
            <div className="mb-4">
              <InputLabelField
                label="Phone Number (Optional)"
                name="phoneNumber"
                type="number"
                placeholder="Phone Number"
                autoComplete="phoneNumber"
                onChange={(event) =>
                  formik.setFieldValue("phoneNumber", event.target.value)
                }
                onBlur={() => formik.setFieldTouched("phoneNumber", true)}
              />
              {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                <div className="text-red-500">{formik.errors.phoneNumber}</div>
              ) : (
                <div />
              )}
            </div>
            <div className="mb-4">
              <DropdownComponent
                name="category"
                options={Category}
                isMulti={false}
                placeholder="Select Category..."
                selectedOption={
                  (Category.length > 0 &&
                    Category.find(
                      (opt) => opt.value === formik.values.category
                    )) ||
                  []
                }
                onOptionChange={(selectedOption: any) =>
                  Category.length > 0 &&
                  formik.setFieldValue("category", selectedOption?.value || "")
                }
              />
            </div>
            <div className="mb-4">
              <InputLabelField
                label="Subject"
                name="subject"
                type="text"
                placeholder="Subject"
                autoComplete="subject"
                onChange={(event) =>
                  formik.setFieldValue("subject", event.target.value)
                }
                onBlur={() => formik.setFieldTouched("subject", true)}
              />
              {formik.touched.subject && formik.errors.subject ? (
                <div className="text-red-500">{formik.errors.subject}</div>
              ) : (
                <div />
              )}
            </div>
            <div className="mb-4">
              <InputLabelField
                type="file"
                id="attachment"
                name="attachment"
                inputClassName="block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                onChange={(event: any) =>
                  formik.setFieldValue("attachment", event.target.value)
                }
              />
              <p className="text-sm text-gray-500">
                Note: Max file size 5MB; accepted formats: jpg, jpeg, png, pdf,
                docx
              </p>
            </div>
            <div className="mb-4 col-span-1 md:col-span-2">
              <Textarea
                label="Description"
                labelClassName="block text-sm font-bold mb-2"
                name="description"
                onChange={(event: any) =>
                  formik.setFieldValue("description", event.target.value)
                }
              />
            </div>
            <div className="mb-4 col-span-1 md:col-span-2">
              <div className="flex items-center">
                <CheckBox
                  label={
                    "I agree to Xonick Pay collecting my details through this form."
                  }
                  checked={agreeCheckBox}
                  name="agree"
                  onChange={(event: boolean) => {
                    formik.setFieldValue("agree", event);
                    setAgreeCheckBox(event);
                  }}
                />
              </div>
            </div>
            <Button
              type="submit"
              btnClassName="w-full py-2 px-4 rounded-md bg-gradient-to-r from-orange-500 to-amber-500 text-white text-lg from-20% col-span-1 md:col-span-2"
              disabled={formik.isSubmitting || !agreeCheckBox}
            >
              Submit
            </Button>
          </form>
        );
      }}
    </UseFormikForm>
  );
};

export default InquiryForm;
