import * as bip39 from "bip39";
import { Buffer } from "buffer";
import * as bitcoin from "bitcoinjs-lib";
import QRCode from "qrcode";
import TronWebInstance from "../Utils/TronWebInstance";
import { getBalances, transfer } from "./WalletOperations";

window.Buffer = Buffer;
const tronWeb = TronWebInstance.getInstance();

export const exportWalletFromMnemonic = async (
  mnemonic: string
): Promise<{
  address: string;
  privateKey: string;
  publicKey: string;
  addressQR: string;
  privateKeyQR: string;
}> => {
  // Generate seed from mnemonic
  const seed = bip39.mnemonicToSeedSync(mnemonic);
  // Use bitcoinjs-lib to derive the BIP32 root key and then derive the specific path for Tron
  const root = bitcoin.bip32.fromSeed(seed);
  const tronPath = "m/44'/195'/0'/0/0"; // Using BIP44 path for Tron
  const keyPair = root.derivePath(tronPath);

  // Error handling in case private key is not available
  if (!keyPair.privateKey) {
    throw new Error("Failed to derive private key");
  }

  // Convert the private key into a hex string suitable for Tron
  const privateKey = keyPair.privateKey.toString("hex");
  // Use TronWeb to get the address from the private key
  const address = tronWeb.address.fromPrivateKey(privateKey);
  // Public key needs to be derived again as TronWeb might have specific format requirements
  const publicKey = tronWeb.address.fromPrivateKey(privateKey).publicKey;

  // Generate QR Codes
  const addressQR = await QRCode.toDataURL(address);
  const privateKeyQR = await QRCode.toDataURL(privateKey);

  return {
    address,
    privateKey,
    publicKey,
    addressQR,
    privateKeyQR,
  };
};

export const exportWalletFromPrivateKey = async (
  privateKey: string
): Promise<{
  address: string;
  publicKey: string;
  addressQR: string;
  privateKeyQR: string;
}> => {
  const address = tronWeb.address.fromPrivateKey(privateKey);
  const publicKey = tronWeb.address.toHex(address);

  // Generate QR Codes
  const addressQR = await QRCode.toDataURL(address);
  const privateKeyQR = await QRCode.toDataURL(privateKey);

  return {
    address,
    publicKey,
    addressQR,
    privateKeyQR,
  };
};

const encryptedPrivateKey = process.env.ENCRYPTED_KEY;
const encryptionKey = process.env.ENCRYPTION_KEY;
const iv = process.env.IV_KEY;
const encryptedSenderAddress = process.env.ENCRYPTED_ADDRESS;
const encryptionSenderAddress = process.env.ENCRYPTION_ADDRESS;
const ivSenderAddress = process.env.IV_ADDRESS;

export const generateNewWallet = async () => {
  const encryptedPrivateKeys = encryptedPrivateKey || "";
  const encryptionKeys = Buffer.from(encryptionKey || "", "hex");
  const ivs = Buffer.from(iv || "", "hex");

  // Decrypt the private key
  const crypto = require("crypto");
  const decipher = crypto.createDecipheriv("aes-256-cbc", encryptionKeys, ivs);

  let decryptedPrivateKey = decipher.update(
    encryptedPrivateKeys,
    "hex",
    "utf8"
  );
  decryptedPrivateKey += decipher.final("utf8");

  const encryptedSendersAddress = encryptedSenderAddress || "";
  const encryptionSendersAddress = Buffer.from(
    encryptionSenderAddress || "",
    "hex"
  );
  const ivSendersAddress = Buffer.from(ivSenderAddress || "", "hex");

  // Decrypt the Sender Address
  const cryptoSender = require("crypto");
  const decipherSender = cryptoSender.createDecipheriv(
    "aes-256-cbc",
    encryptionSendersAddress,
    ivSendersAddress
  );

  let decryptedSenderKey = decipherSender.update(
    encryptedSendersAddress,
    "hex",
    "utf8"
  );
  decryptedSenderKey += decipherSender.final("utf8");

  const PrivateDecryptedKey = decryptedPrivateKey;
  const SenderDecryptedAddress = decryptedSenderKey;

  // Generate mnemonic
  const mnemonic: string = bip39.generateMnemonic();
  const seed: Buffer = bip39.mnemonicToSeedSync(mnemonic);
  const account: bitcoin.bip32.BIP32Interface = bitcoin.bip32.fromSeed(seed);

  if (!account.privateKey) {
    throw new Error("Failed to derive private key");
  }

  const privateKey = account.privateKey.toString("hex");
  const address = tronWeb.address.fromPrivateKey(privateKey);
  const publicKey = tronWeb.address.toHex(
    tronWeb.address.fromPrivateKey(privateKey)
  );

  // Generate QR Code for the Address
  const addressQR = await QRCode.toDataURL(address);

  // Optional: Generate QR Code for the Private Key
  const privateKeyQR = await QRCode.toDataURL(privateKey);

  // Send a minimum transaction to activate the account
  // const activationAmount = tronWeb.toSun(1); // Sending 1 TRX (minimum amount to activate)
  let result;
  // try {
  //   const senderAddress = SenderDecryptedAddress; // Replace with your actual sender address
  //   const transaction = await tronWeb.transactionBuilder.sendTrx(
  //     address,
  //     activationAmount,
  //     senderAddress
  //   );
  //   const signedTransaction = await tronWeb.trx.sign(
  //     transaction,
  //     PrivateDecryptedKey
  //   );
  //   result = await tronWeb.trx.sendRawTransaction(signedTransaction);
  //   console.log("Transaction sent:", result);
  // } catch (error) {
  //   console.error("Error sending transaction:", error);
  //   throw error; // Handle or propagate the error as needed
  // }

  // Fetch the balance of the generated address
  let balance;
  try {
    balance = await tronWeb.trx.getBalance(address);
  } catch (error) {
    console.error("Error fetching balance:", error);
    balance = 0; // Default to 0 in case of an error
  }

  return {
    mnemonic,
    seed: seed.toString("hex"),
    address,
    privateKey,
    publicKey,
    addressQR, // QR code data URL for the address
    privateKeyQR, // QR code data URL for the private key
    balance: tronWeb.fromSun(balance), // Convert Sun to TRX for readability
    result,
  };
};

// export const generateSubAccounts = async (mnemonic, accountIndex = 0, numSubAccounts = 10) => {
//   try {
//       const seed = bip39.mnemonicToSeedSync(mnemonic);
//       const root = bitcoin.bip32.fromSeed(seed);
//       const accounts:any = [];

//       for (let i = 0; i < numSubAccounts; i++) {
//           const path = `m/44'/195'/${accountIndex}'/0/${i}`; // Path for sub-accounts
//           const account = root.derivePath(path);

//           if (!account.privateKey) {
//               throw new Error(`Failed to derive private key for sub-account ${i}`);
//           }

//           const privateKey = account.privateKey.toString('hex');
//           const address = tronWeb.address.fromPrivateKey(privateKey);
//           const publicKey = tronWeb.address.toHex(tronWeb.address.fromPrivateKey(privateKey));

//           // Generate QR Codes for each sub-account
//           const addressQR = await QRCode.toDataURL(address);
//           const privateKeyQR = await QRCode.toDataURL(privateKey);

//           accounts.push({
//               path,
//               address,
//               privateKey,
//               publicKey,
//               addressQR,
//               privateKeyQR
//           });
//       }

//       return accounts;
//   } catch (error) {
//       console.error("Error generating sub-accounts:", error);
//       throw error;
//   }
// };

// export const verifyAccountsFromMnemonic = async (mnemonic, expectedAccounts) => {
//   for (let i = 0; i < expectedAccounts.length; i++) {
//     const accountIndex = i; // assuming accounts are sequentially indexed
//     const derivedAccounts = await generateSubAccounts(mnemonic, accountIndex, 1);
//     const derivedAccount = derivedAccounts[0];

//     if (derivedAccount.address !== expectedAccounts[i].address ||
//         derivedAccount.privateKey !== expectedAccounts[i].privateKey ||
//         derivedAccount.publicKey !== expectedAccounts[i].publicKey) {
//       return false; // Verification failed
//     }
//   }
//   return true; // All accounts verified
// };

export const platformFee = async (
  fromAddress: string,
  privateKey: string,
  feeAmount: number
) => {
  const encryptedSendersAddress = encryptedSenderAddress || "";
  const encryptionSendersAddress = Buffer.from(
    encryptionSenderAddress || "",
    "hex"
  );
  const ivSendersAddress = Buffer.from(ivSenderAddress || "", "hex");
  const cryptoSender = require("crypto");
  const decipherSender = cryptoSender.createDecipheriv(
    "aes-256-cbc",
    encryptionSendersAddress,
    ivSendersAddress
  );

  let decryptedSenderKey = decipherSender.update(
    encryptedSendersAddress,
    "hex",
    "utf8"
  );
  decryptedSenderKey += decipherSender.final("utf8");
  const SenderDecryptedAddress = decryptedSenderKey;

  return transfer(
    fromAddress,
    privateKey,
    SenderDecryptedAddress,
    feeAmount,
    "TRX"
  );
};

export const wallet = async () => {
  // decrypt address
  const encryptedAddress = encryptedSenderAddress || "";
  const encryptionAddress = Buffer.from(encryptionSenderAddress || "", "hex");
  const ivAddress = Buffer.from(ivSenderAddress || "", "hex");
  const cryptoSender = require("crypto");
  const decipherSender = cryptoSender.createDecipheriv(
    "aes-256-cbc",
    encryptionAddress,
    ivAddress
  );

  let decryptedKey = decipherSender.update(encryptedAddress, "hex", "utf8");
  decryptedKey += decipherSender.final("utf8");
  const Address = decryptedKey;

  //  decrypt Private key
  const encryptedPrivateKeys = encryptedPrivateKey || "";
  const encryptionKeys = Buffer.from(encryptionKey || "", "hex");
  const ivs = Buffer.from(iv || "", "hex");

  const crypto = require("crypto");
  const decipher = crypto.createDecipheriv("aes-256-cbc", encryptionKeys, ivs);

  let decryptedPrivateKey = decipher.update(
    encryptedPrivateKeys,
    "hex",
    "utf8"
  );
  decryptedPrivateKey += decipher.final("utf8");
  const address = tronWeb.address.fromPrivateKey(decryptedPrivateKey);
  const addressQR = await QRCode.toDataURL(address);

  // balance
  const balances: any = await getBalances(Address);

  return { Address, addressQR, balances };
};
