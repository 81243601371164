import React from "react";
import InputField from "../Input/InputFieldComponent";
var Autocomplete = function (_a) {
    var id = _a.id, value = _a.value, onChange = _a.onChange, placeholder = _a.placeholder, suggestions = _a.suggestions, onSelectSuggestion = _a.onSelectSuggestion;
    return (React.createElement("div", null,
        React.createElement(InputField, { type: "text", value: value, onChange: function (e) { return onChange(e.target.value); }, placeholder: placeholder, autoComplete: "off", label: "" }),
        suggestions.length > 0 && (React.createElement("ul", { className: "absolute z-10 bg-white border border-gray-300 rounded-md mt-1 w-80" }, suggestions.map(function (suggestion, index) { return (React.createElement("li", { key: index, onClick: function () {
                onSelectSuggestion(suggestion);
            }, className: "px-4 py-2 cursor-pointer hover:bg-gray-100" }, suggestion)); })))));
};
export default Autocomplete;
