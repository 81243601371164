export var setSessionStorageData = function (key, value) {
    typeof value === 'object' ?
        sessionStorage.setItem(key, JSON.stringify(value))
        : sessionStorage.setItem(key, value);
};
export var getSessionStorageData = function (key) {
    var storedData = sessionStorage.getItem(key);
    // return typeof storedData === 'string' ? JSON.parse(storedData) : storedData;
    return storedData; // JSON.parse is must important 
};
