import clsx from "clsx";
import React, { useState, useEffect } from "react";
var ToggleComponent = function (_a) {
    var onLabel = _a.onLabel, offLabel = _a.offLabel, onClickValue = _a.onClickValue, activeClassName = _a.activeClassName, inActiveClassName = _a.inActiveClassName, _b = _a.defaultValue, defaultValue = _b === void 0 ? false : _b;
    var _c = useState(defaultValue), isChecked = _c[0], setIsChecked = _c[1]; // Set initial state based on defaultValue
    useEffect(function () {
        setIsChecked(defaultValue);
    }, [defaultValue]);
    var handleToggle = function () {
        var newValue = !isChecked;
        onClickValue(newValue);
        setIsChecked(newValue);
    };
    return (React.createElement("label", { className: "relative inline-flex cursor-pointer select-none items-center justify-center rounded-md bg-gray-200 p-1" },
        React.createElement("input", { type: "checkbox", className: "sr-only", checked: isChecked, onChange: handleToggle }),
        React.createElement("span", { className: "flex items-center space-x-[6px] rounded py-2 px-[18px] text-sm font-medium ".concat(!isChecked
                ? clsx(activeClassName === undefined || null
                    ? "text-white bg-tint-500"
                    : activeClassName)
                : "text-body-color") }, onLabel),
        React.createElement("span", { className: "flex items-center space-x-[6px] rounded py-2 px-[18px] text-sm font-medium ".concat(isChecked
                ? clsx(activeClassName === undefined || null
                    ? "text-white bg-tint-500"
                    : activeClassName)
                : "text-body-color") }, offLabel)));
};
export default ToggleComponent;
