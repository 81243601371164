var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useRef } from "react";
import { CSVLink } from "react-csv";
import { FaFileExport, FaDownload } from "react-icons/fa6";
import { IoPrint } from "react-icons/io5";
import { jsPDF } from "jspdf";
import { useReactToPrint } from "react-to-print";
import LinkComponent from "../Link/LinkComponent";
var ExportTable = function (_a) {
    var data = _a.data, headers = _a.headers, fileName = _a.fileName, id = _a.id, responsive = _a.responsive;
    var _b = React.useState(false), dropdownOpen = _b[0], setDropdownOpen = _b[1];
    var dropdownRef = useRef(null);
    var handleExportPDF = function (userData, headerList) {
        var doc = new jsPDF("landscape");
        var y = 10; // Initial y position with some margin from the top
        var headerListData = headerList.filter(function (header) { return header.visible !== false; });
        // Set font size for data rows
        doc.setFontSize(8);
        // Shorten header names
        var shortenedHeaderList = headerListData.map(function (headerItem) { return (__assign(__assign({}, headerItem), { headerName: headerItem.headerName.length > 7
                ? headerItem.headerName.substring(0, 6) + "..."
                : headerItem.headerName })); });
        // Set up the header row
        shortenedHeaderList.forEach(function (headerItem, index) {
            if (headerItem.visible) {
                doc.text(headerItem.headerName, index * 15 + 2, y);
            }
        });
        // Move down a bit for data rows
        y += 10;
        // Set up the data rows
        userData.forEach(function (user) {
            var x = 2; // Initial x position
            headerList.forEach(function (headerItem) {
                if (headerItem.visible) {
                    var value = user[headerItem.propertyName];
                    // Remove "gmail.com"
                    if (headerItem.propertyName === "emailId" && value) {
                        value = value.replace("gmail.com", "");
                    }
                    // Check if the value is longer than 7 characters
                    if (value && value.length > 7) {
                        var lines = doc.splitTextToSize(value, 10); // Adjust the width as needed
                        lines.forEach(function (line, index) {
                            doc.text(line, x, y + index * 5); // Adjust the line spacing as needed
                        });
                    }
                    else {
                        doc.text(value != null ? value.toString() : "", x, y);
                    }
                    // Move to the next column
                    x += 15;
                }
            });
            // Draw a line after each row
            doc.line(2, y + 25, x - 15, y + 25);
            y += 30;
            if (y >= 200) {
                doc.addPage();
                y = 10;
            }
        });
        doc.save("table_data.pdf");
    };
    var handlePrint = useReactToPrint({
        content: function () { return document.getElementById(id); },
    });
    var toggleDropdown = function () {
        setDropdownOpen(function (prev) { return !prev; });
    };
    var handleOutsideClick = function (event) {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setDropdownOpen(false);
        }
    };
    useEffect(function () {
        document.addEventListener('mousedown', handleOutsideClick);
        return function () {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);
    return (React.createElement("div", { className: "relative", ref: dropdownRef },
        React.createElement(LinkComponent, { to: "", onClick: function () { return toggleDropdown(); }, className: "mx-2 flex items-center ".concat(responsive
                ? "justify-center"
                : "bg-gray-300 text-black border-gray-200 p-2 rounded") }, "Export Document"),
        dropdownOpen && (React.createElement("div", { id: "dropdown", className: "absolute  rounded-lg px-2 bg-white shadow-lg border border-gray-200 drop-shadow-md ".concat(responsive
                ? "bottom-12 w-full left-0 -ml-2"
                : "right-0 top-9 mt-2 w-48") },
            React.createElement(CSVLink, { data: data, headers: headers.map(function (header) { return header.headerName; }), filename: fileName, className: "px-4 py-2 w-full flex items-center text-gray-800 hover:bg-gray-200" },
                React.createElement(FaFileExport, { className: "mr-2" }),
                "CSV"),
            React.createElement("div", { onClick: function () { return handleExportPDF(data, headers); }, className: "px-4 py-2 w-full flex items-center text-gray-800 hover:bg-gray-200" },
                React.createElement(FaDownload, { className: "mr-2" }),
                "PDF"),
            React.createElement("div", { onClick: handlePrint, className: "px-4 py-2 w-full flex items-center text-gray-800 hover:bg-gray-200" },
                React.createElement(IoPrint, { className: "mr-2" }),
                " Print")))));
};
export default ExportTable;
