import React from 'react'
import techCard1 from "../../Assets/about_tech1.svg";
import techCard2 from "../../Assets/about_tech2.svg";
import techCard3 from "../../Assets/about_tech3.svg";
import techCardbg from "../../Assets/new-bg-custom.svg";

const TechCardComponent = () => {
  return (
    <div
        className="relative bg-cover bg-center bg-fixed bg-no-repeat"
        style={{ backgroundImage: `url(${techCardbg})` }}
      >
        <div className="relative container mx-auto p-16 ">
          <h1 className="text-2xl ssm:text-3xl md:text-4xl font-bold mb-10 text-center text-white">
            The Technology <span className="text-orange-400">at Heart</span>
          </h1>
          <div className="grid lg:grid-cols-3 grid-cols-1 gap-4 lg:ml-28 md:ml-12 ssm:-ml-4 msm:-ml-9 -ml-14">
            <div
              className="lg:w-80 md:w-[35rem] sm:w-[22rem] ssm:w-[20rem] w-[20rem] border border-white border-opacity-50 rounded-2xl hover:shadow-xl flex flex-col items-center p-4 bg-sky-900"
              
            >
              <img
                src={techCard1}
                className="rounded-lg overflow-hidden lg:w-full md:w-52"
              />
              <div className="mt-4 text-center text-white">
                <p className="mt-2">
                  Xonick Pay Wallet is at the heart of our platform, developed
                  by the expert team at Xipers Solutiions. Our platform is built
                  on the Tron blockchain, known for its high throughput,
                  scalability, and ability to support numerous decentralized
                  applications. The unique architecture of the Tron blockchain
                  enables Xonick Pay to execute transactions quickly and
                  securely, providing a seamless experience for our users.
                </p>
              </div>
            </div>

            <div
              className="lg:w-80 md:w-[35rem] sm:w-[22rem] ssm:w-[20rem] w-[20rem] border border-white border-opacity-50 rounded-2xl hover:shadow-xl flex flex-col items-center p-4 bg-sky-900"
              
            >
              <div className="text-center text-white">
                <p className="mt-2">
                  Experts from Xipers Solutiions bring a wealth of expertise in
                  the blockchain industry, with a proven track record of
                  developing innovative and secure solutions. Our team's deep
                  understanding of blockchain technology and commitment to
                  excellence have been crucial in creating a platform that meets
                  and exceeds industry standards.
                </p>
              </div>
              <img
                src={techCard2}
                className="rounded-lg mt-4 overflow-hidden lg:w-full md:w-52"
              />
            </div>

            <div
              className="lg:w-80 md:w-[35rem] sm:w-[22rem] ssm:w-[20rem] w-[20rem] border border-white border-opacity-50 rounded-2xl hover:shadow-xl flex flex-col items-center p-4 bg-sky-900"
              
            >
              <img
                src={techCard3}
                className="rounded-lg overflow-hidden lg:w-full md:w-52"
              />
              <div className="mt-4 text-center text-white">
                <p className="mt-2">
                  Harnessing the strengths of the Tron blockchain, Xonick Pay
                  provides a reliable and efficient solution for converting USDT
                  to INR and vice versa setting new benchmarks in the digital
                  currency exchange landscape.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}

export default TechCardComponent