// components/PieChart.tsx
import React from 'react';
import { Pie } from 'react-chartjs-2';
var PieChartComponent = function (_a) {
    var data = _a.data, title = _a.title;
    return (React.createElement("div", { className: "bg-white rounded-lg p-4 border border-gray-200 shadow-lg" },
        React.createElement("h2", { className: "text-lg font-semibold mb-4" }, title),
        React.createElement(Pie, { data: data })));
};
export default PieChartComponent;
