var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useState, useRef, useEffect, useCallback } from "react";
import { BsRecordBtn, BsStopCircle, } from "react-icons/bs";
import ButtonComponent from "../Button/ButtonComponent";
import { addToast } from "../Toster/TosterNotification";
import Webcam from "react-webcam";
var VideoCapture = function (_a) {
    var onRecordStart = _a.onRecordStart, onRecordStop = _a.onRecordStop, onDownload = _a.onDownload, ButtonName = _a.ButtonName, content = _a.content, btnClassName = _a.btnClassName;
    var webcamRef = useRef(null);
    var mediaRecorderRef = useRef(null);
    var _b = useState(false), isRecording = _b[0], setIsRecording = _b[1];
    var _c = useState(false), isPaused = _c[0], setIsPaused = _c[1];
    var _d = useState([]), recordedChunks = _d[0], setRecordedChunks = _d[1];
    var _e = useState(0), timer = _e[0], setTimer = _e[1];
    var _f = useState(undefined), timerInterval = _f[0], setTimerInterval = _f[1];
    var _g = useState(false), submitAprrove = _g[0], setSubmitAprrove = _g[1];
    useEffect(function () {
        if (isRecording) {
            setTimer(0);
            var interval = setInterval(function () {
                setTimer(function (prevTimer) {
                    var newTimer = prevTimer + 1;
                    if (newTimer > 10) {
                        setSubmitAprrove(true);
                    }
                    else {
                        setSubmitAprrove(false);
                    }
                    return newTimer;
                });
            }, 1000);
            setTimerInterval(interval);
        }
        else {
            clearInterval(timerInterval);
            setTimerInterval(undefined);
        }
    }, [isRecording]);
    var handleRecordClick = useCallback(function () {
        if (webcamRef.current) {
            var stream = webcamRef.current.stream;
            if (stream) {
                mediaRecorderRef.current = new MediaRecorder(stream, {
                    mimeType: "video/webm; codecs=opus",
                    audioBitsPerSecond: 320000,
                });
                mediaRecorderRef.current.ondataavailable = function (event) {
                    if (event.data.size > 0) {
                        setRecordedChunks(function (prevChunks) { return __spreadArray(__spreadArray([], prevChunks, true), [event.data], false); });
                    }
                };
                mediaRecorderRef.current.onstop = function () {
                    var blob = new Blob(recordedChunks, { type: "video/webm" });
                    var url = URL.createObjectURL(blob);
                    if (webcamRef.current && webcamRef.current.video) {
                        webcamRef.current.video.src = url;
                        webcamRef.current.video.addEventListener("loadedmetadata", function () {
                            webcamRef.current.video.play();
                        });
                    }
                    setIsPaused(false);
                    if (onRecordStop) {
                        onRecordStop();
                    }
                };
                mediaRecorderRef.current.start();
                setIsRecording(true);
                setIsPaused(false);
                if (onRecordStart) {
                    onRecordStart();
                }
            }
        }
    }, [onRecordStart, onRecordStop, recordedChunks]);
    var handleStopClick = useCallback(function () {
        if (mediaRecorderRef.current && mediaRecorderRef.current.state === "recording") {
            mediaRecorderRef.current.stop();
            setIsRecording(false);
        }
    }, []);
    var handleCameraError = function () {
        window.confirm("\n  Notifications are currently disabled. Please follow these steps to enable notifications:\n            \n1. Click on the 'i' or padlock icon to the left of the URL in your browser's address bar.\n2. In the dropdown menu that appears, look for the \"Permissions\" section.\n3. Find \"Notifications\" and select \"Allow\" from the dropdown menu next to it.\n4. Similarly, find \"Camera\", \"Microphone\" and \"Sound\" and select \"Allow\" for both.\n5. Refresh this page after changing the settings.\n          \n  If you don't see these options, you may need to go to your browser's settings and look for \"Privacy and security\" or \"Site settings\" to manage notifications, camera, and microphone permissions.\n      ");
    };
    var handleSubmitClick = useCallback(function () {
        if (!isRecording && recordedChunks.length > 0) {
            if (onDownload) {
                onDownload(recordedChunks);
            }
        }
        else {
            addToast("Please stop recording", "error");
        }
    }, [isRecording, onDownload, recordedChunks]);
    return (React.createElement("div", { className: "flex flex-col items-center space-y-4" },
        React.createElement(Webcam, { ref: webcamRef, audio: true, videoConstraints: {
                width: 1280,
                height: 720,
                facingMode: "user",
            }, className: "mt-4 max-h-80 rounded-xl w-3/4", onUserMediaError: handleCameraError }),
        React.createElement("div", { className: "max-w-screen-md", dangerouslySetInnerHTML: { __html: content } }),
        React.createElement(ButtonComponent, { onClick: isRecording ? handleStopClick : handleRecordClick, type: "button", className: "p-4 rounded-md ".concat(isRecording ? "bg-red-500" : "bg-green-500", " text-white") }, isRecording ? React.createElement(BsStopCircle, null) : React.createElement(BsRecordBtn, null)),
        React.createElement("div", { className: "text-xl font-bold" },
            Math.floor(timer / 60),
            ":",
            (timer % 60).toString().padStart(2, "0")),
        submitAprrove && (React.createElement(ButtonComponent, { type: "submit", className: btnClassName, children: ButtonName, onClick: handleSubmitClick }))));
};
export default VideoCapture;
