var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { FaTimes } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import LinkComponent from "../Link/LinkComponent";
import { HiMiniBars3BottomLeft } from "react-icons/hi2";
var HeaderComponent = function (_a) {
    var navItems = _a.navItems, iconItems = _a.iconItems, logoImage = _a.logoImage, profileImage = _a.profileImage, profileName = _a.profileName, links = _a.links, navClassName = _a.navClassName, logoClassName = _a.logoClassName, navItemClassName = _a.navItemClassName, navDropdownClassName = _a.navDropdownClassName, navDropdownItemClassName = _a.navDropdownItemClassName, iconItemClassName = _a.iconItemClassName, profileDropdownChildren = _a.profileDropdownChildren, dropdownClassName = _a.dropdownClassName, imageLink = _a.imageLink, iconClassName = _a.iconClassName, navIcon = _a.navIcon, props = __rest(_a, ["navItems", "iconItems", "logoImage", "profileImage", "profileName", "links", "navClassName", "logoClassName", "navItemClassName", "navDropdownClassName", "navDropdownItemClassName", "iconItemClassName", "profileDropdownChildren", "dropdownClassName", "imageLink", "iconClassName", "navIcon"]);
    var _b = useState(null), dropdownOpenFor = _b[0], setDropdownOpenFor = _b[1];
    var _c = useState(false), menuOpen = _c[0], setMenuOpen = _c[1];
    var toggleDropdown = function (id) {
        setDropdownOpenFor(dropdownOpenFor === id ? null : id);
    };
    var handleClickOutside = function (event) {
        var dropdown = document.getElementById("dropdown");
        if (dropdown && !dropdown.contains(event.target)) {
            setDropdownOpenFor(null);
        }
    };
    useEffect(function () {
        document.addEventListener("mousedown", handleClickOutside);
        return function () {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);
    var renderDropdownContent = function () {
        var item = iconItems.find(function (icon) { return icon.id === dropdownOpenFor; });
        return item ? item.dropdownContent : null;
    };
    var handleToggle = function () {
        setMenuOpen(!menuOpen);
    };
    return (React.createElement("nav", { className: clsx("p-4 top-0 right-0 w-full relative z-50", navClassName === undefined || null ? "bg-gray-100" : navClassName) },
        React.createElement("div", { className: "w-full mx-auto flex justify-between items-center" },
            React.createElement(LinkComponent, { to: imageLink, target: "_blank", className: logoClassName },
                React.createElement("img", { src: logoImage, alt: "logo" })),
            React.createElement("div", { id: "collapseMenu", className: "".concat(menuOpen ? "block" : "hidden", " lg:flex lg:items-center lg:space-x-5 relative") },
                React.createElement("button", { id: "toggleClose", className: "lg:hidden -top-10 -right-44 sm:right-16 z-50 rounded-full bg-white p-3 absolute", onClick: handleToggle },
                    React.createElement(FaTimes, { className: "w-4 h-4 fill-black" })),
                React.createElement("ul", { className: "flex flex-col lg:flex-row lg:items-center gap-x-5 space-y-3 lg:space-y-0 fixed lg:relative bg-white lg:bg-transparent lg:w-full sm:w-5/12 w-full p-6 lg:p-0 top-0 left-0 lg:top-auto lg:left-auto z-40 h-screen lg:h-auto" }, navItems.map(function (item, index) { return (React.createElement("li", { key: index, className: "lg:border-none border-b border-gray-300 py-3 px-3 lg:py-0 lg:px-0" },
                    React.createElement(NavLink, { to: item.link, className: clsx(navItemClassName === undefined || null
                            ? "flex items-center w-full p-2 text-gray-900 font-medium"
                            : navItemClassName, location.pathname === item.link
                            ? "underline decoration-primary-500 decoration-4 underline-offset-8"
                            : "") }, item.navItem))); }))),
            React.createElement("div", { className: "flex items-center" },
                iconItems.map(function (item, index) { return (React.createElement("div", { key: index, className: "icon-item-".concat(index, " relative mx-1") },
                    React.createElement("button", { onClick: function () { return toggleDropdown(item.id); }, className: clsx(iconItemClassName === undefined || null
                            ? "flex items-center px-2 text-gray-900 text-2xl"
                            : iconItemClassName) }, item.navItem),
                    dropdownOpenFor === item.id && (React.createElement("div", { id: "dropdown", className: clsx("absolute right-0 mt-2 rounded-lg z-50 overflow-y-auto max-h-96 xl:max-h-screen", dropdownClassName === undefined || null
                            ? "bg-white shadow-lg w-60 p-6"
                            : dropdownClassName) }, renderDropdownContent())))); }),
                navIcon.map(function (item, index) { return (React.createElement("div", { className: "relative mx-1" },
                    React.createElement("button", { key: index, onClick: item.onClick, className: "p-2 w-full flex items-center text-gray-900 hover:bg-gray-200" }, item.icon))); }),
                React.createElement("div", { className: "relative flex space-x-3" },
                    React.createElement("button", { onClick: function () { return toggleDropdown("profile"); }, className: "flex items-center gap-4 text-gray-900 focus:outline-none" },
                        React.createElement("span", { className: "hidden text-right lg:block" },
                            React.createElement("span", { className: "block font-medium text-black" }, profileName)),
                        React.createElement("div", { className: "flex items-center" },
                            React.createElement("img", { src: profileImage, alt: "Profile", className: "w-8 h-8 rounded-full object-cover" }))),
                    dropdownOpenFor === "profile" && (React.createElement("div", { id: "dropdown", className: clsx("absolute right-0 mt-2 w-48 rounded-lg py-2", navDropdownClassName === undefined || null
                            ? "bg-white shadow-lg"
                            : navDropdownClassName) },
                        profileDropdownChildren,
                        links.map(function (link, index) { return (React.createElement("button", { key: index, onClick: link.onClick, className: clsx("px-4 py-2 w-full flex items-center", navDropdownItemClassName === undefined || null
                                ? "text-gray-800 hover:bg-gray-200"
                                : navDropdownItemClassName) },
                            link.icon,
                            link.text)); }))),
                    React.createElement("button", { id: "toggleOpen", className: clsx(iconClassName === undefined || null
                            ? "lg:hidden"
                            : iconClassName), onClick: handleToggle },
                        React.createElement(HiMiniBars3BottomLeft, { className: "sm:w-7 w-3 h-7 fill-black" })))))));
};
export default HeaderComponent;
