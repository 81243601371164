import React from "react";
import banner from "../Assets/work_banner.svg";
import DashboardView from "../Assets/dashbord_1.svg";
import WalletManag from "../Assets/WalletManagImage.svg";
import Dashboard from "../Assets/Rectangle 10.svg";
import FaqComponent from "../Components/Faq/FaqComponent";
import { Faqallpage } from "../Components/Faq/FaqConstant";
import BuySellPlacingCard from "../Components/Cards/BuySellPlacingCard";
import RegisterFlowComponent from "../Components/RegisterComponent/RegisterFlowComponent";
import { RegistrationAbout } from "../Components/RegisterComponent/RegisterFLowConstant";
import bg_mission from "../Assets/bg-light.svg";
import MainLayout from "../Layouts/MainLayout";

const Work = () => {
  return (
    <MainLayout>
      <div className="relative h-96 ">
        <img
          src={banner}
          className="object-cover w-full h-full absolute top-0 left-0"
          alt="Background Image"
        />
        <div className="container mx-auto px-5 md:py-20 relative z-10">
          <div className="md:w-1/2 md:mb-0 p-4 ">
            <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold mb-4">
              How It&nbsp;
              <span className="text-orange-400">Works?</span>
            </h1>
            <p className="text-lg md:text-xl lg:text-2xl">
              Xonick Pay enables seamless P2P trading of INR and USDT, providing
              a secure and user-friendly platform built on the Tron blockchain.
              Follow the step-by-step guide below to learn how to trade on
              Xonick Pay.
            </p>
          </div>
        </div>
      </div>
      <RegisterFlowComponent data={RegistrationAbout} />
      <div className="p-5">
        <h1 className="text-3xl md:text-4xl font-bold mb-10 text-center p-5">
          Navigating the <span className="text-orange-400"> Dashboard</span>
        </h1>
        <div className="container w-4/5 3xl:w-3/6 mx-auto p-6">
          <div className="bg-white p-6 shadow-lg rounded-md mb-6">
            <div className="flex flex-col md:flex-row items-center md:space-x-6">
              <img
                src={DashboardView}
                alt="Trading Image"
                className="w-full md:w-1/2 h-auto rounded-md"
              />

              <div className="text-center md:text-left md:ml-6 mt-4 md:mt-0">
                <h2 className="text-xl md:text-2xl font-bold">
                  Overview of the Dashboard
                </h2>
                <p className="mt-2 text-base md:text-lg">
                  After logging in, you will see an overview of your wallet
                  balance, recent transactions, and available trading options.
                </p>
              </div>
            </div>

            <div className="flex flex-col md:flex-row items-center md:space-x-6 mt-6">
              <img
                src={Dashboard}
                alt="Supported Currencies Image"
                className="w-full md:w-1/2 h-auto rounded-md md:hidden"
              />
              <div className="text-center md:text-left md:mr-6 mt-4 md:mt-0">
                <h2 className="text-xl md:text-2xl font-bold">
                  Supported Currencies
                </h2>
                <p className="mt-2 text-base md:text-lg">
                  The Xonick Pay wallet supports USDT and TRX.
                </p>
              </div>

              <img
                src={Dashboard}
                alt="Supported Currencies Image"
                className="w-full md:w-1/2 h-auto rounded-md hidden md:block"
              />
            </div>
          </div>
        </div>
      </div>
      <BuySellPlacingCard />

      <div className="p-5 bg-white">
        <h1 className="text-3xl md:text-4xl font-bold mb-10 text-center p-5">
          Managing Your <span className="text-orange-400"> Wallet</span>
        </h1>
        <div className="container w-4/5 3xl:w-3/6 mx-auto p-6">
          <div className="bg-white p-6 shadow-lg rounded-md mb-6">
            <div className="flex flex-col md:flex-row items-center md:space-x-6">
              <img
                src={WalletManag}
                alt="Trading Image"
                className="w-full md:w-1/2 h-auto rounded-md"
              />

              <div className="text-center md:text-left md:ml-6 mt-4 md:mt-0">
                <h2 className="text-xl md:text-2xl font-bold">
                  Deposits and Withdrawals
                </h2>
                <p className="mt-2 text-base md:text-lg">
                  Easily deposit TRX from an external wallet (e.g., Binance) and
                  withdraw USDT or INR to your bank account.
                </p>
              </div>
            </div>

            <div className="flex flex-col md:flex-row items-center md:space-x-6 mt-6">
              <img
                src={Dashboard}
                alt="Supported Currencies Image"
                className="w-full md:w-1/2 h-auto rounded-md md:hidden"
              />
              <div className="text-center md:text-left md:mr-6 mt-4 md:mt-0">
                <h2 className="text-xl md:text-2xl font-bold">
                  Transaction History
                </h2>
                <p className="mt-2 text-base md:text-lg">
                  Keep track of all your transactions within the Xonick Pay
                  wallet.
                </p>
              </div>

              <img
                src={Dashboard}
                alt="Supported Currencies Image"
                className="w-full md:w-1/2 h-auto rounded-md hidden md:block"
              />
            </div>
          </div>
        </div>
      </div>

      <FaqComponent faqItems={Faqallpage} />
    </MainLayout>
  );
};
export default Work;
