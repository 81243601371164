import React, { useEffect, useState } from "react";
import { Button } from "XonickPay-Components-Lib";
import LoaderComponent from "../../Components/Loader/LoaderComponent";
import backgroundImage from "../../Assets/background.svg";
import image404 from "../../Assets/404.svg";
import icon1 from "../../Assets/404-icon1.svg";
import icon2 from "../../Assets/404-icon2.svg";
import icon3 from "../../Assets/404-icon3.svg";
import icon4 from "../../Assets/404-icon4.svg";

const Eroor404Page: React.FC = () => {
  const [loading, setLoading] = useState(true);

  // Set loading to false after 2 seconds and cleanup function
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2500);

    return () => clearTimeout(timer);
  }, []);

  const goBack = () => {
    window.history.back();
  };

  if (loading) {
    return <LoaderComponent />;
  }

  return (
    <div
      className="h-screen overflow-hidden bg-no-repeat bg-cover flex justify-around"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className="flex-col justify-between my-10 hidden md:flex">
        <img src={icon1} alt="" />
        <img src={icon4} alt="" />
      </div>
      <div className="text-center my-auto md:mx-0 mx-4">
        <img src={image404} alt="" className="mx-auto md:w-min w-48" />
        <h3 className="md:text-5xl font-bold my-6 text-xl">
          Oops, this page is not found
        </h3>
        <p className="md:text-xl mb-6 md:w-4/6 mx-auto">
          Free equity investments and flat ₹20 intraday and F&O trades even
          higher earning rates and our lowest borrowing rates.
        </p>
        <Button
          onClick={goBack}
          btnClassName="py-4 px-6 rounded-full bg-gray-100 md:text-2xl"
        >
          Go Back
        </Button>
      </div>
      <div className="flex-col justify-between my-10 hidden md:flex">
        <img src={icon2} alt="" />
        <img src={icon3} alt="" />
      </div>
    </div>
  );
};

export default Eroor404Page;
