import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import ProtectedRoutes from "./ProtectedRoutes";
import { initFirebase } from "XonickPay-Auth";
import { onMessage } from "firebase/messaging";
import { TosterNotification, addToast } from "XonickPay-Components-Lib";
import "./App.css";
import { RoutingProvider } from "./Context/PreviousUrlContext";

const App: React.FC = () => {
  useEffect(() => {
    const { auth, messaging } = initFirebase(); // Get the messaging instance, assuming firebase has been initialized elsewhere
    onMessage(messaging, (payload) => {
      if (payload.notification?.body) {
        addToast(payload.notification.body, "info");
      }
    });
  }, []);

  return (
    <>
      <TosterNotification />
      <RoutingProvider>
        <BrowserRouter>
          <ProtectedRoutes />
        </BrowserRouter>
      </RoutingProvider>
    </>
  );
};

export default App;
