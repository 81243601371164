import React from "react";
import login from "../../Assets/Login-img.svg";
import backgroundImage from "../../Assets/login_background.svg";
import { LayoutProps } from "../../Models/types";

const AuthContainer: React.FC<LayoutProps> = ({ children }) => {
  return (
    <div
      className="bg-cover h-screen"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className="h-screen flex justify-center xl:gap-32 w-full items-center">
        <div className="hidden md:inline-block w-full max-w-md m-auto xl:my-auto xl:mx-0 2xl:max-w-2xl">
          <img
            src={login}
            alt="login-img"
            className="2xl:max-w-2xl h-dvh m-auto"
          />
        </div>
        <div className="w-full max-w-md 2xl:max-w-2xl my-auto">
          <div className="rounded-lg px-8 pt-6 pb-8 mb-4 p-6 bg-white border border-gray-200 shadow-lg mx-4">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthContainer;
