import React from "react";
import { LinkComponent } from "XonickPay-Components-Lib";

export const chatQue = [
    {
      question: "Account Assistance",
      answer: `We're here to help with your account needs. Please specify the issue you are facing, such as account access, updating account details, or other account-related concerns.`,
    },
    {
      question: "Transaction Issues",
      answer: `If you're experiencing issues with a transaction, please provide the transaction ID and describe the problem so we can assist you promptly.`,
    },
    {
      question: "Withdrawal Help",
      answer: `Need help with a withdrawal of TRX/USDT from Xonick Pay wallet? Let us know the currency and amount, and describe any issues you are encountering. We're here to ensure a smooth process.`,
    },
    {
      question: "Deposit Queries",
      answer: `For deposit assistance, please tell us the currency you're trying to deposit and any specific issues like delayed credits or unrecognized transactions.`,
    },
    {
      question: "Trading Support",
      answer: `We can assist with trading queries. Whether it's order execution, understanding trading fees, or other trading-related questions, please provide details so we can offer the best support`,
    },
    {
      question: "Security Concerns",
      answer: `Your security is crucial. If you suspect any security issues or have concerns about account safety, describe your concerns in detail so we can address them immediately.`,
    },
    {
      question: "Verification Help",
      answer: `Verification issues can be tricky. If you need help with your KYC or account verification, describe the problem, and we'll guide you through the necessary steps.`,
    },
    {
      question: "Fee Information",
      answer: `Questions about fees? Please specify whether you're inquiring about trading fees, withdrawal fees, or any other charges, and we'll provide detailed information.`,
    },
    {
      question: "Report a Bug",
      answer: `Found a bug? We appreciate your help in improving our platform. Please describe the issue, including where and when you encountered the bug.`,
    },
    {
      question: "Technical Support",
      answer: `Experiencing technical difficulties? Tell us about the issue, including any error messages or screenshots, so we can work to resolve it quickly`,
    },
    {
      question: "Password Reset",
      answer: (
        <>
          Need to reset your password? Follow this login{" "}
          <LinkComponent
           to="/login"
           className="text-blue-600"
           >
            link
          </LinkComponent>{" "}
          to enter your email address and select forgot password to reset it securely. If you encounter any issues during the process, please let us know.
        </>
      ),
    },
  ];
  
  export const chatConversation = [
    {
      title: "Welcome Message:",
      description:
        "Hello! Welcome to Xonick Pay Support. How can I assist you today?",
    },
    {
      title: "Issue Selection Prompt:",
      description:
        "Please select one of the following issues or type your question, and I'll help you out!",
    },
    {
      title: "Follow-Up on Selection (Dynamic based on user choice):",
      description:
        "You've selected [User's Choice]. Could you please provide me with more details or describe the issue further?",
    },
    {
      title: "Acknowledgement of Issue Details:",
      description:
        "Thank you for providing the details. Let me look into that for you right away.",
    },
    {
      title: "Interim Message (if the issue requires more time to resolve):",
      description:
        "This might take a few moments. I'm working on finding the best solution for you. Thank you for your patience.",
    },
    {
      title: "Solution Presentation or Escalation:",
      description:
        "I have found a solution that might help you. It seems your issue requires specialized attention. I'm going to connect you with one of our experts. Please hold on for just a moment.",
    },
    {
      title: "Additional Help Offer:",
      description: "Is there anything else I can assist you with today?",
    },
    {
      title: "Feedback Request:",
      description:
        "We strive to improve our service. Could you please rate your experience today or provide feedback on how we could do better?",
    },
    {
      title: "Closure:",
      description:
        "Thank you for contacting Xonick Pay Support. Have a great day!",
    },
    {
      title: "Offline Message (if chat is not available):",
      description:
        "Thank you for reaching out. Our support hours are 24/7. Please leave a message, and we will get back to you as soon as possible.",
    },
  ];
  
  export const kycStepList = [
    {
      title: "Step 1: Registration",
      step: {
        step1: {
          title: "Visit the Website :",
          data: "Go to the Xonick Pay website and click on the 'Sign Up' or 'Register' button.",
        },
        step2: {
          title: "Enter Your Details :",
          data: "You will need to provide basic information such as:",
        },
        SubStep: {
          subStep1: "Your full name as it appears on your government-issued ID.",
          subStep2:
            "A valid email address to which you will have ongoing access.",
          subStep3:
            "A strong password that meets the platform's security criteria.",
        },
        step3: {
          title: "Accept Terms :",
          data: "Read through the Terms of Service and Privacy Policy. If you agree, proceed by checking the box to accept.",
        },
      },
    },
    {
      title: "Step 2: Email Verification",
      step: {
        step1: {
          title: "Confirm Your Email :",
          data: "After registration, check your email for a verification link from Xonick Pay. Click on this link to verify your email address. If you don't see the email, check your spam folder or request a new verification email.",
        },
      },
    },
    {
      title: "Step 3: KYC (Know Your Customer) Compliance",
      step: {
        step1: {
          title: "Start KYC Process :",
          data: "Log in to your new Xonick Pay account and navigate to the KYC section.",
        },
        step2: {
          title: "Upload Documents :",
          data: "You will be asked to upload high-quality scans or photos of the following documents:",
        },
        SubStep: {
          subSubStep1:{
            title: "Aadhaar Card :",
            data: "For Aadhaar verification use Digilocker."
          },
          subSubStep2:{
             title: "PAN Card :",
            data: "To validate your financial and tax details."
          },
        },
        step3: {
          title: "Complete the Form :",
          data: "Fill in any additional information required for KYC compliance, such as your date of birth and current residential address.",
        },
      },
    },
    {
      title: "Step 4: Bank Verification",
      step: {
        step1: {
          title: "Link Your Bank Account :",
          data: "Enter your bank account details including account number and IFSC code. Ensure these details are accurate as they will be used for all future withdrawals and deposits.",
        },
        step2: {
          title: "Penny Drop Verification :",
          data: " Xonick Pay will conduct a penny drop verification to ensure the bank account belongs to you. This involves a small amount (usually one rupee) being deposited into your account.",
        },
        step3: {
          title: "Verify the Deposit :",
          data: "You may be asked to confirm the exact amount deposited or the transaction reference number to complete the bank verification process.",
        },
      },
    },
    {
      title: "Step 5: Video KYC ( Required)",
      step: {
        step1: {
          title: "Video KYC :",
          data: "Users may be required to complete a video KYC for additional security or regulatory reasons. Record and send a video holding your government-issued ID-proof",
        },
      },
    },
    {
      title: "Step 6: Final Review and Account Activation",
      step: {
        step1: {
          title: "Review by Xonick Pay :",
          data: "After all documents and verifications are submitted, Xonick Pay will review your application. This process typically takes 24 hours.",
        },
        step2: {
          title: "Account Activation :",
          data: "Once your account is verified and approved, you will receive an email confirming that your account is active and ready for trading.",
        },
      },
    },
  ];
  
  export const withdrawProcessList = [
    {
      title: "1. Initiate Withdrawal",
      subTitle: [
        "Log into your Xonick Pay account",
        "Navigate to the ‘Withdrawal’ section.",
        "Select the currency you wish to withdraw (USDT or TRX).",
        "Enter the amount and the wallet address to which you want to transfer the funds.",
      ],
    },
    {
      title: "2. Confirm Transaction",
      subTitle: [
        "Review the withdrawal details.",
        "Confirm the transaction with your password or 2FA (Two-Factor Authentication) for added security.",
      ],
    },
    {
      title: "3. Processing",
      subTitle: [
        "Withdrawals are typically processed within a few hours but may take up to 24 hours depending on network conditions and security checks.",
      ],
    },
    {
      title: "4. Completion",
      subTitle: [
        "You will receive a confirmation pop-up once the withdrawal is successful.",
        "Funds will appear in your designated wallet once processed.",
      ],
    },
  ];
  
  export const withdrawFaqList = [
    {
      question: "Q: What are the fees for trading and withdrawals?",
      answer:
        "A: Xonick Pay charges a minimal fee on trading while the withdrawal fees depend on the blockchain network and load (Tron charges).",
    },
    {
      question: "Q: How can I cancel an ongoing trade or withdrawal?",
      answer:
        "A: Trades can be cancelled if they have not been executed yet. Withdrawals can only be cancelled if they are still in the pending approval stage.",
    },
    {
      question: "Q: What should I do if a withdrawal fails?",
      answer:
        "A: Check for any notifications or support messages. If the issue persists, contact our support team for immediate assistance.",
    },
  ];
  
  export const tradingSupportList = [
      {
          title: "1. Start Trading",
          subTitle: [
              "Access the ‘Trade’ section in your dashboard.",
              "Choose whether to buy or sell USDT/TRX.",
              "Set your trade parameters, such as price limits and the amount you wish to trade."
          ]
      },
      {
          title: "2. Matching Orders - ",
          subTitle: [
              "Your order will be matched with other users' orders based on the best available prices.",
              "Monitor your trades in real time under the ‘My Orders’ section.",
          ]
      },
      {
          title: "3. Trade Execution -",
          subTitle: [
              "Once matched, your trade will execute automatically.",
              "Review the trade summary in your transaction history for details on the trade execution.",
          ]
      },
  ]
  
  export const withdrawCheckList = [
    {
      title: "Checklist -",
      steps: [
        "Always make sure that your wallet address is correct.",
        "Confirm network compatibility for withdrawals.",
        "Maintain necessary balances to cover any withdrawal fees.",
      ],
    },
  ];
  
  export const withdrawQuestionList = [
    {
      title: "How do I withdraw TRX from my Xonick Pay account?",
      heading:
        "To withdraw TRX from your Xonick Pay account, follow these steps:",
      steps: [
        "Log into your Xonick Pay account.",
        "Navigate to the 'Wallet' section and select 'TRX.'",
        "Click on the 'Withdraw' option.",
        "Enter the amount of TRX you wish to withdraw and the address of your external wallet.",
        "Confirm the transaction details and complete any security verifications required.",
        "Submit your withdrawal request.",
        "You will receive a confirmation once the TRX has been transferred to your external wallet.",
      ],
    },
    {
      title: "What are the fees associated with withdrawing USDT?",
      content:
        "The fee for withdrawing USDT from your Xonick Pay account is of the transaction amount. This fee is subject to change based on network conditions. Always check the latest fee schedule on the Xonick Pay platform before making a withdrawal to ensure you have the most current information.",
    },
    {
      title: "How long do withdrawals take?",
      content:
        "Withdrawal times can vary based on the blockchain network's traffic and the asset being withdrawn. Typically, TRX withdrawals are processed within minutes, while USDT withdrawals may take up to 1 hour. During times of high network congestion, delays may occur, and we appreciate your patience.",
    },
    {
      title: "Can I cancel a withdrawal in progress?",
      content:
        "Once a withdrawal has been initiated and confirmed by you, it cannot be cancelled due to the nature of blockchain transactions. It's crucial to double-check all transaction details before submitting them. If you suspect any mistake or need assistance, please contact our support team immediately.",
    },
    {
      title: "How do I initiate P2P trading on Xonick Pay?",
      heading:
        "To initiate P2P trading on Xonick Pay, follow these simple steps:",
      steps: [
        "Log into your Xonick Pay account and navigate to the P2P trading section.",
        "Select the currency pair you wish to trade (e.g., USDT to INR).",
        "Browse through the list of available offers or create your own trade offer by specifying the amount and price per unit.",
        "Once you find a suitable offer, click 'Trade' to open a trade dialogue with the other user.",
        "Follow the on-screen instructions to complete the trade, including payment to the seller's specified bank account or receiving payment to your account.",
        "Once payment is confirmed by both parties, the corresponding amount of cryptocurrency will be released from escrow to your Xonick Pay wallet.",
      ],
    },
  ];
  
  export const accountList = [
      {
          title: "How do I create an account on Xonick Pay?",
          step: 'To create an account, visit the Xonick Pay website and click on the "Sign Up" button. Follow the prompts to enter your personal information, verify your email address, and complete KYC verification for security purposes.'
      },
      {
          title: "What are the requirements for KYC verification?",
          step: 'KYC verification requires a government-issued ID, proof of address, and a recent photograph. Ensure your documents are valid and clearly visible. KYC is crucial for ensuring a secure trading environment.'
      },
      {
          title: "How can I update my registered email address or phone number?",
          step: 'You can update your contact details by navigating to the "Account Settings" section of your dashboard. Verification through your existing contact details may be required for security reasons.'
      },
      {
          title: "What should I do if I forget my password?",
          step: 'If you forget your password, click on the "Forgot Password?" link on the login page. You will be prompted to enter your registered email address, and a password reset link will be sent to you.'
      },
      {
          title: "How do I set up 2-factor authentication (2FA)?",
          step: 'To enhance the security of your account, we recommend setting up 2-factor authentication. Go to the "Security Settings" section in your account and follow the instructions to enable 2FA using your mobile device.'
      },
      {
          title: "Is my personal information safe with Xonick Pay?",
          step: 'We prioritize your privacy and security. All personal information is encrypted and stored securely. We comply with all relevant data protection regulations to safeguard your information.'
      },
      {
          title: "How can I close my Xonick Pay account?",
          step: 'If you decide to close your account, please contact our support team through the help center. Note that you will need to withdraw any remaining balances and close all open trades before account closure.'
      },
      {
          title: "What are the transaction limits on my account?",
          step: 'Transaction limits depend on your account level, which is determined by your completed KYC tier. For detailed information on limits, please refer to the "Limits and Fees" section in your account settings.'
      },
  ]
  
  export const bankStep = [
    {
      title: "Login to Your Account -",
      step: "Access your profile settings.",
    },
    {
      title: "Enter Bank Details -",
      step: "Provide your bank account number and IFSC code.",
    },
    {
      title: "Initiate Verification -",
      step: "Submit the details to trigger the penny drop process.",
    },
    {
      title: "Verify the Transaction -",
      step: "Check your bank account for a small deposit from Xonick Pay and confirm the amount on your platform profile.",
    },
  ];
  
  export const bankCheckList = [
    {
      title: "Checklist :",
      steps: [
        "Bank account number",
        "IFSC code",
        "Valid email linked to your bank account",
        "Access to online banking to verify the penny drop transaction",
      ],
    },
  ];
  
  export const securitySteps = [
    {
      title: "Two-Factor Authentication (2FA) -",
      step: "Adds an extra layer of security at login and withdrawal.",
    },
    {
      title: "End-to-End Encryption -",
      step: "Ensures that your data is encrypted during transmission and storage.",
    },
    {
      title: "Secure Storage -",
      step: "Uses state-of-the-art cryptographic storage methods to safeguard your funds.",
    },
  ];
  
  export const bankFaq = [
    {
      question: "Q: What if I don't see the penny drop transaction?",
      answer:
        "A: Please ensure the details entered are correct and check your bank statement after 24 hours. If you still encounter issues, contact our support team.",
    },
    {
      question: "Q: Is there a fee for bank account verification?",
      answer:
        "A: No, Xonick Pay covers all costs associated with the penny drop verification process.",
    },
  ];
  
  export const securityCheckList = [
    {
      title: "Tips for our Users",
      steps: [
        "Activate 2FA on all sensitive transactions.",
        "Regularly update your password and use a unique combination of characters.",
        "Monitor your account for any unauthorized access and alert our team immediately if you notice suspicious activity.",
      ],
    },
  ];