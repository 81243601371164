var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import clsx from "clsx";
import React from "react";
var InputFieldComponent = function (_a) {
    var label = _a.label, labelClassName = _a.labelClassName, inputClassName = _a.inputClassName, rest = __rest(_a, ["label", "labelClassName", "inputClassName"]);
    return (React.createElement("div", null,
        React.createElement("label", { className: clsx(labelClassName === undefined || null
                ? "block text-sm font-bold mb-2 text-tint-600"
                : labelClassName) }, label),
        React.createElement("input", __assign({}, rest, { className: clsx(inputClassName === undefined || null
                ? "border rounded w-full py-2 px-3 text-gray-500"
                : inputClassName), autoComplete: "off" }))));
};
export default InputFieldComponent;
