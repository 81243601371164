export const CONSTANTS = {
  EMAIL_VERIFY: "/email-verification",
  VERIFICATION_STEPS: "/kyc-verification",
  AD_POST_CREATE: "/advertisement/create",
  AD_LISTING: "/advertisement",
  ADD_NEW_BANK: (refId: number, refType: string) =>
    `/bank-details/add/${refId}/${refType}`,
  AD_PREVIEW: "/advertisement/verify",
  CONSENT_FORM: "/sign-consent",
  WAITING_SCREEN: "/registration-waiting",
  USER_PROFILE: "/user-profile",
  ORDER_INITIATED: (tradeId: number) => `/trade/${tradeId}/initiate`,
  ORDER_CONFIRM: "/trade/payment",
  ORDER_RELEASE: "trade/complete",
  MYADS: "/advertisement/view",
  TRADE_LIST: "/incomingTrade",
  VERIFY_PAYMENT: (tradeId: number) => `/incomingTrade/${tradeId}/verify`,
  WAITING_PAYMENT: "incomingTrade/waiting-payment",
  VIEW_WALLET: "/wallet",
  APPEAL: "/appeal",
  DASHBOARD: "/dashboard",
  ONGOING: "/ongoingTrade",
  UNDERTAKING_DOC: (tradeId: number) => `undertaking`,
  SUCCESS: "successFailureMessage",
  DEPOSIT: "/wallet/deposit",
  WITHDRAW: "/wallet/withdraw",
  TRADE_DETAILS_HISTORY: "/tradeHistory/tradeDetailsHistory",
  TRANSACTION_HISTORY: "/transactionHistory",
  TRANSACTION_DETAILS_HISTORY: "/transactionHistory/transactionDetailsHistory",
  REWARDS_HISTORY: "/rewards-history",
};

export const OPEN_ROUTES_CONSTANTS = {
  LOGIN: "/login",
  REGISTER: "/register",
  HOME_PAGE: "/",
  ABOUT_US_PAGE: "/about",
  FAQS: "/faq",
  WORK: "/work",
  SUPPORT: "/support",
  SUPPORT_ID: "/support-kyc",
  SUPPORT_ID_CHAT: "/support-chat-support",
  SUPPORT_ID_WITHDRAW: "/support-withdraw-trade",
  SUPPORT_ID_BANK: "/support-bank-account",
  SUPPORT_ID_SECURITY: "/support-security",
  SUPPORT_ID_ACCOUNT: "/support-account",
  SUPPORT_ID_INQUIRY: "/support-inquiry-form"
};
