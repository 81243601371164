import React, { useRef, useState, MouseEvent } from "react";
import { IoStar } from "react-icons/io5";
import img from "../../Assets/testimonial1.svg";
import symbol from "../../Assets/testimonialsymbol.svg";
import "../../PagesCss/home.css";

const Testimonial = () => {
  const handleScroll = (event) => {
    const container = event.target;
    const scrollAmount = event.deltaY;
    container.scrollTo({
      top: 0,
      left: container.scrollLeft + scrollAmount,
      behavior: "smooth",
    });
  };

  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const handleMouseDown = (event: MouseEvent<HTMLDivElement>) => {
    const container = scrollContainerRef.current;
    if (!container) return;

    setIsDragging(true);
    setStartX(event.pageX - container.offsetLeft);
    setScrollLeft(container.scrollLeft);
  };

  const handleMouseLeaveOrUp = () => {
    setIsDragging(false);
  };

  const handleMouseMove = (event: MouseEvent<HTMLDivElement>) => {
    if (!isDragging) return;
    event.preventDefault();

    const container = scrollContainerRef.current;
    if (!container) return;

    const x = event.pageX - container.offsetLeft;
    const walk = (x - startX) * 2; // The multiplier for faster scrolling
    container.scrollLeft = scrollLeft - walk;
  };

  const testiMonial = [
    {
      name: "Robert Fox",
      testiMonial: "Xonick Pay has transformed my trading experience with its security measures. The KYC process, Aadhaar e-sign, and face verification steps ensure that every transaction is secure and trustworthy. I feel confident knowing my trades are protected on this platform.",
      star: 5,
      image: img
    },
    {
      name: "Robert Fox",
      testiMonial: "Trading on Xonick Pay is incredibly efficient. The registration process was quick, and I could easily transfer TRX and start trading INR for USDT in no time. The platform's user-friendly interface makes it the best option with seamless transactions.",
      star: 5,
      image: img
    },
    {
      name: "Robert Fox",
      testiMonial: "Xonick Pay stands out with its exceptional customer support. Whenever I had queries or needed assistance, their team was prompt and thorough, ensuring all my issues were swiftly resolved. This level of service makes trading here a truly hassle-free experience.",
      star: 5,
      image: img
    },
    {
      name: "Robert Fox",
      testiMonial: "The real-time USDT/INR price chart on Xonick Pay is a game-changer for my trading decisions. The platform provides accurate and up-to-date market data that helps me make informed trades quickly and effectively. It's a reliable tool that I've come to depend on.",
      star: 5,
      image: img
    },
  ]

  return (
    <div className="flex justify-center items-center overflow-y-auto bg-white py-8">
      <div
        className="flex overflow-x-scroll pb-10 ml-[5%] hide-scrollbar"
        onWheel={handleScroll}
        ref={scrollContainerRef}
        onMouseDown={handleMouseDown}
        onMouseLeave={handleMouseLeaveOrUp}
        onMouseUp={handleMouseLeaveOrUp}
        onMouseMove={handleMouseMove}
        style={{ cursor: isDragging ? "grabbing" : "grab" }}
      >
        <div className="flex flex-nowrap flex-row items-center space-y-0 space-x-6">
          {testiMonial.map((testi, index) => (
            <div className="bg-white shadow-lg rounded-lg p-8 w-[26rem] h-[18rem]" key={index}>
              <div className="flex justify-start mb-4">
                {[...Array(testi.star)].map((_, index) => (
                  <IoStar key={index} className="text-yellow-500" />
                ))}
              </div>
              <p className="text-gray-700 mb-4 max-h-[10rem] overflow-y-auto hide-scrollbar">
                {testi.testiMonial}
              </p>
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <img
                    src={testi.image}
                    alt="testimonial-img"
                    className="h-12 w-12 rounded-full mr-4"
                  />
                  <p className="text-gray-900 font-semibold">{testi.name}</p>
                </div>
                <img src={symbol} alt="symbol-img" className="h-12 w-12" />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
