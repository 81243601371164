import React from "react";
import Register from "../../Assets/Rectangle 10.svg";
import Transfer from "../../Assets/Rectangle 11.svg";
import Trade from "../../Assets/Rectangle 12.svg";
import Funds from "../../Assets/Rectangle 13.svg";
import BlockChainBg from "../../Assets/new-bg-custom.svg";

const cardsData = [
  {
    img: Register,
    name: "Register",
    description:
      "Register in 4 easy steps, sign up and complete the KYC process for a secure USDT to INR exchange.",
  },
  {
    img: Transfer,
    name: "Transfer TRX",
    description:
      "Move TRX from your external wallet to your Xonick Pay wallet.",
  },
  {
    img: Trade,
    name: "Trade",
    description: "Enter into a USDT trade for INR seamlessly.",
  },
  {
    img: Funds,
    name: "Manage Funds",
    description:
      "Complete the trade and transfer TRX or USDT to another wallet or withdraw as needed.",
  },
];

const StaticCard = () => {
  return (
    <div
      className="p-4 md:p-6 lg:p-8 xl:p-10 h-auto bg-fixed bg-no-repeat bg-cover"
      style={{ backgroundImage: `url(${BlockChainBg})` }}
    >
      <h1 className="text-2xl text-white md:text-3xl lg:text-4xl 2xl:text-5xl font-bold text-center mt-4 mb-8">
        How to Trade&nbsp;
        <span className="text-orange-400 text-2xl md:text-3xl lg:text-4xl 2xl:text-5xl">
          USDT/INR?
        </span>
      </h1>
      <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-4 items-center gap-4">
        {cardsData.map((card, index) => (
          <div className="group flex flex-col items-center" key={index}>
            <div className="relative flex items-center h-52 lg:h-60 w-full max-w-sm mx-auto group-hover:bg-white rounded-xl overflow-hidden shadow-lg transition-all duration-500 ease-in-out transform group-hover:w-[450px] group-hover:rounded-[100px_10px_100px_100px]">
              <div className="flex-shrink-0 h-52 w-52 lg:h-60 lg:w-60 rounded-full overflow-hidden p-2">
                <img
                  src={card.img}
                  className="h-full w-full rounded-full object-cover"
                  alt={card.name}
                />
              </div>
              <div className="p-4 opacity-0 group-hover:opacity-100 transition-opacity duration-500 ease-in-out">
                <h2 className="text-xl lg:text-2xl font-semibold italic">
                  {card.name}
                </h2>
                <h4 className="text-sm lg:text-base">{card.description}</h4>
              </div>
            </div>
            <span className="mt-2 text-white text-xl lg:text-2xl font-semibold">
              {card.name}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StaticCard;
