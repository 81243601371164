import React, { useEffect, useState } from "react";
import { FcGoogle } from "react-icons/fc";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  CheckBox,
  InputLabelField,
  LinkComponent,
  PopupComponent,
  UseFormikForm,
  addToast,
  yup,
} from "XonickPay-Components-Lib";
import { SignUp, TermsAndConditions } from "XonickPay-Services";
import email from "../../Assets/emailVerification.svg";
import AuthContainer from "../../Layouts/AuthContainer/AuthContainer";
import { OPEN_ROUTES_CONSTANTS } from "../../Constants/routesConstant";
import { CredentialsProps } from "../../Models/types";
import { signInWithGoogle } from "../../Utils/VerificationFlow";
import { initAxios } from "../../config/http-common";
import {
  initFirebase,
  sendEmailOtp,
  signOutUser,
  resetPassword as firebaseResetPassword,
  verifyEmailUsingOOBCode,
  loginUser,
} from "XonickPay-Auth";
import {
  setSessionStorageData,
  getSessionStorageData,
} from "../../Utils/StorageDataSetGet";
import logoImage from "../../Assets/xonick_pay_logo.svg";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { verificationStepsComponent } from "../../Utils/VerificationStepsComponent";
import LoaderComponent from "../../Components/Loader/LoaderComponent";
import { useRoutingContext } from "../../Context/PreviousUrlContext";

const Login: React.FC = () => {
  const [userinvalid, setUserInvalid] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isEmailPopupOpen, setIsEmailPopupOpen] = useState(false);
  const [isCheckBoxChecked, setIsCheckBoxChecked] = useState(false);
  const [termsHtml, setTermsHtml] = useState<string>();
  const [formValues, setFormValues] = useState({
    password: "",
    email: "",
  });
  const [loading, setLoading] = useState(false);
  const { lastVisitedUrl, setLastVisitedUrl } = useRoutingContext();

  if (lastVisitedUrl) {
    caches.open("my-cache").then((cache) => {
      cache.put("/data", new Response(lastVisitedUrl));
    });
  }
  let previousUrl: any;
  caches.open("my-cache").then((cache) => {
    cache
      .match("/data")
      .then((response) => {
        if (response) {
          response.text().then((data) => {
            previousUrl = data;
            updateCacheAndScheduleDeletion(data);
          });
        }
      })
      .catch((error) => {
        return;
      });
  });

  let cachedData = null;
  let lastUpdateTime: any = null;

  function updateCache(data: any) {
    cachedData = data;
    lastUpdateTime = Date.now();
  }

  async function deleteIfExpired() {
    try {
      if (lastUpdateTime && Date.now() - lastUpdateTime > 15 * 60 * 1000) {
        const cache = await caches.open("my-cache");
        const deleted = await cache.delete("/data");
        if (deleted) {
          cachedData = null;
          lastUpdateTime = null;
          setLastVisitedUrl(null);
        }
      }
    } catch (error) {
      console.error("Error handling cache:", error);
    }
  }

  setInterval(deleteIfExpired, 1000 * 60);

  function updateCacheAndScheduleDeletion(data: any) {
    updateCache(data);
    setTimeout(() => {
      deleteIfExpired();
    }, 15 * 60 * 1000);
  }

  useEffect(() => {
    if (userinvalid) {
      addToast(
        "Invalid credential, Please enter valid email and password",
        "error"
      );
      // setTimeout(() => {
      //   addToast("If you're new, please create your account first.", "info");
      // }, 5000);
      setUserInvalid(false);
    }
  }, [userinvalid]);

  const navigate = useNavigate();
  const initialValues: CredentialsProps = {
    password: "",
    email: "",
  };
  const { auth } = initFirebase();
  useEffect(() => {
    initAxios();
    const checkEmailVerification = async () => {
      const oobCode = new URLSearchParams(window.location.search).get(
        "oobCode"
      );
      if (oobCode) {
        await handleEmailVerification(oobCode);
      }
    };
    checkEmailVerification();
  }, [auth.currentUser]);

  async function handleEmailVerification(oobCode: string) {
    const { success, message } = await verifyEmailUsingOOBCode(oobCode);
    if (success) {
      addToast(message, "success");
    } else {
      addToast(message, "error");
    }
  }
  const location = useLocation();
  const {referralCode} = location.state || 0
  const urlParams = new URLSearchParams(window.location.search);
  const ibReferralCode = urlParams.get("ibreferral") || referralCode;

  const handleSubmitForm = async (values: CredentialsProps) => {
    setFormValues(values);
    setLoading(true);
    try {
      const { user, token, fcmtoken } = await loginUser(
        values.email,
        values.password
      );
      setSessionStorageData("UID", user.uid);
      setSessionStorageData("Token", token);
      setSessionStorageData("FcmToken", fcmtoken);
      if (token !== null) {
        if (user.emailVerified === false) {
          values.email = "";
          values.password = "";
          setLoading(false)
          setIsEmailPopupOpen(true);
          return;
        }
        initAxios();
        const parentIbReferralCode = ibReferralCode ? ibReferralCode : 0
        const signUpResult = await SignUp({ fcmToken: fcmtoken, parentIbReferralCode : parentIbReferralCode });
        setLoading(false);
        if (signUpResult.data.statuscode === 200) {
          const { data } = signUpResult.data;
          if (!data.isEmailVerified) {
            const emailVerificationSent = getSessionStorageData(
              "EmailVerificationSent"
            );
            if (!emailVerificationSent) {
              await sendEmailOtp();
              setSessionStorageData("EmailVerificationSent", "true");
              addToast("Email Not Verified, Check Inbox.", "error");
            } else {
              addToast(
                "Verification email has already been sent. Please check your inbox.",
                "info"
              );
            }
          } else {
            addToast("User signed in successfully", "success");
            setLoading(false);
          }

          verificationStepsComponent(
            data,
            navigate,
            user.uid,
            setIsPopupOpen,
            setTermsHtml,
            location,
            previousUrl
          );
        } else {
          addToast("SignUp failed", "error");
          setTimeout(() => {
            signOutUser();
          }, 2000);
        }
      }
    } catch (error) {
      setLoading(false);
      if (error instanceof Error) {
        const errorMessage = error.message;
        const lastBracketIndex = errorMessage.lastIndexOf("(");
        const lastBracket = errorMessage.substring(lastBracketIndex);

        // User is already existed than error given below message
        if (lastBracket === "(auth/invalid-credential).") {
          setUserInvalid(true);
        } else if (lastBracket === "(auth/too-many-requests).") {
          addToast(
            "Account has been temporarily disabled due to many failed login attempts.",
            "error"
          );
          setTimeout(() => {
            addToast(
              "You can immediately restore it by resetting your password or you can try again later.",
              "error"
            );
          }, 3000);
        } else {
          console.error("An error occurred:", errorMessage);
        }
      }
    }
  };

  const resetPassword = async (values: CredentialsProps) => {
    try {
      await firebaseResetPassword(values.email);
      addToast("Password reset link sent!", "info");
    } catch (err) {
      console.error(err);
    }
  };

  const validationSchema = yup.object().shape({
    password: yup.string().required("Password is required"),
    email: yup
      .string()
      .email("Invalid email")
      .required("Email is required")
      .matches(/@[^.]*\./),
  });

  const [showPassword, setShowPassword] = useState(false);

  const handleCheckBoxChecked = async () => {
    const objectId = getSessionStorageData("UID");
    const data = {
      objectId: objectId,
      isTermsAndCondition: true,
    };
    if (isCheckBoxChecked) {
      await TermsAndConditions(data, objectId);
      setIsPopupOpen(false);
      if (formValues.email !== "") {
        handleSubmitForm(formValues);
      } else {
        signInWithGoogle(
          location,
          navigate,
          setIsPopupOpen,
          setTermsHtml,
          setLoading,
          previousUrl
        );
      }
    }
  };

  if (loading) {
    return <LoaderComponent />;
  }

  return (
    <>
      <AuthContainer>
        <img src={logoImage} alt="logo-image" className="w-48 mx-auto" />
        <h2 className="md:text-4xl text-3xl text-primary-600 text-center font-bold mb-6 my-4">
          Login
        </h2>

        <UseFormikForm
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmitForm}
          formClassName=""
        >
          {(formik, handleSubmit) => (
            <form
              className="flex flex-col justify-evenly h-full"
              onSubmit={handleSubmit}
            >
              <div className="mb-4">
                <InputLabelField
                  label="Email"
                  name="email"
                  type="email"
                  placeholder="Email"
                  onChange={(event) =>
                    formik.setFieldValue("email", event.target.value)
                  }
                  onBlur={() => formik.setFieldTouched("email", true)}
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className="text-red-500">{formik.errors.email}</div>
                ) : (
                  <div />
                )}
              </div>
              <div className="relative">
                <InputLabelField
                  label="Password"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  autoComplete="password"
                  onChange={(event) =>
                    formik.setFieldValue("password", event.target.value)
                  }
                  onBlur={() => formik.setFieldTouched("password", true)}
                />
                {formik.touched.password && formik.errors.password ? (
                  <div className="text-red-500">{formik.errors.password}</div>
                ) : (
                  <div />
                )}
                <span
                  className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer h-12"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <BsEye /> : <BsEyeSlash />}
                </span>
              </div>
              <div className="mb-10 relative">
                <span className="absolute right-0">
                  <LinkComponent
                    to="#"
                    className="text-primary-600"
                    onClick={() => resetPassword(formik.values)}
                  >
                    Forgot Password?
                  </LinkComponent>
                </span>
              </div>
              <Button
                type="submit"
                btnClassName="w-full py-2 px-4 rounded-md bg-gradient-to-r from-orange-500 to-amber-500 text-white text-lg from-20%"
                disabled={formik.isSubmitting}
              >
                Login
              </Button>
              <div className="text-center flex flex-col justify-evenly h-44">
                <div className="flex items-center">
                  <div className="bg-gray-200 h-0.5 w-full" />
                  <span className="mx-2">or</span>
                  <div className="bg-gray-200 h-0.5 w-full" />
                </div>
                <p className="border rounded w-full py-2 px-3 text-gray-500">
                  <LinkComponent
                    to="#"
                    onClick={async () => {
                      signInWithGoogle(
                        location,
                        navigate,
                        setIsPopupOpen,
                        setTermsHtml,
                        setLoading,
                        previousUrl
                      );
                    }}
                    className="flex items-center justify-center"
                  >
                    Login with Google
                    <span className="mx-2">
                      <FcGoogle />
                    </span>
                  </LinkComponent>
                </p>
                <p>
                  Not registered yet?
                  <LinkComponent
                    to={OPEN_ROUTES_CONSTANTS.REGISTER}
                    className="font-medium text-primary-600 hover:underline ml-1"
                  >
                    Register
                  </LinkComponent>
                </p>
              </div>
            </form>
          )}
        </UseFormikForm>
        <PopupComponent
          isOpen={isPopupOpen}
          popupClassName="bg-gradient-to-b from-other-50 from-5% max-w-screen-sm"
          acceptClassName={`bg-gradient-to-r from-orange-500 to-amber-500 text-white ${
            isCheckBoxChecked ? "" : "opacity-50 cursor-not-allowed"
          }`}
          acceptText={"Confirm"}
          onAccept={handleCheckBoxChecked}
          onClose={() => {
            setIsPopupOpen(false),
              signOutUser();
          }}
          childrenClassName="h-[75vh]"
        >
          <div dangerouslySetInnerHTML={{ __html: termsHtml || "" }} />
          <div className="mt-4">
            <CheckBox
              label={"Accept all Terms and Conditions"}
              onChange={() => setIsCheckBoxChecked(!isCheckBoxChecked)}
            />
          </div>
        </PopupComponent>
        <PopupComponent
          isOpen={isEmailPopupOpen}
          acceptClassName="cursor-default"
          popupClassName="bg-custom-gradient w-full"
          onClose={() => {
            setIsEmailPopupOpen(false), signOutUser();
          }}
        >
          <div className="mt-4 mb-2 space-x-3">
            <img src={email} alt="email-verification" className="mx-auto mb-2" />
            <p className="text-primary-600 text-center text-xl mb-2 font-semibold">
              Please verify Email link on your registered email id.
            </p>
          </div>
        </PopupComponent>
      </AuthContainer>
    </>
  );
};

export default Login;
