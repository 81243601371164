var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useState } from "react";
import DropdownComponent from "../Dropdown/DropdownComponent";
import CheckBoxFieldComponent from "../CheckBox/CheckBoxFieldComponent";
import InputField from "../Input/InputFieldComponent";
import Autocomplete from "../AutoComplete/AutoCompleteComponent";
import { clsx } from "clsx";
import LinkComponent from "../Link/LinkComponent";
import RadioButton from "../Radio/RadioComponent";
var Filters = function (_a) {
    var initialFiltersData = _a.initialFiltersData, setFilterDataProps = _a.setFilterDataProps, linkLabel = _a.linkLabel, linkClassName = _a.linkClassName, onButtonClick = _a.onButtonClick, ClearClassName = _a.ClearClassName, onClearClick = _a.onClearClick;
    var _b = useState(initialFiltersData), filtersData = _b[0], setFiltersData = _b[1];
    var _c = useState({}), selectedOptions = _c[0], setSelectedOptions = _c[1];
    var _d = useState([]), autocompleteSuggestions = _d[0], setAutocompleteSuggestions = _d[1];
    useEffect(function () {
        setFilterDataProps(filtersData);
    }, [filtersData, setFilterDataProps]);
    var handleOptionChange = function (filterId, value, selectedOption) {
        setFiltersData(function (currentFilters) {
            return currentFilters.map(function (filter) {
                if (filter.id === filterId) {
                    return __assign(__assign({}, filter), { value: value });
                }
                return filter;
            });
        });
        setFilterDataProps(function (currentFilters) {
            return currentFilters.map(function (filter) {
                if (filter.id === filterId) {
                    return __assign(__assign({}, filter), { value: value });
                }
                return filter;
            });
        });
        setSelectedOptions(function (prevOptions) {
            var _a;
            return (__assign(__assign({}, prevOptions), (_a = {}, _a[filterId] = selectedOption, _a)));
        });
        if (filterId === "filter7") {
            fetchAutocompleteSuggestions(value.toString());
        }
    };
    var fetchAutocompleteSuggestions = function (value) {
        var suggestions = [];
        initialFiltersData.forEach(function (filter) {
            if (filter.controlType === "autocomplete" && filter.options) {
                filter.options.forEach(function (option) {
                    if (option.toLowerCase().includes(value.toLowerCase())) {
                        suggestions.push(option);
                    }
                });
            }
        });
        setAutocompleteSuggestions(suggestions);
    };
    var renderFilter = function (filter) {
        switch (filter.controlType) {
            case "dropdown":
                return (React.createElement("div", { key: filter.id },
                    React.createElement("label", { className: "block mb-2 font-semibold" }, filter.label),
                    React.createElement(DropdownComponent, { name: "", options: filter.options || [], isMulti: false, selectedOption: selectedOptions[filter.id] || null, onOptionChange: function (selectedOption) {
                            return handleOptionChange(filter.id, selectedOption.value, selectedOption);
                        } })));
            case "checkbox":
                return (React.createElement("div", { key: filter.id },
                    React.createElement("label", { htmlFor: filter.id, className: "block mb-2 font-semibold" }, filter.label),
                    (filter.options || []).map(function (option) {
                        var isChecked = (selectedOptions[filter.id] || []).includes(option.value);
                        return (React.createElement(CheckBoxFieldComponent, { key: option.value.toString(), label: option.label, checked: isChecked, onChange: function () {
                                setSelectedOptions(function (prevOptions) {
                                    var _a;
                                    var prevSelectedOptions = (prevOptions[filter.id] ||
                                        []);
                                    var updatedOptions = isChecked
                                        ? prevSelectedOptions.filter(function (item) { return item !== option.value; })
                                        : __spreadArray(__spreadArray([], prevSelectedOptions, true), [option.value.toString()], false);
                                    handleOptionChange(filter.id, updatedOptions.toString());
                                    return __assign(__assign({}, prevOptions), (_a = {}, _a[filter.id] = updatedOptions, _a));
                                });
                            } }));
                    })));
            case "radio":
                return (React.createElement("div", { key: filter.id },
                    React.createElement("label", { htmlFor: "".concat(filter.id), className: "block mb-2 font-semibold" }, filter.label),
                    (filter.options || []).map(function (option, index) { return (React.createElement(RadioButton, { key: index, label: typeof option === "string" ? option : option.label, name: filter.id, value: typeof option === "string" ? option : option.value, checked: filter.value ===
                            (typeof option === "string" ? option : option.value), onChange: function (value) {
                            handleOptionChange(filter.id, value);
                        } })); })));
            case "text":
            case "date":
            case "range":
                return (React.createElement("div", null,
                    React.createElement("label", { className: "block mb-2 font-semibold" }, filter.label),
                    React.createElement(InputField, { type: filter.controlType, value: selectedOptions[filter.id], onChange: function (e) { return handleOptionChange(filter.id, e.target.value); }, label: "", className: "block w-full px-4 py-2 rounded border-gray-300 focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" })));
            case "autocomplete":
                return (React.createElement("div", null,
                    React.createElement("label", { className: "block mb-2 font-semibold" }, filter.label),
                    React.createElement(Autocomplete, { id: filter.id, value: selectedOptions[filter.id], onChange: function (value) {
                            handleOptionChange(filter.id, value);
                            fetchAutocompleteSuggestions(value);
                        }, placeholder: "Type to search ".concat(filter.label, "..."), suggestions: autocompleteSuggestions, onSelectSuggestion: function (value) {
                            handleOptionChange(filter.id, value);
                            setAutocompleteSuggestions([]); // Clear the suggestions
                        } })));
            default:
                return null;
        }
    };
    var handleClearFilters = function () {
        setFiltersData(initialFiltersData.map(function (filter) { return (__assign(__assign({}, filter), { value: "" })); }));
        setSelectedOptions({});
        setAutocompleteSuggestions([]);
        onClearClick();
    };
    return (React.createElement("div", { className: clsx("border-2 border-gray-200 p-4 rounded overflow-auto no-scrollbar bg-white"), style: { height: "calc(100vh - 10.5rem)" } },
        initialFiltersData.map(function (filter) { return (React.createElement("div", { key: filter.id, className: "mb-4" }, renderFilter(filter))); }),
        React.createElement("div", { className: "flex items-center" },
            React.createElement(LinkComponent, { to: "", className: clsx("mt-4 p-2 rounded-md text-center w-1/2", linkClassName === undefined || null
                    ? "bg-blue-500 text-white"
                    : linkClassName), onClick: onButtonClick }, linkLabel),
            React.createElement(LinkComponent, { to: "", className: clsx("mt-4 ml-2 p-2 rounded-md text-center w-1/2", ClearClassName === undefined || null
                    ? "bg-gray-300 text-black border-gray-200"
                    : ClearClassName), onClick: handleClearFilters }, "Clear"))));
};
export default Filters;
