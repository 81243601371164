import clsx from "clsx";
import React from "react";
var Pagination = function (_a) {
    var currentPage = _a.currentPage, totalPages = _a.totalPages, onPageChange = _a.onPageChange, paginationPrevClassName = _a.paginationPrevClassName, paginationNextClassName = _a.paginationNextClassName, paginationActiveClassName = _a.paginationActiveClassName, paginationInactiveClassname = _a.paginationInactiveClassname;
    var generatePageNumbers = function () {
        var visiblePages = 3;
        var pageNumbers = [];
        var startPage = Math.max(1, currentPage - Math.floor(visiblePages / 2));
        var endPage = Math.min(totalPages, startPage + visiblePages - 1);
        if (totalPages <= visiblePages) {
            startPage = 1;
            endPage = totalPages;
        }
        else if (endPage - startPage + 1 < visiblePages) {
            startPage = endPage - visiblePages + 1;
        }
        for (var i = startPage; i <= endPage; i++) {
            pageNumbers.push(i);
        }
        return pageNumbers;
    };
    var pageNumbers = generatePageNumbers();
    return (React.createElement("nav", { className: "block" },
        React.createElement("ul", { className: "flex items-center -space-x-px h-10 text-base" },
            React.createElement("li", null,
                React.createElement("button", { onClick: function () { return onPageChange(currentPage - 1); }, disabled: currentPage === 1, className: clsx(paginationPrevClassName === undefined || null
                        ? "flex items-center justify-center px-4 h-10 ms-0 leading-tight text-gray-500 bg-white border border-e-0 border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 "
                        : paginationPrevClassName), style: {
                        cursor: currentPage === 1 ? "not-allowed" : "pointer",
                    } }, "Previous")),
            pageNumbers.map(function (page) { return (React.createElement("li", { key: page },
                React.createElement("button", { onClick: function () { return onPageChange(page); }, className: clsx("px-3 py-2 ,".concat(currentPage === page
                        ? paginationActiveClassName === undefined || null
                            ? "text-indigo-600 font-semibold flex items-center justify-center px-4 h-10 leading-tight  bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700"
                            : paginationActiveClassName
                        : paginationInactiveClassname === undefined || null
                            ? "flex items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700"
                            : paginationInactiveClassname)) }, page))); }),
            React.createElement("li", null,
                React.createElement("button", { onClick: function () { return onPageChange(currentPage + 1); }, disabled: currentPage >= totalPages, className: clsx(paginationNextClassName === undefined || null
                        ? "flex items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 "
                        : paginationNextClassName), style: {
                        cursor: currentPage >= totalPages ? "not-allowed" : "pointer",
                    } }, "Next")))));
};
export default Pagination;
