import {
  getFirestore,
  collection,
  addDoc,
  doc,
  getDoc,
  query,
  where,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import { app } from "../firebaseconfig";

const firestore = getFirestore(app);

export const firebaseStoreAddData = async (
  collectionName: string,
  data: any
) => {
  try {
    await addDoc(collection(firestore, collectionName), { data });
  } catch (error) {
    console.error("Error adding data: ", error);
  }
};

export const firebaseStoreAddSubData = async (
  collectionWithDocumentAndSubCollection: string,
  data: any
) => {
  try {
    await addDoc(
      collection(firestore, collectionWithDocumentAndSubCollection),
      { data }
    );
  } catch (error) {
    console.error("Error adding data: ", error);
  }
};

export const getFirebaseStoreDataById = async (
  collectionName: string,
  id: string
) => {
  try {
    const ref = doc(firestore, collectionName, id);
    const docSnap = await getDoc(ref);
    return docSnap.data();
  } catch (error) {
    console.error("Error geting data: ", error);
  }
};

export const getFirebaseStoreDataByQuery = async (
  collectionName: string,
  fieldName: any,
  operator: any,
  value: any
) => {
  try {
    const collectionRef = collection(firestore, collectionName);
    const querys = query(collectionRef, where(fieldName, operator, value)); // example :- where("age", ">=", 25)
    const snapshot = await getDocs(querys);
    return snapshot;
  } catch (error) {
    console.error("Error geting data: ", error);
  }
};

export const updateFirebaseStoreDataById = async (
  collectionName: string,
  id: string,
  data: any
) => {
  try {
    const ref = doc(firestore, collectionName, id);
    await updateDoc(ref, { data });
  } catch (error) {
    console.error("Error updating data: ", error);
  }
};
