import clsx from "clsx";
import React, { useEffect, useState } from "react";
var VerticalProgressSteps = function (_a) {
    var steps = _a.steps, activeBtnClassName = _a.activeBtnClassName, inActiveBtnClassName = _a.inActiveBtnClassName, mainClassName = _a.mainClassName, labelNameClassName = _a.labelNameClassName, initialStep = _a.initialStep;
    var _b = useState(initialStep), currentStep = _b[0], setCurrentStep = _b[1];
    useEffect(function () {
        setCurrentStep(initialStep);
    }, [initialStep]);
    return (React.createElement("div", { className: clsx(mainClassName, "flex h-screen w-full") },
        React.createElement("div", { className: "flex-col items-center w-1/2 p-4 h-4/6 justify-between hidden lg:flex" },
            steps[currentStep].imageSrc && (React.createElement("img", { src: steps[currentStep].imageSrc, alt: "Step ".concat(currentStep + 1, " Image") })),
            React.createElement("div", { className: "flex flex-col items-center w-9/12\trounded-lg px-8 p-6 bg-white" },
                React.createElement("div", { className: "relative w-full" }, steps.map(function (step, index) { return (React.createElement("div", { key: index, className: "flex items-center mb-4" },
                    React.createElement("div", { className: "flex items-center justify-center w-6 h-6 rounded-full border-2 border-gray-100 ".concat(currentStep === index
                            ? clsx(activeBtnClassName === undefined || null
                                ? "bg-blue-500 text-white"
                                : activeBtnClassName)
                            : clsx(inActiveBtnClassName === undefined || null
                                ? "bg-white"
                                : inActiveBtnClassName)) }, currentStep > index ? (React.createElement("span", { className: "text-sm font-semibold w-6 bg-green-600 rounded-full text-white flex items-center justify-center" }, "\u2713")) : (index + 1)),
                    React.createElement("div", { className: clsx(labelNameClassName, "ml-3") },
                        step.label,
                        step.caption && (React.createElement("div", { className: "text-sm text-gray-500 uppercase font-semibold tracking-wide" }, step.caption))))); })))),
        steps[currentStep].component));
};
export default VerticalProgressSteps;
