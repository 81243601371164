var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useRef, useState } from "react";
import LinkComponent from "../Link/LinkComponent";
import clsx from "clsx";
var AdvertisingComponent = function (_a) {
    var logos = _a.logos, imageClassName = _a.imageClassName;
    var logosRef = useRef(null);
    var _b = useState(0), animationDuration = _b[0], setAnimationDuration = _b[1];
    var _c = useState(false), isHovered = _c[0], setIsHovered = _c[1];
    useEffect(function () {
        if (logosRef.current) {
            var ul = logosRef.current;
            var totalWidth = ul.scrollWidth;
            var duration = (totalWidth / 75) || 1;
            setAnimationDuration(duration);
        }
    }, [logos]);
    var handleMouseEnter = function () {
        setIsHovered(true);
    };
    var handleMouseLeave = function () {
        setIsHovered(false);
    };
    return (React.createElement("div", { className: "relative font-inter antialiased px-6 pb-6 pt-2 overflow-hidden" },
        React.createElement("div", { ref: logosRef, className: "flex items-center", style: {
                animationName: 'scroll',
                animationDuration: "".concat(animationDuration, "s"),
                animationTimingFunction: 'linear',
                animationIterationCount: 'infinite',
                animationPlayState: isHovered ? "paused" : "running",
                whiteSpace: 'nowrap',
            }, onMouseEnter: handleMouseEnter, onMouseLeave: handleMouseLeave },
            React.createElement("div", { className: "flex items-center" }, __spreadArray(__spreadArray([], logos, true), logos, true).map(function (link, index) { return (React.createElement(LinkComponent, { key: index, to: link.path || "#", onClick: link.onClick },
                React.createElement("div", { className: "p-4 mx-2 rounded-lg flex items-center border border-gray-400 min-w-[300px] bg-[#393939]" },
                    React.createElement("img", { src: link.imageSrc, alt: "adsLogo", className: clsx(imageClassName ? imageClassName : "rounded-full w-14 h-14") }),
                    React.createElement("div", { className: "flex-grow flex flex-col ml-4" },
                        React.createElement("div", { className: "flex items-center justify-between" },
                            React.createElement("span", { className: "text-lg font-semibold uppercase text-white" }, link.title)),
                        React.createElement("div", { className: "flex items-center justify-between" },
                            React.createElement("span", { className: "text-white text-sm font-semibold" }, link.description)))))); }))),
        React.createElement("style", null, "\n        @keyframes scroll {\n          0% { transform: translateX(0); }\n          100% { transform: translateX(-".concat(logos.length * 300, "px); }\n        }\n      "))));
};
export default AdvertisingComponent;
